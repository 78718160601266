<ng-container class="form-input">
    <label 
        class="form-input__label" 
        *ngIf="label"
        [for]="InputId"
    >
        {{name}}
    </label>
    <!--
           <div *ngFor="let error of control.errors | keyvalue">
        {{ errorMessages[error.value] }}
    </div>
    -->
    <input 
        type="text" 
        [placeholder]="name" 
        
    >
</ng-container>
