<button 
    [ngClass]="changeClass()" 
    class="Button" 
    (click)="callBackFunc()" 
    [ngStyle]="{
        'width': maxWidth ? '100%' : 'fit-content',
        'padding': padding ? padding : ''
    }">
    <p>{{btnName}}</p>
    <img src="assets/icons/sm-arrow-right.svg" alt="">
</button>