<form action="" class="reset-pwd-page" [formGroup]="resetPwdForm">
  <h2 class="reset-pwd-page__title">
    {{ "reset-pwd.title.1" | translate }} <br />
    {{ "reset-pwd.title.2" | translate }}
  </h2>
  <!-- user info container -->
  <app-form-reset-pwd
    *ngIf="step == 0"
    formGroupName="userInfo"
    [loginPhoneError]="numberError"
  >
  </app-form-reset-pwd>
  <!-- user info container -->

  <!-- user code container -->
  <app-form-user-code
    *ngIf="step == 1"
    formGroupName="codeInfo"
    [restorePwd]="true"
  >
  </app-form-user-code>
  <!-- user code container -->

  <!-- user info button -->
  <buttonComponent
    *ngIf="step == 0"
    [color]="'blue'"
    [btnName]="'reset-pwd.continue-btn' | translate"
    [callBackFunc]="forwardStep"
    [maxWidth]="true"
  />
  <!-- user info button -->

  <!-- user code button -->
  <buttonComponent
    *ngIf="step == 1"
    [color]="'blue'"
    [btnName]="'reset-pwd.continue-btn' | translate"
    [callBackFunc]="submitForm"
    [maxWidth]="true"
  />
  <!-- user code button -->

  <p *ngIf="numberError" class="reset-pwd-page__input-box__error">
    {{ numberError }}
  </p>
  <!--timer to resend code-->
  <p *ngIf="step == 1 && time > 0" class="reset-pwd-page__bottom-text">
    {{ "reset-pwd.new-send-in" | translate }}
    <span>{{ time }} {{ word }}</span>
  </p>
  <button
    *ngIf="step == 1 && time <= 0"
    class="reset-pwd-page__bottom-button"
    (click)="codeTimer()"
  >
    <p class="reset-pwd-page__bottom-button__link">
      {{ "reset-pwd.code-send-again" | translate }}
    </p>
  </button>
  <!--timer to resend code-->
</form>
<p class="reset-pwd-page-bottomLink text-link">
  <a href="" class="reset-pwd-page-bottomLink__link">{{
    "reset-pwd.help" | translate
  }}</a>
</p>
