<div class="user-form-code" [formGroup]="form">
  <p class="user-form-code__text-top">
    {{ "reg-page.user-code.sms.1" | translate }}
    <span *ngIf="!restorePwd">{{ regData?.getPhoneNumber }}</span>
    <span *ngIf="restorePwd">{{ phoneNumber }}</span>
    {{ "reg-page.user-code.sms.2" | translate }}
  </p>
  <div class="user-form-code__input-box">
    <input
      type="text"
      class="user-form-code__input-box__input"
      [placeholder]="'reg-page.user-code.code' | translate"
      formControlName="code"
      [ngClass]="
        form.get('code')?.invalid || codeError
          ? 'user-form-code__input-box__input-invalid'
          : code.value !== ''
          ? 'user-form-code__input-box__input-valid'
          : ''
      "
      formControlName="code"
      #code
    />
    <p
      *ngIf="form.get('code')?.errors?.required"
      class="user-form-code__input-box__error"
    >
      {{ "reg-page.errors.empty" | translate }}
    </p>
    <p
      *ngIf="form.get('code')?.errors?.pattern"
      class="user-form-code__input-box__error"
    >
      {{ errMessage }}
    </p>
    <p *ngIf="codeError" class="user-form-code__input-box__error">
      {{ codeError | translate }}
    </p>
  </div>
</div>
