import {
  SuccessRegisterCompany,
  addCompanyDraftDataState,
  addCompanyDraftSuccess,
  addProfileImage,
  getCitiesByCountrySuccess,
  getCitiesDataSuccess,
  getCompanyOptionsSuccess,
  getCountriesByMacroregionSuccess,
  getCountriesDataSuccess,
  getMacroRegionsDataSuccess,
  getRegionsDataSuccess,
  setAddCompanyStep,
  setClickedSubscription,
  setCompanyShares,
  setConfirmMailError,
  setConfirmMailTimerAction,
  setEditableCompanyStep,
  setLanguage,
  setLoadingSpinner,
  setLoginErrorCode,
  setLoginErrorMessage,
  setLogoutMessage,
  setModalWindowConfirmMail,
  setModalWindowLogout,
  setModalWindowUserInfoSave,
  setRequestError,
  setRequestSuccess,
  setRightSidebarSignIn,
  setRightSidebarSignInOptions,
  setRigtSidebarButtonsUserInfo,
  setRigtSidebarSaveButtonUserInfo,
  setRigtSidebarSaveCabinetInfo,
  setRigtSidebarOrdersButtons,
  createTemplateSuccess,
  getCompanyByIdSuccess,
  clearCompanyId,
  getPaymentMethodsSuccess,
  setDeletePaymentMethodModal,
  setDeleteOrderModal,
} from './shared.actions';
import { SharedState, initialState } from './shared.state';
import { Action, createReducer, on } from '@ngrx/store';

const _sharedReducer = createReducer(
  initialState,
  on(setLoadingSpinner, (state, action) => {
    return {
      ...state,
      showLoading: action.status,
    };
  }),
  on(setLoginErrorMessage, (state, action) => {
    return {
      ...state,
      errorMessage: action.message,
    };
  }),
  on(setLoginErrorCode, (state, action) => {
    return {
      ...state,
      errorCode: action.code,
    };
  }),
  on(setLogoutMessage, (state, action) => {
    return {
      ...state,
      logoutMessage: action.message,
    };
  }),

  on(getPaymentMethodsSuccess, (state, action) => {
    return {
      ...state,
      paymentMethods: action.data,
    };
  }),

  on(setRightSidebarSignIn, (state, action) => {
    const selected: string[] = [];
    if (action.props) {
      action.props.map((prop, key) => {
        selected.push(prop.options[0]);
      });
      return {
        ...state,
        signInRightSidebar: action.props,
        signInRightSidebarSelected: selected,
      };
    }
    return {
      ...state,
      signInRightSidebar: null,
    };
  }),
  on(setRightSidebarSignInOptions, (state, action) => {
    return {
      ...state,
      signInRightSidebarSelected: action.options,
    };
  }),
  on(setClickedSubscription, (state, action) => {
    if (action.options) {
      return {
        ...state,
        clickedSubscription: action.options,
      };
    }
    return {
      ...state,
      clickedSubscription: null,
    };
  }),
  on(addProfileImage, (state, action) => {
    return {
      ...state,
      newProfileImage: action.file,
    };
  }),
  /*LOCATIONS*/
  on(getCountriesDataSuccess, (state, action) => {
    return {
      ...state,
      countries: action.countries,
    };
  }),
  on(getRegionsDataSuccess, (state, action) => {
    return {
      ...state,
      regions: action.regions.map((region) => {
        return {
          id: region.id,
          title: region.name,
          inCountry: region.inCountry,
        };
      }),
    };
  }),
  on(getCitiesDataSuccess, (state, action) => {
    return {
      ...state,
      cities: action.cities.map((city) => {
        return {
          id: city.id,
          title: city.name,
          inRegion: city.inRegion,
        };
      }),
    };
  }),
  on(getMacroRegionsDataSuccess, (state, action) => {
    return {
      ...state,
      macroregions: action.macroregions.map((region) => {
        return {
          id: region.id,
          title: region.name,
          continent: region.continent,
        };
      }),
    };
  }),
  //country by macro
  on(getCountriesByMacroregionSuccess, (state, action) => {
    return {
      ...state,
      countriesByMacro: action.countries,
    };
  }),
  //city by coutry
  on(getCitiesByCountrySuccess, (state, action) => {
    return {
      ...state,
      citiesByCoutry: action.cities.map((city) => {
        return {
          id: city.id,
          title: city.name,
          inRegion: city.inRegion,
        };
      }),
    };
  }),
  /*LOCATIONS*/
  on(setLanguage, (state, action) => {
    return {
      ...state,
      lang: action.lang,
    };
  }),
  //confirm mail
  on(setConfirmMailTimerAction, (state, action) => {
    return {
      ...state,
      timer: action.timeStart,
      time: action.time,
    };
  }),
  on(setConfirmMailError, (state, action) => {
    return {
      ...state,
      confirmMailError: action.error,
    };
  }),
  ///SIDEBAR BUTTONS//
  on(setRigtSidebarSaveCabinetInfo, (state, action) => {
    return {
      ...state,
      saveBtnCabinetInfo: action.show,
    };
  }),
  on(setRigtSidebarButtonsUserInfo, (state, action) => {
    return {
      ...state,
      ButtonsUserInfoPage: action.show,
    };
  }),
  on(setRigtSidebarSaveButtonUserInfo, (state, action) => {
    return {
      ...state,
      SaveButtonUserInfoPage: action.show,
    };
  }),
  on(setRigtSidebarOrdersButtons, (state, action) => {
    return {
      ...state,
      ordersButtons: action.button,
    };
  }),

  //MODAL WINDOWS//
  on(setModalWindowUserInfoSave, (state, action) => {
    if (action.show) {
      return {
        ...state,
        ModalWindowUserInfoSave: action.show,
      };
    }
    return {
      ...state,
      ModalWindowUserInfoSave: null,
    };
  }),
  on(setModalWindowConfirmMail, (state, action) => {
    if (action.show) {
      return {
        ...state,
        ModalWindowConfirmMail: action.show,
      };
    }
    return {
      ...state,
      ModalWindowConfirmMail: null,
    };
  }),
  on(setModalWindowLogout, (state, action) => {
    if (action.show) {
      return {
        ...state,
        ModalWindowLogoutButton: action.show,
      };
    }
    return {
      ...state,
      ModalWindowLogoutButton: null,
    };
  }),
  on(setDeletePaymentMethodModal, (state, action) => {
    if (action.open && action.card) {
      return {
        ...state,
        ModalWindowDeleteCard: { open: action.open, card: action.card },
      };
    }
    return {
      ...state,
      ModalWindowDeleteCard: {
        open: false,
        card: '',
      },
    };
  }),

  on(setDeleteOrderModal, (state, action) => {
    if (action.open && action.order) {
      return {
        ...state,
        ModalWindowDeleteOrder: { open: action.open, order: action.order },
      };
    }
    return {
      ...state,
      ModalWindowDeleteOrder: {
        open: false,
        order: '',
      },
    };
  }),
  // add company
  on(setAddCompanyStep, (state, action) => {
    if (action.show !== undefined) {
      return {
        ...state,
        addCompanyStepShow: action.show,
      };
    }
    if (action.step || action.step == 0) {
      return {
        ...state,
        addCompanyStep: action.step,
      };
    }
    if (action.step && action.show) {
      return {
        ...state,
        addCompanyStepShow: action.show,
        addCompanyStep: action.step,
      };
    }
    return {
      ...state,
    };
  }),
  on(setEditableCompanyStep, (state, action) => {
    return {
      ...state,
      editableCompanyStep: action.value,
    };
  }),
  on(setCompanyShares, (state, action) => {
    let share =
      action.act == '+'
        ? state.companyShares + Number(action.number)
        : state.companyShares - Number(action.number);
    return {
      ...state,
      companyShares: share,
    };
  }),
  on(SuccessRegisterCompany, (state, action) => {
    return {
      ...state,
      currentCompanyToUpdate: action.company,
    };
  }),
  on(addCompanyDraftSuccess, (state, action) => {
    return {
      ...state,
    };
  }),
  on(addCompanyDraftDataState, (state, action) => {
    return {
      ...state,
      companyDraftState: action.data,
    };
  }),
  on(getCompanyOptionsSuccess, (state, action) => {
    return {
      ...state,
      companyOptions: action.options,
    };
  }),
  on(createTemplateSuccess, (state, action) => {
    return {
      ...state,
      currentTemplateId: action.data,
    };
  }),
  on(getCompanyByIdSuccess, (state, action) => {
    return {
      ...state,
      currentTemplateId: action.data.id,
    };
  }),
  on(clearCompanyId, (state, action) => {
    return {
      ...state,
      currentTemplateId: null,
    };
  }),
  //add company

  /* REQUESTS */
  on(setRequestError, (state, action) => {
    return {
      ...state,
      requestError: action.message,
    };
  }),
  on(setRequestSuccess, (state, action) => {
    if (!action.message) {
      return {
        ...state,
        requestSuccess: null,
      };
    }
    return {
      ...state,
      requestSuccess: action.message,
    };
  })
  /* REQUESTS*/
);

export function SharedReducer(state: SharedState | undefined, action: Action) {
  return _sharedReducer(state, action);
}
