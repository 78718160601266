import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-form-reset-pwd',
  templateUrl: './ui/form-reset-pwd.component.html',
  styleUrls: ['./ui/form-reset-pwd.component.scss'],
})
export class FormResetPwdComponent {
  Object: Object;

  @Input() formGroupName: string;
  @Input() loginPhoneError: string | null;

  form: FormGroup;
  inputs: string[];
  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit() {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.inputs = Object.keys(this.form.controls);
  }
}
