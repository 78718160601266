import { Component, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { User } from 'app/models/user.model';
import { isAuthenticated } from 'app/providers/AppRouter/authState/auth.selector';
import { AuthService } from 'app/services/auth.service';
import {
  autoLogin,
  setRequestError,
  setRequestSuccess,
} from 'app/store/Shared/shared.actions';
import {
  MatSnackBar,
  MatSnackBarRef,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import {
  getLoading,
  getLoginCodeErrorMessage,
} from 'app/store/Shared/shared.seleсtor';
import { AppState } from 'app/store/app.state';
import { Observable, map } from 'rxjs';
import { NotificationType, NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Firmder-app-angular';
  showLoading: Observable<boolean>;
  errorCode$: Observable<number>;
  isAuthenticated$: Observable<boolean>;
  errorCode: number;
  route: string;
  durationInSeconds = 3;

  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router,
    private _notifications: NotificationsService
  ) {}

  ngOnInit() {
    this.showLoading = this.store.select(getLoading);
    this.errorCode$ = this.store.select(getLoginCodeErrorMessage);
    this.isAuthenticated$ = this.store.select(isAuthenticated);

    this.store.dispatch(autoLogin());

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.route = event.url;
        //localStorage.setItem('FirmderUrl', this.route);
      }
    });

    this.errorCode$.subscribe((error) => {
      if (error == 404) {
        this.router.navigate(['/login']);
        localStorage.clear();
      }
      console.log(error);
    });

    this.store
      .select((state) => state.shared.requestError)
      .subscribe((error) => {
        if (error) {
          this.createResponceMessage('Request Error', error, 'error');
        }
      });
    this.store
      .select((state) => state.shared.requestSuccess)
      .subscribe((success) => {
        if (success) {
          this.createResponceMessage('Request Success', success, 'success');
        }
      });
  }

  createResponceMessage(title: string, content: string, type: string) {
    const props = {
      type: type,
      title: title,
      content: content,
      timeOut: this.durationInSeconds * 1000, //
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true,
      animate: 'fromRight',
    };
    console.log('createResponceMessage');

    this._notifications.create(
      props.title,
      props.content,
      type == 'error' ? NotificationType.Error : NotificationType.Success,
      props
    );
  }

  error() {
    this.store.dispatch(setRequestError({ message: 'Invalid' }));
  }
}
