import { createAction, props } from '@ngrx/store';
import { UserInfoPostProps, UserInfoProps } from './models/userInfo.model';

export const GET_USERINFO_ACTION = '[userInfo state] get user info data';
export const GET_USERINFO_SUCCESS_ACTION =
  '[userInfo state] get user info data success';
export const SET_USERINFO_ERROR_MESSAGE_ACTION =
  '[userInfo state] set user info error message';
export const POST_USERINFO_ACTION = '[userInfo state] post user info data';
export const POST_USERINFO_ACTION_SUCCESS = '[userInfo state] post user info data success';
export const POST_USERINFO_IMAGE_ACTION =
  '[userInfo state] post user info image data';
export const POST_USERINFO_SUCCESS_ACTION =
  '[userInfo state] post user info data success';
export const SET_USERINFO_DATA = '[userInfo state] set user info data';

export const getUserInfo = createAction(
  GET_USERINFO_ACTION,
  props<{ token: string }>()
);

export const setUserInfoErrormessage = createAction(
  SET_USERINFO_ERROR_MESSAGE_ACTION,
  props<{ message: string }>()
);
export const getUserInfoSuccess = createAction(
  GET_USERINFO_SUCCESS_ACTION,
  props<{ UserInfoData: UserInfoProps }>()
);
export const postUserinfoAction = createAction(
  POST_USERINFO_ACTION,
  props<{ UserInfoData: UserInfoPostProps; token: string }>()
);
export const postUserinfoActionSuccess = createAction(
  POST_USERINFO_ACTION_SUCCESS,
  props<{ data: any; }>()
);
export const postUserinfoActionImage = createAction(
  POST_USERINFO_IMAGE_ACTION,
  props<{ UserInfoData: UserInfoPostProps; token: string }>()
);
export const setUserinfoData = createAction(
  SET_USERINFO_DATA,
  props<{ UserInfoData: UserInfoPostProps; token: string }>()
);
// export const postUserinfoAction = createAction(
//   POST_USERINFO_SUCCESS_ACTION,
//   props<{ UserInfoData: UserInfoPostProps; token: string }>()
// );
