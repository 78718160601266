import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-note-modal',
  templateUrl: './ui/note-modal.component.html',
  styleUrls: ['./ui/note-modal.component.scss']
})
export class NoteModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NoteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
   
  }

  ngOnInit(): void {
  }

}
