import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppState } from 'app/store/app.state';
import { setValidationRequired } from 'shared/lib';
import { Store } from '@ngrx/store';
import { setAllValidationRequired } from 'shared/lib/setValidationRequired/setValidationRequired';
import {
  resetPwdSendCode,
  setResetPwdLogin,
  setResetPwdTimerAction,
} from 'app/store/ResetPwd/resetPwd.actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './ui/reset-password-page.component.html',
  styleUrls: ['./ui/reset-password-page.component.scss'],
})
export class ResetPasswordPageComponent {
  resetPwdForm: FormGroup;
  step: number | string = 0;
  time: number = 61;
  word: string;
  numberError: string | null = null;
  timerStarted: boolean = false;
  phoneNumber: string | null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {
    //this.userForm.valueChanges.subscribe(value => console.log(value));
  }

  ngOnInit(): void {
    this.resetPwdForm = this.fb.group({
      userInfo: this.fb.group({
        login: this.fb.control('', [
          Validators.pattern(
            // /^(\+7|7|8)[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
            // /^(\+7|7|8)[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/ 
            /^(\+)(7|49(1?))[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
          ),
        ]),
      }),
      codeInfo: this.fb.group({
        code: this.fb.control('', [Validators.pattern(/^[0-9]{6,6}$/)]),
      }),
    });
    this.resetPwdForm.controls['userInfo'].valueChanges.subscribe(
      (data) => data
    );

    this.store
      .select((state) => state.resetPwd.loginErrorMessage)
      .subscribe((numberError) => {
        this.numberError = numberError;
        setAllValidationRequired(
          this.resetPwdForm,
          'userInfo',
          this.numberError ? true : false
        );
      });

    this.store
      .select((state) => state.resetPwd.step)
      .subscribe((step) => (this.step = step));
    this.store
      .select((state) => state.resetPwd.timer)
      .subscribe((timerStarted) => (this.timerStarted = timerStarted));
    this.store
      .select((state) => state.resetPwd.time)
      .subscribe((time) => {
        this.time = time;
        this.codeTimerWord();
      });
    this.store
      .select((state) => state.resetPwd.login)
      .subscribe((phone) => (this.phoneNumber = phone));

    setValidationRequired(this.resetPwdForm, 'userInfo');
    setValidationRequired(this.resetPwdForm, 'codeInfo');
  }

  phoneNumberValidation = (): string => {
    let phoneNumber: string = this.resetPwdForm.controls.userInfo.value.login;
    const newPhone = String(phoneNumber).split('');
    if (newPhone[0] == '8' || newPhone[0] == '7') {
      newPhone[0] = '+7';
    }
    return newPhone.join('');
  };

  forwardStep = (): void => {
    const userInfo = this.resetPwdForm.get('userInfo') as FormArray;
    userInfo !== null
      ? Object.keys(userInfo.controls).forEach((key) => {
          let userInfoItem = userInfo.get(key);
          if (userInfoItem?.value == '') {
            userInfoItem?.setValidators(Validators.required);
          } else {
            userInfoItem?.removeValidators(Validators.required);
          }
          userInfoItem?.updateValueAndValidity();
        })
      : '';
    if (userInfo.valid) {
      (async () => {
        const login: string = this.phoneNumberValidation();
        this.store.dispatch(setResetPwdLogin({ login: login }));
      })();
    }
  };
  backwardStep = (): void => {};

  codeTimer = (): void => {
    this.store.dispatch(setResetPwdTimerAction({ timeStart: true, time: 60 }));
    this.forwardStep();
  };

  codeTimerWord = (): void => {
    let timerStr = this.time.toString().split('');
    if (1 < this.time && this.time < 5) {
      this.word = this.translateService.instant('reset-pwd.sec.3');
    } else if (1 == this.time) {
      this.word = this.translateService.instant('reset-pwd.sec.2');
    } else if (this.time > 5 && this.time < 21) {
      this.word = this.translateService.instant('reset-pwd.sec.1');
    } else if (
      Number(timerStr[timerStr.length - 1]) > 1 &&
      Number(timerStr[timerStr.length - 1]) < 5
    ) {
      this.word = this.translateService.instant('reset-pwd.sec.3');
    } else if (Number(timerStr[timerStr.length - 1]) == 1) {
      this.word = this.translateService.instant('reset-pwd.sec.2');
    } else {
      this.word = this.translateService.instant('reset-pwd.sec.1');
    }
  };
  submitForm = (): void => {
    const codeInfo2 = this.resetPwdForm.get('codeInfo');
    const codeInfo = this.resetPwdForm.get('userInfo') as FormArray;
    codeInfo !== null
      ? Object.keys(codeInfo.controls).forEach((key) => {
          let userInfoItem = codeInfo.get(key);
          if (userInfoItem?.value == '') {
            userInfoItem?.setValidators(Validators.required);
          } else {
            userInfoItem?.removeValidators(Validators.required);
          }
          userInfoItem?.updateValueAndValidity();
        })
      : '';
    if (codeInfo.valid) {
      const code: string = this.resetPwdForm.controls.codeInfo.value.code;
      const phone = this.phoneNumberValidation();
      this.store.dispatch(resetPwdSendCode({ code: code, phoneNumber: phone }));
      this.store.dispatch(setResetPwdTimerAction({ timeStart: true, time: 0 }));
    }
  };
}
