<div *ngIf="myCompany && !loading" class="my-company-edit-container">
  <div class="company-container">
    <div class="inner-company-container">
      <div class="company-title">{{ myCompany.titleVariants[0].title }} {{ myCompany.titleVariants[0].prefix }}</div>
      <div class="subscription-container">
        <div 
          *ngIf="myCompany.state === 4" 
          class="subscription-text"
        >
          {{ 'my-companies.renewal-date' | translate }}: {{ (getRenewalDate(myCompany.createdAt)) | date }}
        </div>
        <div 
          *ngIf="myCompany.state === 4"
          class="subscription-text"
        >
          {{ 'my-companies.renewal-cost' | translate }}: {{ myCompany.renewalCost }} {{myCompany.currency }}
        </div>
        <div *ngIf="myCompany.state === 3" class="loading-pink-container">
          <p class="state-title">{{ "my-orders.states." + myCompany.state | translate }}</p>
          <div class="loading-pink">
            <div
              class="loading-pink__bar"
              [style]="{ width: myCompany.state * 25 + '%' }"
            ></div>
          </div>
        </div>
      </div>

      <div class="block-of-2">
        <div class="block">
          <div class="title">{{ 'my-companies.country' | translate }}</div>
          <div class="value">{{ getCountryById(myCompany.countryId) }}</div>
        </div>
        <div class="block">
          <div class="title">{{ 'my-companies.form' | translate }}</div>
          <div class="value">{{ getFormNameById(myCompany.legalFormId) }}</div>
        </div>
      </div>

      <div class="block-of-2">
        <div class="block">
          <div class="title">{{ 'my-companies.directors' | translate }}</div>
          <div class="value">{{ myCompany.directorsCount }}</div>
        </div>
        <div class="block">
          <div class="title">{{ 'my-companies.founders' | translate }}</div>
          <div class="value">{{ myCompany.shareholdersCount }}</div>
        </div>
      </div>

      <div class="block-of-2">
        <div class="block">
          <div class="title">{{ 'my-companies.reg-number' | translate }}</div>
          <div class="value long-value">{{ myCompany.id }}</div>
        </div>
        <div class="block">
          <div class="title">{{ 'my-companies.reg-date' | translate }}</div>
          <div class="value">{{ myCompany.createdAt | date }}</div>
        </div>
      </div>

      <app-capital-slider-component
        [amount]="amount"
        [minValue]="getAmount('min', myCompany.legalFormId)"
        [maxValue]="getAmount('max', myCompany.legalFormId)"
        [currency]="myCompany.currency"
      ></app-capital-slider-component>

      <button 
        *ngIf="myCompany.state === 4"
        class="pay-button"
        [disabled]="!renewalService"
        (click)="payButtonClick(myCompany.id)"
      >
        {{ 'my-companies.pay-for-renewal' | translate }}
      </button>

      <button 
        *ngIf="myCompany.state === 4"
        class="download-button"
        [disabled]="!myCompany.foundingDocumentsUrl"
        (click)="downloadFiles(myCompany.foundingDocumentsUrl)"
      >
        {{ 'my-companies.download-documents' | translate }}
      </button>

      <!-- <div class="icons-container">
        <div *ngFor="let cs of selectedServices">
          <div *ngIf="cs.metaData.service.icon === 'company-registration'" class="service-icon service-icon-registration"></div>
          <div *ngIf="cs.metaData.service.icon === 'documents-request'" class="service-icon service-icon-documents"></div>
          <div *ngIf="cs.metaData.service.icon === 'accounting'" class="service-icon service-icon-accounting"></div>
        </div>
      </div> -->
    </div>

    <div 
      *ngIf="myCompany.state === 4"
      class="inner-company-container services-container"
    >
      <div class="connected-services-container">
        <div class="title">{{ 'my-companies.connected-services' | translate }}</div>
        <div 
          *ngIf="!selectedServices.length"
          class="no-services"
        >
          {{ 'my-companies.no-services-connected' | translate }}
        </div>
        <div 
          *ngIf="selectedServices.length"
          class="connected-services"
        >
          <div class="icons-container">
            <div *ngFor="let cs of selectedServices">
              <ng-container *ngIf="cs.isVisible">
                <div *ngIf="cs.metaData.service.icon === 'company-registration'" class="service-icon service-icon-registration"></div>
                <div *ngIf="cs.metaData.service.icon === 'documents-request'" class="service-icon service-icon-documents"></div>
                <div *ngIf="cs.metaData.service.icon === 'accounting'" class="service-icon service-icon-accounting"></div>
                <!-- <div *ngIf="cs.metaData.service.icon === 'company-renewal'" class="service-icon service-icon-accounting"></div> -->
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="available-services-container">
        <div class="title">{{ 'my-companies.available-services' | translate }}</div>

        <ng-container *ngFor="let service of services"> 
          <div
            *ngIf="service.isVisible"
            class="available-services-item"
            (click)="payButtonClick(myCompany.id, service.id)"
          >
            <div class="available-services-inner-container">
              <div *ngIf="service.icon === 'company-registration'" class="service-icon service-icon-registration"></div>
              <div *ngIf="service.icon === 'documents-request'" class="service-icon service-icon-documents"></div>
              <div *ngIf="service.icon === 'accounting'" class="service-icon service-icon-accounting"></div>
              <!-- <div *ngIf="service.icon === 'company-renewal'" class="service-icon service-icon-accounting"></div> -->
              <div class="service-title">{{ service.title }}</div>
              <div class="service-arrow"></div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div 
      *ngIf="myCompany.state === 3"
      class="inner-company-container services-container"
    >
      <div class="registration-penging-message">{{ 'my-companies.registration-will-take-about-5-days' | translate }}</div>
    </div>
  </div>

  <div class="company-info-container">
    <div class="company-info-block">
      <div class="info-block">
        <div class="title">{{ 'my-companies.category' | translate }}</div>
        <div class="value">{{ myCompany?.categories?.name || "---" }}</div>
      </div>
      <div class="info-block">
        <div class="title">{{ 'my-companies.subcategory' | translate }}</div>
        <div class="value">{{ getNamesFromArray(myCompany?.categories?.subCategories) }}</div>
      </div>
      <div class="info-block">
        <div class="title">{{ 'my-companies.types-of-economic-activities' | translate }}</div>
        <div class="value">{{ getNamesFromArray(myCompany?.activities) }}</div>
      </div>
    </div>

    <div class="company-info-block-with-title-container">
      <div class="block-title">{{ 'my-companies.director' | translate }} №1</div>
      <div class="company-info-block">
        <div class="info-block">
          <div class="title">{{ 'my-companies.full-name' | translate }}</div>
          <div class="value">{{ myCompany?.director[0]?.fullname || "---" }}</div>
        </div>
        <div class="info-block">
          <div class="title">{{ 'my-companies.citizenship' | translate }}</div>
          <div class="value">{{ myCompany?.director[0]?.nationality || "---" }}</div>
        </div>
        <div class="info-block">
          <div class="title">{{ 'my-companies.passport' | translate }}</div>
          <div class="value">{{ myCompany?.director[0]?.passport || "---" }}</div>
        </div>
        <div class="info-block">
          <div class="value with-icon">{{ 'my-companies.copy-of-passport' | translate }}
            <img *ngIf="myCompany.director[0]?.passportUrl" src="assets/icons/success-sign.svg" class="animate__animated animate__zoomIn"/>
            <img *ngIf="!myCompany.director[0]?.passportUrl" src="assets/icons/loading-icon.svg"/>
          </div>
        </div>
        <div class="info-block">
          <div class="value with-icon">{{ 'my-companies.address-proof' | translate }}
            <img *ngIf="myCompany.director[0]?.addressProofUrl" src="assets/icons/success-sign.svg" class="animate__animated animate__zoomIn"/>
            <img *ngIf="!myCompany.director[0]?.addressProofUrl" src="assets/icons/loading-icon.svg"/>
          </div>
        </div>
      </div>
    </div>

    <div *ngFor="let founder of myCompany.shareholderPhysical; let i = index" class="company-info-block-with-title-container">
      <div class="block-title">{{ 'my-companies.founder' | translate }} №{{i + 1}}</div>
      <div class="company-info-block">
        <div class="info-block">
          <div class="title">{{ 'my-companies.full-name' | translate }}</div>
          <div class="value">{{ founder?.fullname || "" }}</div>
        </div>
        <div class="info-block">
          <div class="title">{{ 'my-companies.citizenship' | translate }}</div>
          <div class="value">{{ founder?.nationality || "" }}</div>
        </div>
        <div class="info-block">
          <div class="title">{{ 'my-companies.passport' | translate }}</div>
          <div class="value">{{ founder?.passport || "" }}</div>
        </div>
        <div class="info-block">
          <div class="value with-icon">{{ 'my-companies.copy-of-passport' | translate }}
            <img *ngIf="founder?.passportUrl" src="assets/icons/success-sign.svg" class="animate__animated animate__zoomIn"/>
            <img *ngIf="!founder?.passportUrl" src="assets/icons/loading-icon.svg"/>
          </div>
        </div>
        <div class="info-block">
          <div class="value with-icon">{{ 'my-companies.address-proof' | translate }}
            <img *ngIf="founder?.addressProofUrl" src="assets/icons/success-sign.svg" class="animate__animated animate__zoomIn"/>
            <img *ngIf="!founder?.addressProofUrl" src="assets/icons/loading-icon.svg"/>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>