<div class="add-card-container">
  <h3 class="add-card-title">{{ "add-card.title" | translate }}</h3>
  <ngx-stripe-card-group
    [elementsOptions]="elementsOptions"
  >
    <ngx-stripe-card-number class="stripe-card-number" [options]="cardOptions">
    </ngx-stripe-card-number>
    <div class="in-line">
      <ngx-stripe-card-expiry class="stripe-expiry-date" [options]="baseStyles">
      </ngx-stripe-card-expiry>
      <ngx-stripe-card-cvc class="stripe-cvc" [options]="cvvOptions"></ngx-stripe-card-cvc>
    </div>

    <button type="button" (click)="createToken()">{{"add-card.button-text" | translate}}</button>
  </ngx-stripe-card-group>
  <div id="card-errors"></div>
</div>