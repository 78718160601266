<form action="" class="new-pwd-page" [formGroup]="newPwdForm">
  <h2 class="new-pwd-page__title">{{ "new-pwd.title" | translate }}</h2>

  <app-form-new-pwd formGroupName="passwords"></app-form-new-pwd>

  <buttonComponent
    *ngIf="step == 0"
    [color]="'blue'"
    [btnName]="'new-pwd.continue-btn' | translate"
    [callBackFunc]="submitForm"
    [maxWidth]="true"
    [disabled]="!submitActive"
  />
  <div class="new-pwd-page__validation">
    <p
      class="new-pwd-page__validation__item"
      [ngClass]="pwdValid.symbols ? 'new-pwd-page__validation__item-check' : ''"
    >
      {{ "new-pwd.pwd-rules.1" | translate }}
    </p>
    <p
      class="new-pwd-page__validation__item"
      [ngClass]="pwdValid.capital ? 'new-pwd-page__validation__item-check' : ''"
    >
      {{ "new-pwd.pwd-rules.2" | translate }}
    </p>
    <p
      class="new-pwd-page__validation__item"
      [ngClass]="
        pwdValid.smallLett ? 'new-pwd-page__validation__item-check' : ''
      "
    >
      {{ "new-pwd.pwd-rules.3" | translate }}
    </p>
    <p
      class="new-pwd-page__validation__item"
      [ngClass]="pwdValid.numbers ? 'new-pwd-page__validation__item-check' : ''"
    >
      {{ "new-pwd.pwd-rules.4" | translate }}
    </p>
    <p
      class="new-pwd-page__validation__item"
      [ngClass]="pwdValid.specialSymbol ? 'new-pwd-page__validation__item-check' : ''"
    >
      {{ "new-pwd.pwd-rules.5" | translate }}
    </p>
  </div>
</form>

<p class="new-pwd-page-bottomLink text-link">
  <a href="" class="new-pwd-page-bottomLink__link">
    {{ "new-pwd.help-service" | translate }}
  </a>
</p>

<app-modal-window
  [open]="showModal"
  [title]="'alerts.registrationsSuccess' | translate"
  [okText]="'alerts.ok-btn' | translate"
  [okFunction]="closeModal"
></app-modal-window>