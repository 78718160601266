<form action="" class="reg-page" [formGroup]="userForm">
  <div class="reg-page__header">
    <div class="reg-page__header__buttons">
      <button
        *ngIf="step > 0"
        (click)="stepBack()"
        class="reg-page__header__buttons__back"
      >
        <img src="assets/icons/arrow-left.svg" alt="arrow" />
      </button>
      <button
        *ngIf="step !== 1 && !numberError && regData && !numberConfirmed"
        (click)="stepForward()"
        class="reg-page__header__buttons__forward"
      >
        <img src="assets/icons/arrow-left.svg" alt="arrow" />
      </button>
    </div>
    <h2 class="reg-page__title">{{ "reg-page.register" | translate }}</h2>
  </div>
  <!-- user info container -->
  <app-form-user-info
    *ngIf="step == 0"
    formGroupName="userInfo"
    [numberError]="numberError"
  >
  </app-form-user-info>
  <!-- user info container -->

  <!-- user code container -->
  <app-form-user-code
    *ngIf="step == 1"
    formGroupName="codeInfo"
    [codeError]="numberError"
    [errMessage]="errorMessage"
  >
  </app-form-user-code>
  <!-- user code container -->

  <!-- user password container -->
  <!-- <app-form-new-pwd *ngIf="step == 2" formGroupName="codeInfo">
  </app-form-new-pwd> -->
  <!-- user passwors -->

  <!-- user info button -->
  <buttonComponent
    *ngIf="step == 0"
    [color]="'blue'"
    [btnName]="'reg-page.register-btn' | translate"
    [callBackFunc]="firstStep"
    [maxWidth]="true"
  />
  <!-- user info button -->

  <!-- user code button -->
  <buttonComponent
    *ngIf="step == 1"
    [color]="'blue'"
    [btnName]="'reg-page.continue-btn' | translate"
    [callBackFunc]="submitForm"
    [maxWidth]="true"
  />
  <!-- user code button -->

  <!--timer to resend code-->
  <p *ngIf="step == 1 && timer > 0" class="reg-page__bottom-text">
    {{ "reg-page.new-send-in" | translate }}
    <span>{{ timer }} {{ word }}</span>
  </p>
  <button
    (click)="resendCode()"
    *ngIf="step == 1 && timer <= 0"
    class="reg-page__bottom-button"
  >
    <p class="reg-page__bottom-button__link">
      {{ "reg-page.repeat-sending" | translate }}
    </p>
  </button>
  <!--timer to resend code-->
</form>
<app-rights></app-rights>
