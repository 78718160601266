import { state } from '@angular/animations';
import {
  RegistrationCodeSuccess,
  RegistrationPhoneNumberSuccess,
  registrationSetStep,
  setRegistrationData,
  setRegistrationErrorMessage,
  setRegistrationTimerAction,
} from './registration.actions';
import { RegistrationState, initialState } from './registration.state';
import { Action, createReducer, on } from '@ngrx/store';

const _registrationReducer = createReducer(
  initialState,
  on(setRegistrationData, (state, action) => {
    return {
      ...state,
      registrationData: action.registrationData,
      numberConfirmed: false,
    };
  }),
  on(setRegistrationErrorMessage, (state, action) => {
    return {
      ...state,
      errMessage: action.message,
    };
  }),
  on(RegistrationPhoneNumberSuccess, (state, action) => {
    return {
      ...state,
      step: 1,
    };
  }),
  on(RegistrationCodeSuccess, (state, action) => {
    return {
      ...state,
      numberConfirmed: true,
    };
  }),
  on(setRegistrationTimerAction, (state, action) => {
    return {
      ...state,
      timer: action.timeStart,
      time: action.time,
    };
  }),
  on(registrationSetStep, (state, action) => {
    return {
      ...state,
      step: action.step,
    };
  })
);

export function RegistrationReducer(
  state: RegistrationState | undefined,
  action: Action
) {
  return _registrationReducer(state, action);
}
