import { createAction, props } from '@ngrx/store';
import { registrationData } from './models/registrationData.model';

export const SET_REGISTRATION_DATA_ACTION =
  '[registration state] set registration data';

export const REGISTRATION_PHONE_NUMBER_CHECK =
  '[registration state] checks registration phone number ';
export const SET_REGISTRATION_ERROR_MESSAGE =
  '[registration state] sets registration error message ';
export const REGISTRATION_PHONE_CHECK_SUCCESS =
  '[registration state] phone number check success ';
export const REGISTRATION_SEND_CODE =
  '[registration state] send registration code ';
export const REGISTRATION_CODE_SUCCESS =
  '[registration state] registration code is valid';
export const REGISTRATION_PASSWORD_SUCCESS =
  '[registration state] registration password success';
export const SET_REGISTRATION_TIMER =
  '[registration state] sets registration timer';
export const REGISTRATION_SUCCESS = '[registration state] registration success';
export const REGISTRATION_SET_STEP =
  '[registration state] registration set step';

export const setRegistrationData = createAction(
  SET_REGISTRATION_DATA_ACTION,
  props<{ registrationData: registrationData; phoneNumber: string }>()
);
export const RegistrationPhoneNumberSuccess = createAction(
  REGISTRATION_PHONE_CHECK_SUCCESS
);
export const setRegistrationErrorMessage = createAction(
  SET_REGISTRATION_ERROR_MESSAGE,
  props<{ message: string }>()
);

export const RegistrationSendCode = createAction(
  REGISTRATION_SEND_CODE,
  props<{ code: string; phoneNumber: string }>()
);

export const RegistrationCodeSuccess = createAction(REGISTRATION_CODE_SUCCESS);
export const RegistrationPasswordSucess = createAction(
  REGISTRATION_PASSWORD_SUCCESS,
  props<{
    password: string;
    lastName: string;
    firstName: string;
    phoneNumber: string | number;
  }>()
);

export const setRegistrationTimerAction = createAction(
  SET_REGISTRATION_TIMER,
  props<{ timeStart: boolean; time: number }>()
);

export const registrationSuccess = createAction(
  REGISTRATION_SUCCESS,
  props<{ user: any }>()
);

export const registrationSetStep = createAction(
  REGISTRATION_SET_STEP,
  props<{ step: number }>()
);
