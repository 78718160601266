import { Component } from '@angular/core';
import { gsap } from 'gsap';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './ui/loading-screen.component.html',
  styleUrls: ['./ui/loading-screen.component.scss']
})
export class LoadingScreenComponent {

}
