<div
  class="langSwicher"
  [ngClass]="{ langSwitcherMaxWidth: maxWidth }"
  *ngIf="screenWidth > 900 && !onlyMob"
>
  <a
    *ngFor="let lang of allLanguages"
    (click)="changeLang(lang)"
    class="langSwicher__button"
    [ngClass]="language == lang ? 'langSwicher__button-clicked' : ''"
  >
    {{ lang }}
  </a>
</div>
<div
  class="langSwicher-mob"
  [ngClass]="{ langSwithcerSignInMob: signIn }"
  *ngIf="screenWidth <= 900"
>
  <button
    (click)="changeMob()"
    class="langSwicher__button langSwicher-mob__button-clicked"
  >
    {{ language }}
  </button>
</div>
