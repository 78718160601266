import { Component } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { AppState } from 'app/store/app.state';
import { BehaviorSubject, Observable, exhaustMap, tap } from 'rxjs';
import { RouteConfig } from 'shared/config/routeConfig/routeConfig';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import {
  autoLogout,
  logoutSuccess,
} from 'app/providers/AppRouter/authState/auth.actions';
import { getUser } from 'app/providers/AppRouter/authState/auth.selector';
import {
  getUserInfo,
  getUserInfoSuccess,
} from 'app/store/UserInfo/userInfo.actions';
import { UserInfoProps } from 'app/store/UserInfo/models/userInfo.model';
import { TranslateService } from '@ngx-translate/core';
import { CountryData } from 'app/store/Shared/model/SharedModel';
import { DropdownType1Eement } from 'shared/ui/dropdown-type-1/circle-img/dropdown-type-1';
import {
  getCountriesData,
  sendConfirmationMailCode,
  setModalWindowLogout,
  setRigtSidebarSaveCabinetInfo,
} from 'app/store/Shared/shared.actions';
import { SharedService } from 'app/store/Shared/services/SharedServices';

@Component({
  selector: 'app-user-cabinet-page',
  templateUrl: './ui/user-cabinet-page.component.html',
  styleUrls: ['./ui/user-cabinet-page.component.scss'],
})
export class UserCabinetPageComponent {
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private actions: Actions,
    private store: Store<AppState>,
    private translateService: TranslateService,
    private sharedService: SharedService
  ) {}

  countries: CountryData[];
  countriesTitles: DropdownType1Eement[];
  codeTime: number;
  modalLogout: boolean = false;
  cardsData: any; //cards data not used now
  defaultCard: number;

  citizenship = new BehaviorSubject<string>('');
  rezidenship = new BehaviorSubject<string>('');
  userId: string = '';
  token: string = '';
  userInfo: UserInfoProps | null;
  avatar: string | undefined = '';
  setCitizenship(elem: string) {
    this.citizenship.next(elem);
    this.citizenship.subscribe((val) => {
      if (val && val !== this.userInfo?.citizenship.name) {
        this.store.dispatch(setRigtSidebarSaveCabinetInfo({ show: true }));
      } else {
        this.store.dispatch(setRigtSidebarSaveCabinetInfo({ show: false }));
      }
    });
  }
  setRezedenship(elem: string) {
    this.rezidenship.next(elem);
    this.rezidenship.subscribe((val) => {
      if (val && val !== this.userInfo?.residency.name) {
        this.store.dispatch(setRigtSidebarSaveCabinetInfo({ show: true }));
      } else {
        this.store.dispatch(setRigtSidebarSaveCabinetInfo({ show: false }));
      }
    });
  }

  public _getPaymentMethods() {
    this.sharedService.getPaymentMethods().subscribe(({ data }) => {
      if (data.filter((e: any) => e.paymentMetaData.brand)) {
        this.cardsData = data.filter((e: any) => e.paymentMetaData.brand);
        this.cardsData.map(
          (e: any) =>
            (e.paymentMetaData.lastDigits =
              e.paymentMetaData.lastDigits.replace(/^.{2}/g, '**'))
        );
        this.defaultCard = this.cardsData.findIndex((e: any) => {
          return e.isDefault;
        });
      }
      console.log(this.defaultCard);
    });
  }

  ngOnInit() {
    /*this.route.paramMap.subscribe( params => {
      if (params.get('confirmed')) {
        console.log('qweqweqwe');
      }
    });*/
    this._getPaymentMethods();

    this._getUserInfo();

    this.store.select(getUser).subscribe((user) => {
      this.userId = user?.id || '';
    });

    this.store
      .select((state) => state.userinfo)
      .subscribe((info) => {
        this.userInfo = info.UserInfoData;
        if (this.userInfo?.residency?.name) {
          this.rezidenship.next(this.userInfo?.residency.name);
        }
        if (this.userInfo?.citizenship?.name) {
          this.citizenship.next(this.userInfo?.citizenship.name);
        }
      });

    this.store
      .select((state) => state.shared.time)
      .subscribe((timer) => {
        this.codeTime = timer;
      });

    //save btn from state
    this.store
      .select((state) => state.shared.saveBtnCabinetInfo)
      .subscribe((btn) => {
        this.saveBtn = btn;
      });

    //removing save button from sidebar on navigation to other url
    this.router.events.subscribe((val) => {
      if (
        this.router.url !== '/account/user-cabinet' &&
        val instanceof NavigationEnd &&
        this.saveBtn == true
      ) {
        this.store.dispatch(setRigtSidebarSaveCabinetInfo({ show: false }));
      }
    });

    this.store
      .select((state) => state.userinfo)
      .subscribe((info) => {
        this.avatar = info.UserInfoData?.avatarImageLink;
      });

    this.store
      .select((state) => state.shared)
      .subscribe((sharedState) => {
        if (sharedState.countries) {
          this.countries = sharedState.countries;
          this.countriesTitles = sharedState.countries.map((c) => {
            return { title: c.title };
          });
        }
      });

    //getting usercabinet logout modal window state
    this.store
      .select((state) => state.shared.ModalWindowLogoutButton)
      .subscribe((btn) => {
        if (btn) {
          this.modalLogout = btn;
        } else {
          this.modalLogout = false;
        }
      });
  }

  dropdown: string = '0';
  saveBtn: boolean = false;

  setDropdown(drop: string): void {
    this.dropdown = drop;
    console.log(drop);
  }

  setSaveBtn(): void {
    console.log(this.saveBtn);
  }

  onSave() {}

  navigateToInfo() {
    this.router.navigateByUrl('account/user-cabinet-info');
  }

  navigateToPayment() {
    if (RouteConfig['add-payment-method'].path) {
      this.router.navigateByUrl('account/add-payment-method');
    }
  }
  navigateToTransactions() {
    if (RouteConfig['transactions'].path) {
      this.router.navigateByUrl('account/transactions');
    }
  }
  naviagateToConfirmMail() {
    this.store.dispatch(sendConfirmationMailCode());
  }

  onLogout = () => {
    this.closeLogoutModalWindow();
    const userDataString = localStorage.getItem('firmderWebUser');
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      this.store.dispatch(logoutSuccess({ userId: userData.user.id }));
    }
  };

  openLogoutModalWindow = () => {
    this.store.dispatch(setModalWindowLogout({ show: true }));
  };
  closeLogoutModalWindow = () => {
    this.store.dispatch(setModalWindowLogout({ show: false }));
  };

  private _getUserInfo() {
    let token = JSON.parse(localStorage.getItem('firmderWebUser')!).token;
    this.store.dispatch(getUserInfo({ token }));
  }
}
