import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { UserInfoProps } from 'app/store/UserInfo/models/userInfo.model';
import { AppState } from 'app/store/app.state';
import { Order } from 'app/store/Shared/model/SharedModel';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';

import {
  getOrderList,
  getOrderListSuccess,
} from 'app/store/Shared/shared.actions';

@Component({
  selector: 'app-services-catalogue-page',
  templateUrl: './ui/ServicesCataloguePage.component.html',
  styleUrls: ['./ui/ServicesCataloguePage.component.scss'],
})
export class ServicesCataloguePage {
  userInfo: UserInfoProps | null;
  name: string = '';
  companiesCount = 0;
  ordersCount = 0;
  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private router: Router
  ) {}

  ngOnInit() {
    //sets user info
    this.store
      .select((state) => state.userinfo)
      .subscribe((info) => {
        this.userInfo = info.UserInfoData;
      });
    //sets user name
    this.store
      .select((state) => state.userinfo)
      .subscribe((state) => {
        if (state.UserInfoData) {
          this.name = `${state.UserInfoData.firstName} ${state.UserInfoData.lastName}`;
        }
      });

    this._getOrders();
  }

  private _getOrders() {
    this.store.dispatch(getOrderList());
    this.actions
      .pipe(ofType(getOrderListSuccess.type))
      .subscribe(({ data }: any) => {
        if (data && data.length) {
          this.companiesCount = (data as any).filter(
            (e: Order) => e.state === 3
          ).length;
          this.ordersCount = data.length - this.companiesCount;
        }
      });
  }

  navigateToUserCabinetInfo() {
    this.router.navigate(['/account/user-cabinet-info']);
  }
  navigateToStartACompany = () => {
    this.router.navigate(['/account/add-company']);
  };
  navigateToMyOrders = () => {
    this.router.navigate(['/account/my-orders']);
  };
  navigateToMyCompanies = () => {
    this.router.navigate(['/account/my-companies']);
  };
  navigateToAdditionalServices = () => {
    // this.router.navigate(['/account/additional-services']);
    this.router.navigate(['/account/transactions']);
  };
}
