<div class="new-pwd-form" [formGroup]="form">
  <div class="new-pwd-form__input-box">
    <input
      type="text"
      class="new-pwd-form__input-box__input"
      [ngClass]="
        form.get('password')?.invalid
          ? 'new-pwd-form__input-box__input-invalid'
          : form.get('repeatedPwd')?.invalid
          ? 'new-pwd-form__input-box__input-invalid'
          : password.value !== ''
          ? 'new-pwd-form__input-box__input-valid'
          : ''
      "
      [placeholder]="'new-pwd.form.insert-pwd' | translate"
      formControlName="password"
      #password
    />
    <p
      *ngIf="form.get('password')?.errors?.['incorrect']"
      class="new-pwd-form__input-box__error"
    >
      {{ "new-pwd.form.errors.privacy" | translate }}
    </p>
  </div>
  <div class="new-pwd-form__input-box">
    <input
      type="text"
      class="new-pwd-form__input-box__input"
      [ngClass]="
        form.get('repeatedPwd')?.invalid
          ? 'new-pwd-form__input-box__input-invalid'
          : repeatedPwd.value !== ''
          ? 'new-pwd-form__input-box__input-valid'
          : ''
      "
      [placeholder]="'new-pwd.form.accept-pwd' | translate"
      formControlName="repeatedPwd"
      #repeatedPwd
    />
    <p
      *ngIf="form.get('repeatedPwd')?.errors?.['incorrect']"
      class="new-pwd-form__input-box__error"
    >
      {{ "new-pwd.form.pwd-not-match" | translate }}
    </p>
  </div>
</div>
