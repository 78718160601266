<section class="add-payment-method">
  <h3 class="add-payment-method__title">
    {{ "add-payment-method-page.payment-methods" | translate }}
  </h3>
  <!-- <div class="add-payment-method__container">
    <buttonComponent
      [maxWidth]="false"
      [padding]="'9px 20px'"
      [color]="'black'"
      [btnName]="'Add payment card'"
      [callBackFunc]="navigateToAddCard"
    />
  </div> -->
  <div class="add-payment-method_container">
    <p class="add-payment-method_container_title">
      {{ "add-payment-method-page.payment-methods" | translate }}
    </p>
    <div></div>
    <app-modal-window
      [open]="deleteWindowOpen"
      [title]="'alerts.save2' | translate"
      [okText]="'alerts.ok-btn' | translate"
      [cancelText]="'alerts.cancel-btn' | translate"
      [okFunction]="deletePaymentMethod"
      [cancelFunction]="closeDeleteWindow"
    ></app-modal-window>
    <div
      *ngFor="let card of cardsData; index as i"
      class="add-payment-method_container_cards"
    >
      <div class="add-payment-method_container_cards_title">
        {{ card.paymentMetaData.brand }}
        {{ card.paymentMetaData ? card.paymentMetaData.lastDigits : "" }}
      </div>
      <div class="add-payment-method_container_cards_buttons">
        <button (click)="openDeleteWindow(card.paymentMethodId)">
          <img src="/assets/icons/cross-icon.svg" alt="" />
        </button>
        <input
          type="checkbox"
          [checked]="defaultCard === i"
          (change)="onDefaultChange(i)"
          [disabled]="loading"
        />
      </div>
    </div>
    <div class="add-payment-method_container_add" (click)="navigateToAddCard()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
      >
        <circle
          cx="13"
          cy="13"
          r="12"
          stroke="#5E6272"
          stroke-width="0.5"
          stroke-dasharray="2 2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M 13.4153 8.8931 C 13.3876 8.6887 13.2125 8.5312 13.0006 8.5312 C 12.7694 8.5312 12.5819 8.7187 12.5819 8.9499 V 12.576 H 8.9538 L 8.897 12.5798 C 8.6926 12.6075 8.5352 12.7826 8.5352 12.9946 C 8.5352 13.2258 8.7226 13.4132 8.9538 13.4132 H 12.5819 V 17.039 L 12.5858 17.0958 C 12.6135 17.3001 12.7886 17.4576 13.0006 17.4576 C 13.2317 17.4576 13.4192 17.2702 13.4192 17.039 V 13.4132 H 15.0468 L 17.1036 13.4094 C 17.3079 13.3816 17.4654 13.2065 17.4654 12.9946 C 17.4654 12.7634 17.278 12.576 17.0468 12.576 H 13.4192 V 10.9499 L 13.4153 10.8931 Z"
          fill="white"
        />
        <!-- <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4153 10.8931C13.3876 10.6887 13.2125 10.5312 13.0006 10.5312C12.7694 10.5312 12.5819 10.7187 12.5819 10.9499V12.576H10.9538L10.897 12.5798C10.6926 12.6075 10.5352 12.7826 10.5352 12.9946C10.5352 13.2258 10.7226 13.4132 10.9538 13.4132H12.5819V15.039L12.5858 15.0958C12.6135 15.3001 12.7886 15.4576 13.0006 15.4576C13.2317 15.4576 13.4192 15.2702 13.4192 15.039V13.4132H15.0468L15.1036 13.4094C15.3079 13.3816 15.4654 13.2065 15.4654 12.9946C15.4654 12.7634 15.278 12.576 15.0468 12.576H13.4192V10.9499L13.4153 10.8931Z" fill="white"/> -->
      </svg>
      <p>add new card</p>
    </div>
  </div>
</section>

<!-- mobile version -->
<section class="add-payment-method-mobile">
  <h3 class="add-payment-method-mobile__title">
    {{ "add-payment-method-page.payment" | translate }}
  </h3>
  <div class="add-payment-method-mobile__container">
    <p class="add-payment-method-mobile__container__title">
      {{ "add-payment-method-page.payment-methods" | translate }}
    </p>
    <app-modal-window
      [open]="deleteWindowOpen"
      [title]="'alerts.save2' | translate"
      [okText]="'alerts.ok-btn' | translate"
      [cancelText]="'alerts.cancel-btn' | translate"
      [okFunction]="deletePaymentMethod"
      [cancelFunction]="closeDeleteWindow"
    ></app-modal-window>
    <div
      *ngFor="let card of cardsData; index as i"
      class="add-payment-method_container_cards"
    >
      <div class="add-payment-method_container_cards_title">
        {{ card.paymentMetaData.brand }}
        {{ card.paymentMetaData ? card.paymentMetaData.lastDigits : "" }}
      </div>
      <div class="add-payment-method_container_cards_buttons">
        <button (click)="openDeleteWindow(card.paymentMethodId)">
          <img src="/assets/icons/cross-icon.svg" alt="" />
        </button>
        <input
          type="checkbox"
          [checked]="defaultCard === i"
          (change)="onDefaultChange(i)"
          [disabled]="loading"
        />
      </div>
    </div>
    <div class="add-payment-method-mobile__container__buttons">
      <button
        (click)="navigateToAddCard()"
        class="add-payment-method-mobile__container__buttons__btn"
      >
        <div class="add-payment-method-mobile__plus"></div>
        <p class="add-payment-method-mobile__btn-text">
          {{ "add-payment-method-page.add-card" | translate }}
        </p>
      </button>
    </div>
  </div>
</section>

<!--
   <img
      src="assets/icons/mountains.svg"
      class="MainPage__images__pic mountain"
      alt=""
    />
    <img
      src="assets/icons/bublick.svg"
      class="MainPage__images__pic bublick"
      alt=""
    />
    <img
      src="assets/icons/diagram.svg"
      class="MainPage__images__pic diagram"
      alt=""
    />
-->
