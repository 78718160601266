<!--<button
  style="color: #fff; position: absolute"
  (click)="setTemporalState(temporalState)"
>
  Change state
</button>-->
<section
  class="transactions"
  *ngIf="sidebarOptionsSelected[0] == sidebarOptions[0].options[0]"
>
  <h3 class="transactions__title">
    {{ "transactionsPage.additional-services.title" | translate }}
  </h3>
  <div *ngIf="temporalState" class="add-btn-container">
    <button class="add-btn"></button>
    <p class="add-btn__text">
      {{ "transactionsPage.additional-services.add-service.1" | translate }}
      <br />
      {{ "transactionsPage.additional-services.add-service.2" | translate }}
    </p>
  </div>
  <div class="transactions__buttons" *ngIf="!temporalState">
    <app-subscription-component
      [amount]="'START 15$ / год'"
      [title]="'Ведение бухгалтерии'"
      [payDate]="'24.04.2021'"
      [period]="'21.04.2020 -21.04.2021'"
      [clicked]="clicked == 'Ведение бухгалтерии' ? true : false"
      [onClick]="onSubscrtionButtonClick"
      #subscriptionBtn
    ></app-subscription-component>
    <app-subscription-component
      [amount]="'START 15$ / год'"
      [title]="'Местный номер '"
      [payDate]="'24.04.2021'"
      [period]="'21.04.2020 -21.04.2021'"
      [clicked]="clicked == 'Местный номер ' ? true : false"
      [onClick]="onSubscrtionButtonClick"
    ></app-subscription-component>
  </div>
</section>
<section
  class="transactions"
  *ngIf="sidebarOptionsSelected[0] == sidebarOptions[0].options[1]"
>
  <h3 class="transactions__title">
    {{ "transactionsPage.transactions.title" | translate }}
  </h3>
  <div *ngIf="temporalState" class="transactions__empty">
    <p>{{ "transactionsPage.transactions.empty" | translate }}</p>
  </div>
  <div class="transactions__buttons" *ngIf="!temporalState">
    <app-transaction-component
      name="Бухгалтерия Start"
      date="21/07/2023"
      price="30$"
      [company]="sidebarOptionsSelected[1]"
    >
    </app-transaction-component>
  </div>
</section>
<section class="transactions-mobile">
  <h3 class="transactions__title">
    {{ "transactionsPage.title" | translate }}
  </h3>
  <div class="transactions-mobile__sidebar">
    <div>
      <div class="transactions-mobile__sidebar__buttons">
        <button
          *ngFor="let option of sidebarOptions[0].options"
          class="transactions-mobile__sidebar__buttons__btn"
          [ngClass]="
            sidebarOptionsSelected[sidebarOptions.indexOf(sidebarOptions[0])] ==
            option
              ? 'transactions-mobile__sidebar__buttons__btn-selected'
              : ''
          "
          (click)="
            setSidebarProps(sidebarOptions.indexOf(sidebarOptions[0]), option)
          "
        >
          {{ "transactionsPage.sidebar." + option | translate }}
        </button>
      </div>
    </div>
  </div>

  <div
    class="subscriptions-mobile"
    *ngIf="sidebarOptionsSelected[0] == sidebarOptions[0].options[0]"
  >
    <button *ngIf="temporalState" class="add-btn-mobile">
      <div class="add-btn-mobile__plus"></div>
      <p class="add-btn-mobile__btn-text">
        {{
          "transactionsPage.additional-services.add-service-mobile" | translate
        }}
      </p>
    </button>
    <p *ngIf="!temporalState" class="transactions-mobile__buttons-block-title">
      {{ sidebarOptionsSelected[1] }}
    </p>
    <div *ngIf="!temporalState" class="transactions-mobile__buttons">
      <app-subscription-component
        [amount]="'START 15$ / год'"
        [title]="'Ведение бухгалтерии'"
        [payDate]="'24.04.2021'"
        [period]="'21.04.2020 -21.04.2021'"
        [clicked]="clicked == 'Ведение бухгалтерии' ? true : false"
        [onClick]="onSubscrtionButtonClick"
      ></app-subscription-component>
      <app-subscription-component
        [amount]="'START 15$ / год'"
        [title]="'Местный номер '"
        [payDate]="'24.04.2021'"
        [period]="'21.04.2020 -21.04.2021'"
        [clicked]="clicked == 'Местный номер ' ? true : false"
        [onClick]="onSubscrtionButtonClick"
      ></app-subscription-component>
    </div>
  </div>
  <div
    class="alltransactions-mobile"
    *ngIf="sidebarOptionsSelected[0] == sidebarOptions[0].options[1]"
  >
    <div *ngIf="temporalState" class="transactions__empty">
      <p>{{ "transactionsPage.transactions.empty-mobile" | translate }}</p>
    </div>
    <div *ngIf="!temporalState" class="transactions-mobile__buttons">
      <app-transaction-component
        name="Бухгалтерия Start"
        date="21/07/2023"
        price="30$"
        [company]="
          'transactionsPage.sidebar.' + sidebarOptionsSelected[1] | translate
        "
      >
      </app-transaction-component>
    </div>
  </div>
</section>
