<div class="async-select-container">
  <div class="back-button" (click)="goBack()">
    <img
      src="assets/icons/go-back-icon.svg"
      class="dropdown-type-1__arrow"
    />
  </div>

  <div class="main-container">
    <div class="title">{{ title | translate }}</div>
    <div class="input-container">
      <img
          src="assets/icons/search-icon.svg"
          class="dropdown-type-1__arrow"
        />
      <input
        #searchInput
        class="search-input"
        [placeholder]="'search' | translate"
        [(ngModel)]="searchText"
      />
    </div>

    <div class="data-container">
      <ng-container *ngIf="type === 'category'">
        <div *ngFor="let data of dataSources" class="data-item"  (click)="onRowSelected(data)">
          <p class="item-name">{{ data.name }}</p>
          <img
            src="assets/icons/sm-arrow-right.svg"
            class="dropdown-type-1__arrow"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="type !== 'category'">
        <div *ngFor="let data of dataSources" class="data-item">
          <p class="item-name">{{ data.name }}</p>
          <input type="checkbox" [checked]="isChecked(data)" (change)="onCheckboxChange(data)"/>
        </div>
      </ng-container>
    </div>
  </div>
</div>