import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppState } from 'app/store/app.state';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import {
  sendConfirmationMailCode,
  sendVerificationMailCode,
  setClickedSubscription,
  setConfirmMailTimerAction,
  setModalWindowConfirmMail,
  setRightSidebarSignIn,
  setRightSidebarSignInOptions,
} from 'app/store/Shared/shared.actions';
import { signInRightSidebarProps } from 'app/store/Shared/shared.state';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { setValidationRequired } from 'shared/lib';

@Component({
  selector: 'confirm-mail-page',
  templateUrl: './ui/ConfirmMailPage.component.html',
  styleUrls: ['./ui/ConfirmMailPage.component.scss'],
})
export class ConfirmMailPage {
  @ViewChild('subscriptionBtn') subscriptionBtn: ElementRef;

  timerStarted: boolean = false;
  time: number = 61;
  word: string;
  confirmMailForm: FormGroup;
  codeError: string = '';
  mailAdress: string = '';

  modalConfirmMail: boolean = false;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private fb: FormBuilder
  ) {}
  ngOnInit() {
    this.confirmMailForm = this.fb.group({
      code: this.fb.control('', [Validators.pattern(/^[0-9]{6,6}$/)]),
    });
    this.confirmMailForm.controls['code'].valueChanges.subscribe(
      (data) => data
    );
    this.store
      .select((state) => state.shared.timer)
      .subscribe((timerStarted) => {
        this.timerStarted = timerStarted;
        if (!timerStarted) {
          this.router.navigate(['/account/user-cabinet']);
        }
      });
    this.store
      .select((state) => state.shared.time)
      .subscribe((time) => {
        this.time = time;
        this.codeTimerWord();
      });
    this.store
      .select((state) => state.userinfo.UserInfoData?.email)
      .subscribe((mail) => {
        if (mail) {
          this.mailAdress = mail;
        }
        this.codeTimerWord();
      });
    //getting error message
    this.store
      .select((state) => state.shared.confirmMailError)
      .subscribe((error) => {
        if (error) {
          this.codeError = error;
        }
        this.codeTimerWord();
      });
    //getting confirmMail modal window state
    this.store
      .select((state) => state.shared.ModalWindowConfirmMail)
      .subscribe((btn) => {
        if (btn) {
          this.modalConfirmMail = btn;
        } else {
          this.modalConfirmMail = false;
        }
      });
    setValidationRequired(this.confirmMailForm);
  }

  sendCodeAgain = () => {
    this.store.dispatch(
      setConfirmMailTimerAction({ timeStart: true, time: 5 })
    );
    this.store.dispatch(sendConfirmationMailCode());
  };

  onSubmit = () => {
    this.store.dispatch(
      sendVerificationMailCode({
        code: this.confirmMailForm.controls.code.value,
      })
    );
  };

  openConfirmMailWindow = () => {};
  closeConfirmMailWindow = () => {
    this.store.dispatch(setModalWindowConfirmMail({ show: false }));
    this.router.navigateByUrl('/account/user-cabinet');
    //this.router.navigateByUrl('/account/user-cabinet?confirmed=1');
    //this.router.navigate(['/account/user-cabinet'], {queryParams: {confirmed: 1}});
  };

  Valid = (): boolean => {
    if (
      this.confirmMailForm.valid &&
      this.confirmMailForm.controls.code.value !== ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  codeTimerWord = (): void => {
    let timerStr = this.time.toString().split('');
    if (1 < this.time && this.time < 5) {
      this.word = this.translateService.instant('reset-pwd.sec.3');
    } else if (1 == this.time) {
      this.word = this.translateService.instant('reset-pwd.sec.2');
    } else if (this.time > 5 && this.time < 21) {
      this.word = this.translateService.instant('reset-pwd.sec.1');
    } else if (
      Number(timerStr[timerStr.length - 1]) > 1 &&
      Number(timerStr[timerStr.length - 1]) < 5
    ) {
      this.word = this.translateService.instant('reset-pwd.sec.3');
    } else if (Number(timerStr[timerStr.length - 1]) == 1) {
      this.word = this.translateService.instant('reset-pwd.sec.2');
    } else {
      this.word = this.translateService.instant('reset-pwd.sec.1');
    }
  };
}
