import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { AppState } from 'app/store/app.state';
import { Company } from 'app/store/Shared/model/SharedModel';
import { Subscription } from 'rxjs';

import {
  getOrderById,
  getOrderByIdSuccess,
  getCompanyOptions,
  getCompanyOptionsSuccess,
  // getServices,
  // getServicesSuccess,
  getServicesByFormId,
  getServicesByFormIdSuccess,
  getOrders,
  getOrdersSuccess,
  getPaymentMethods,
  getPaymentMethodsSuccess,
  addPayment,
  addPaymentSuccess,
  getServiceCategories,
  getServiceCategoriesSuccess,
} from 'app/store/Shared/shared.actions';

@Component({
  selector: 'app-my-companies-page',
  templateUrl: './ui/MyCompaniesEditPage.component.html',
  styleUrls: ['./ui/MyCompaniesEditPage.component.scss'],
})
export class MyCompaniesEditPage {
  id: string;
  loading: boolean = false;
  myCompany: any = [];
  countries: any = [];
  forms: any = [];
  amount: any;
  services: any = [];
  selectedServices: any = [];
  renewalService: any = null;
  paymentMethodId: string;
  
  subs: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });

    this._getCompanyById();
    this._getCountries();
    this._getForms();
    this._getPaymentMethods();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _getCompanyById() {
    this.loading = true;
    this.store.dispatch(getOrderById({id: this.id}));
    this.subs.push(
      this.actions
        .pipe(ofType(getOrderByIdSuccess.type))
        .subscribe(({data}: any) => {
          this.loading = false;
          this.myCompany = data;
          this.amount = data.authorizedCapital;

          this._getCompanyServices();
        })
      )
  }

  private _getCountries() {
    this.store
      .select((state) => state.shared.countries)
      .subscribe((countries) => {
        if (countries) {
          this.countries = countries;
        }
      });
  }

  private _getForms() {
    this.store.dispatch(getCompanyOptions());

    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyOptionsSuccess.type))
        .subscribe(({options}: any) => {
          if (options) {
            options.map((option: any) => {
              option.legalForms.map((lf: any) => {
                this.forms.push(lf);
              })
            })
          }
        })
      )
  }

  // private _getServices() {
  //   this.store.dispatch(getServices({data: {}}));

  //   this.subs.push(
  //     this.actions
  //       .pipe(ofType(getServicesSuccess.type))
  //       .subscribe(({data}: any) => {
  //         this.services = data;
  //       })
  //     )
  // }

  private _getCompanyServices() {
    this.store.dispatch(getServicesByFormId({data: { formId: this.myCompany.legalFormId }}));

    this.subs.push(
      this.actions
        .pipe(ofType(getServicesByFormIdSuccess.type))
        .subscribe(({data}: any) => {
          this.services = data;
          this._getServiceCategories();
        })
      )
  }

  private _getServiceCategories() {
    this.store.dispatch(getServiceCategories());

    this.subs.push(
      this.actions
        .pipe(ofType(getServiceCategoriesSuccess.type))
        .subscribe(({data}: any) => {
          this.services = this.services.map((service: any) => {
            let info = data.find((data: any) => data.id === service.serviceCategoryId);
            return Object.assign({}, service, {isVisible: info.isVisible || 0});
          });
          this._getOrders();
        })
      )
  }

  private _getOrders() {
    this.store.dispatch(getOrders({data: { companyId: this.id }}));

    this.subs.push(
      this.actions
        .pipe(ofType(getOrdersSuccess.type))
        .subscribe(({data}: any) => {
          this.selectedServices = data;

          this.renewalService = this.services.find((s: any) => s.code === "company_renewal"); //find renewal service
          this.services = this.services.filter((s: any) => s.code !== "company_renewal");  //remove renewal from avaliable services

          this.selectedServices = this.selectedServices.map((service: any) => {
            let info = this.services.find((data: any) => data.id === service.serviceId);
            this.services = this.services.filter((s: any) => s.id !== service.serviceId);
            return Object.assign({}, service, {isVisible: info && info.isVisible || 0});  //add isVisible field to orders
          });

          this.selectedServices = this.selectedServices.filter((service: any) => service.isVisible); //remove non-visible elements
        })
      );
  }

  private _getPaymentMethods() {
    this.store.dispatch(getPaymentMethods());

    this.subs.push(
      this.actions
      .pipe(ofType(getPaymentMethodsSuccess))
      .subscribe(({data}) => {
        this.paymentMethodId = data.find((e: any) => e.isDefault)!.paymentMethodId;
      })
    );
    // this.sharedService.getPaymentMethods()
    //   .subscribe(({data}) => {
    //    this.paymentMethodId = data.find((e: any) => e.isDefault)!.paymentMethodId;
    //   });
  }

  public addCompanyClick() {
    this.router.navigate([`account/add-company/`]);
  }

  public getRenewalDate(date: any) {
    const renewalDate = new Date(date);
    renewalDate.setFullYear(renewalDate.getFullYear() + 1);
    return renewalDate;
  }

  public getCountryById(id: string) {
    if (this.countries.length) {
      return this.countries.find((e: any) => e.id === id).title;
    }
  }

  public getFormNameById(id: string) {
    if (this.forms.length) {
      return this.forms.find((e: any) => e.legalFormId === id).title;
    }
  }

  public getAmount(value: string, id: string) {
    if (this.forms.length) {
      let capital = this.forms.find((e: any) => e.legalFormId === id).authorizedCapital;
      return value === 'max' 
        ? capital.maxSumm + capital.type === 0 
          ? capital.summ
          : 0
        : capital.minSumm + capital.type === 0 
          ? capital.summ
          : 0;
    }
  }

  public downloadFiles(link: string) {
    console.log('download button clicked');
  }

  public payButtonClick(id: string, serviceId?: string) {
    const data = {
      companyId: id,
      paymentMethodId: this.paymentMethodId,
      serviceId: serviceId ? serviceId : this.renewalService.id
    }
    this.store.dispatch(addPayment({data}));

    // this.subs.push(
    //   this.actions
    //   .pipe(ofType(addPaymentSuccess))
    //   .subscribe(({data}) => {
    //   })
    // );
  }

  public getNamesFromArray(array: any) {
    if (array && array.length) {
      let names: any = [];
      array.map((sub: any) => {
        names.push(sub.name);
      });
      return names.join(', ');
    } else {
      return "---";
    }
  }
}
