import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { setValidationRequired } from 'shared/lib';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import {
  RegistrationSendCode,
  setRegistrationData,
  setRegistrationTimerAction,
  RegistrationCodeSuccess,
  registrationSetStep,
} from 'app/store/Registration/registration.actions';
import { registrationData } from 'app/store/Registration/models/registrationData.model';
import { setAllValidationRequired } from 'shared/lib/setValidationRequired/setValidationRequired';
import { TranslateService } from '@ngx-translate/core';
import { map, Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';

export type Step = 'personalInfo' | 'codeInfo';

@Component({
  selector: 'app-reg-page',
  templateUrl: './ui/reg-page.component.html',
  styleUrls: ['./ui/reg-page.component.scss'],
})
export class RegPageComponent implements OnInit {
  userForm: FormGroup;
  step: number = 0;
  timer: number = 61;
  timerStarted: boolean = false;
  word: string;
  phoneNumber: number;
  numberError: string | null = null;
  errorMessage: string | null;
  regData: registrationData | null;

  numberConfirmed: boolean = false;

  subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private actions: Actions,
    private router: Router,
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {
    //this.userForm.valueChanges.subscribe(value => console.log(value));
  }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      userInfo: this.fb.group({
        name: this.fb.control(''),
        lastName: this.fb.control(''),
        phoneNumber: this.fb.control('', [
          Validators.pattern(
            // /^(\+7|7|8)[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
            // /^(\+7|7|8|49)[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{3}$/
            // /^(\+7|7|8|\+49(1?))[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
            /^(\+)(7|49(1?))[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
          ),
        ]),
      }),
      codeInfo: this.fb.group({
        code: this.fb.control('', [Validators.pattern(/^[0-9]{6,6}$/)]),
      }),
    });

    this.userForm.controls['userInfo'].valueChanges.subscribe((data) => data);

    this.store
      .select((state) => state.registration.timer)
      .subscribe((timerStarted) => (this.timerStarted = timerStarted));

    this.store
      .select((state) => state.registration.registrationData)
      .subscribe((data) => {
        this.regData = data;
        if (data) {
          this.userForm.get('userInfo')!.setValue(this.regData);
        }
      });

    this.store
      .select((state) => state.registration.time)
      .subscribe((time) => {
        this.timer = time;
        this.codeTimerWord();
      });

    this.store
      .select((state) => state.registration.step)
      .subscribe((step) => (this.step = step));

    this.store
      .select((state) => state.registration.numberConfirmed)
      .subscribe((numberConfirmed) => (this.numberConfirmed = numberConfirmed));

    this.store
      .select((state) => state.registration.errMessage)
      .subscribe((numberError) => {
        this.numberError = numberError;
        setAllValidationRequired(
          this.userForm,
          'userInfo',
          this.numberError ? true : false
        );
      });

    this.store
      .select((state) => state.registration.errMessage)
      .subscribe((err) => (this.errorMessage = err));

    setValidationRequired(this.userForm, 'userInfo', this.numberError);

    console.log('phone', this.regData?.getName, this.regData?.getPhoneNumber);

    // this.userForm.controls.userInfo.setValue({
    //   Name: this.regData?.getName,
    //   lastName: this.regData?.getLastName,
    //   phoneNumber: Number(this.regData?.getPhoneNumber),
    // });
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  phoneNumberValidation = (): string => {
    let phoneNumber: string = this.userForm.controls.userInfo.value.phoneNumber;
    const newPhone = String(phoneNumber).split('');
    // if (newPhone[0] == '8' || newPhone[0] == '7') {
      // newPhone[0] = '+7';
    // }
    return newPhone.join('');
  };

  sendCode() {
    const name: string = this.userForm.controls.userInfo.value.name;
    const lastName: string = this.userForm.controls.userInfo.value.lastName;
    const phoneNumber = this.phoneNumberValidation();

    const currentRegistrationData = new registrationData(
      name,
      lastName,
      phoneNumber
    );
    this.store.dispatch(
      setRegistrationTimerAction({ timeStart: false, time: 60 })
    );
    this.store.dispatch(
      setRegistrationData({
        registrationData: currentRegistrationData,
        phoneNumber: phoneNumber,
      })
    );
  }

  firstStep = (): void => {
    const userInfo = this.userForm.get('userInfo') as FormArray;
    userInfo !== null
      ? Object.keys(userInfo.controls).forEach((key) => {
          let userInfoItem = userInfo.get(key);
          if (userInfoItem?.value == '') {
            userInfoItem?.addValidators(Validators.required);
          } else {
            userInfoItem?.removeValidators(Validators.required);
          }
          userInfoItem?.updateValueAndValidity();
        })
      : '';
    if (userInfo.valid) {
      this.sendCode();
    }
  };

  resendCode() {
    this.sendCode();
  }
  backwardStep = (): void => {
    this.step -= 1;
  };

  codeTimer = (): void => {};

  codeTimerWord = (): void => {
    let timerStr = this.timer.toString().split('');
    if (1 < this.timer && this.timer < 5) {
      this.word = this.translateService.instant('reg-page.sec.3');
    } else if (1 == this.timer) {
      this.word = this.translateService.instant('reg-page.sec.2');
    } else if (this.timer > 5 && this.timer < 21) {
      this.word = this.translateService.instant('reg-page.sec.1');
    } else if (
      Number(timerStr[timerStr.length - 1]) > 1 &&
      Number(timerStr[timerStr.length - 1]) < 5
    ) {
      this.word = this.translateService.instant('reg-page.sec.3');
    } else if (Number(timerStr[timerStr.length - 1]) == 1) {
      this.word = this.translateService.instant('reg-page.sec.2');
    } else {
      this.word = this.translateService.instant('reg-page.sec.1');
    }
  };
  submitForm = (): void => {
    const codeInfo = this.userForm.get('codeInfo') as FormArray;
    if (this.numberConfirmed) {
      this.store.dispatch(RegistrationCodeSuccess());
      return;
    }
    codeInfo !== null
      ? Object.keys(codeInfo.controls).forEach((key) => {
          let codeInfoItem = codeInfo.get(key);
          if (codeInfoItem?.value == '') {
            codeInfoItem?.addValidators(Validators.required);
          } else {
            codeInfoItem?.removeValidators(Validators.required);
          }
          codeInfoItem?.updateValueAndValidity();
        })
      : '';
    if (codeInfo.valid) {
      const value: string = codeInfo.get('code')?.value;

      if (value.length == 6) {
        const phoneNumber = this.phoneNumberValidation();
        //this.router.navigate(['/new-pwd']);

        this.store.dispatch(
          RegistrationSendCode({ code: value, phoneNumber: phoneNumber })
        );
      }
    }
  };

  stepBack() {
    const newStep = this.step - 1;
    this.store.dispatch(registrationSetStep({ step: newStep }));
  }
  stepForward() {
    const newStep = this.step + 1;
    this.store.dispatch(registrationSetStep({ step: newStep }));
  }

  private _successCodeListener() {
    this.subs.push(
      this.actions.pipe(ofType(RegistrationCodeSuccess)).subscribe(() => {})
    );
  }
}
