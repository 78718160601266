import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { setClickedSubscription } from 'app/store/Shared/shared.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

@Component({
  selector: 'app-subscription-component',
  templateUrl: './ui/SubcscriptionComponent.component.html',
  styleUrls: ['./ui/SubcscriptionComponent.component.scss'],
})
export class SubcscriptionComponent {
  @Input() payDate: string;
  @Input() period: string;
  @Input() title: string;
  @Input() amount: string;
  @Input() clicked: boolean = false;
  @Input() onClick: (val: string | number, event: Event) => void;
  @ViewChild('subscriptionBtn') subscriptionBtn: ElementRef;

  constructor(private renderer: Renderer2, private store: Store<AppState>) {
    // this.renderer.listen('window', 'click', (e: Event) => {
    //   /**
    //    * Only run when toggleButton is not clicked
    //    * If we don't check this, all clicks (even on the toggle button) gets into this
    //    * section which in the result we might never see the menu open!
    //    * And the menu itself is checked here, and it's where we check just outside of
    //    * the menu and button the condition abbove must close the menu
    //    */
    //   // console.log(this.subscriptionBtn.nativeElement, e.target);
    //   // if (e.target !== this.subscriptionBtn.nativeElement) {
    //   //   this.store.dispatch(
    //   //     setClickedSubscription({
    //   //       options: null,
    //   //     })
    //   //   );
    //   // }
    // });
  }
}
