import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { responceData } from 'app/models/responce.model';
import { Observable } from 'rxjs';
import { setResetPwdTimerAction } from '../resetPwd.actions';
import { AppState } from 'app/store/app.state';
import { Store } from '@ngrx/store';
import { environment } from '@environment/environment';


@Injectable({
  providedIn: 'root',
})
export class resetPwdServiceService {
  BASE_URL = `${environment.API_BASE_URL}`;
  constructor(
    private http: HttpClient, 
    private store: Store<AppState>
  ) {}

  checkPhoneNumber(phoneNumber: string): Observable<responceData> {
    return this.http.post<responceData>(
      `${this.BASE_URL}/forgot-password`,
      { phoneNumber: phoneNumber }
    );
  }
  sendCode(phoneNumber: string, code: string): Observable<responceData> {
    return this.http.post<responceData>(
      `${this.BASE_URL}/forgot-password/verify`,
      { phoneNumber: phoneNumber, verificationCode: code }
    );
  }
  sendResetPassword(password: string): Observable<responceData> {
    let gotToken: string = '';
    this.store
      .select((state) => state.resetPwd.token)
      .subscribe((token) => {
        gotToken = token;
      });
    return this.http.post<responceData>(
      `${this.BASE_URL}/forgot-password/reset`,
      { password: password },
      {
        headers: {
          Authorization:
            `Bearer ${gotToken}`,
        },
      }
    );
  }

  codeTimer = (): void => {
    let time: number;
    this.store
      .select((state) => state.resetPwd.time)
      .subscribe((timer) => {
        time = timer;
      });

    console.log('TIIIME');
    const timePut = setTimeout(() => {
      time -= 1;
      if (time < 0) {
        clearTimeout(timePut);
        return;
      }
      this.store.dispatch(
        setResetPwdTimerAction({ timeStart: true, time: time })
      );
      this.codeTimer();
    }, 1000);
  };
}
