import { createAction, props } from '@ngrx/store';
import { signInRightSidebarProps } from './shared.state';
import {
  CityData,
  CompanyData,
  CompanyDraftData,
  CompanyDraftResponceData,
  CompanyResponceData,
  CountryData,
  MacroregionData,
  RegionData,
  SubscriptionOptions,
  companyOption,
  templateResponseData,
} from './model/SharedModel';

export const SET_LOADING_ACTION = '[shared state] set loading spinner';
export const SET_LOGIN_ERROR = '[shared state] set login error message';
export const SET_LOGIN_ERROR_CODE = '[shared state] set login error code';
export const AUTO_LOGIN_ACTION = '[shared state] auto login';
export const SET_LOGOUT_MESSAGE = '[shared state] set logout message';
export const SET_RIGTH_SIDEBAR_SIGNIN =
  '[shared state] set right sidebar sign in';
export const SET_RIGTH_SIDEBAR_SIGNIN_OPTIONS =
  '[shared state] set right sidebar sign in options';
export const SET_CLICKED_SUBSCRIPTION =
  '[shared state] set clicked subscription';
export const ADD_PROFILE_IMAGE =
  '[shared state] set selected profile image to state';
export const SEND_FILE = '[shared state] send file';
export const SEND_FILE_SUCCESS = '[shared state] send file success';
export const SEND_FILE_FAILURE = '[shared state] send file failure';

/*REQUESTS*/
export const SET_REQUEST_ERROR = '[shared state] set request error';
export const SET_REQUEST_SUCCESS = '[shared state] set request success';
/*REQUESTS*/

/* LOCATIONS */
export const GET_COUNTRIES_DATA = '[shared state] get all countries data';
export const GET_COUNTRIES_DATA_SUCCESS =
  '[shared state] SUCCESS get all countries data';
export const GET_REGIONS_DATA = '[shared state] get all regions data';
export const GET_REGIONS_DATA_SUCCES =
  '[shared state] SUCCESS get all regions data';
export const GET_MACROREGIONS_DATA = '[shared state] get all macroregions data';
export const GET_MACROREGIONS_DATA_SUCCESS =
  '[shared state] SUCCESS get all macroregions data';
export const GET_CITIES_DATA = '[shared state] get all cities data';
export const GET_CITIES_DATA_SUCCESS =
  '[shared state] SUCCESS get all cities data';
export const GET_COUNTRIES_BY_MACRO_DATA =
  '[shared state] get all COUNTRIES BY MACRO data';
export const GET_COUNTRIES_BY_MACRO_DATA_SUCCESS =
  '[shared state] SUCCESS get all countries by macro id';
//get city by coutry id
export const GET_CITIES_BY_COUNTRY = '[shared state] get cities by country id';
export const GET_CITIES_BY_COUNTRY_SUCCESS =
  '[shared state] get cities by country id success';
/* LOCATIONS */

export const SET_LANGUAGE = '[shared state] settingLanguage';

export const SEND_CONFIRM_MAIL_CODE =
  '[shared state] SENDING CONFIRM mail CODE';

export const SEND_CONFIRM_MAIL_CODE__SUCCESS =
  '[shared state] SUCCESS SENDING CONFIRM mail CODE ';
export const SEND_VERIFICATION_MAIL_CODE =
  '[shared state] SENDING VERIFICATION mail CODE';
export const SEND_VERIFICATION_MAIL_CODE_SUCCESS =
  '[shared state] SUCCESS SENDING VERIFICATION mail CODE';
export const SET_CONFIRM_MAIL_TIMER =
  '[shared state] setting confirm mail timer';
export const SET_CONFIRM_MAIL_ERROR = '[shared state] CONFIRM mail ERROR';
export const SET_VEREFICATION_MAIL_ERROR =
  '[shared state] VERIFICATION mail ERROR';

//right sidebar buttons
export const SET_RIGTH_SIDEBAR_SAVE_BTN_CABINET_INFO =
  '[shared state] setting SAVE BTN for CABINET INFO';
export const SET_RIGTH_SIDEBAR_BUTTONS_USER_INFO =
  '[shared state] setting BUTTON for USER INFO PAGE';
export const SET_RIGTH_SIDEBAR_SAVE_BTN_USER_INFO =
  '[shared state] setting SAVE BUTTON for USER INFO PAGE';
export const SET_RIGTH_SIDEBAR_ORDERS_BTNS =
  '[shared state] setting BUTTONS for ORDERS';

//MODAL WINDOWS//
export const SET_MODAL_USER_INFO_SAVE_WINDOW =
  '[shared state] setting MODAL WINDOW for USER INFO PAGE';
export const SET_MODAL_CONFIRM_MAIL =
  '[shared state] setting MODAL WINDOW for MAIL CONFIRM';
export const SET_MODAL_LOGOUT = '[shared state] setting MODAL WINDOW Logout';

//add company
export const SET_COMPANY_SHARES = '[shared state] set comapny shares';

export const SET_ADD_COMPANY_STEP = '[shared state] set add company step';

export const SET_EDITABLE_COMPANY_STEP = '[shared state] set add company step';

export const REGISTER_COMPANY = '[shared state] REGISTER COMPANY';
export const REGISTER_COMPANY_SUCCESS =
  '[shared state] SUCCESS REGISTER COMPANY';
export const REGISTER_COMPANY_ERROR =
  '[shared state] set  ERROR REGISTER COMPANY';

export const ADD_COMPANY_DRAFT = '[shared state] add company draft';
export const ADD_COMPANY_DRAFT_SUCCESS =
  '[shared state] add company draft success';
export const ADD_COMPANY_DRAFT_DATA_TO_STATE =
  '[shared state] add company draft data to state';

export const GET_COMPANY_OPTIONS = '[shared state] get company options';
export const GET_COMPANY_OPTIONS_SUCCESS =
  '[shared state] get company options SUCCESS';
export const GET_COMPANY_BY_ID = '[shared state] get company by id';
export const GET_COMPANY_BY_ID_SUCCESS =
  '[shared state] get company by id success';
export const GET_COMPANY_BY_ID_FAILURE =
  '[shared state] get company by id failure';
export const CREATE_TEMPLATE = '[shared state] create template';
export const CREATE_TEMPLATE_SUCCESS = '[shared state] create template success';
export const CREATE_TEMPLATE_FAILURE = '[shared state] create template failure';
export const UPDATE_TEMPLATE = '[shared state] update template';
export const UPDATE_TEMPLATE_SUCCESS = '[shared state] update template success';
export const UPDATE_TEMPLATE_FAILURE = '[shared state] update template failure';

export const GET_MY_ORDERS = '[shared state] get my orders';
export const GET_MY_ORDERS_SUCCESS = '[shared state] get my orders success';
export const GET_MY_ORDERS_FAILURE = '[shared state] get my orders failure';

export const GET_MY_ORDER_BY_ID = '[shared state] get my order by id';
export const GET_MY_ORDER_BY_ID_SUCCESS =
  '[shared state] get my order by id success';
export const GET_MY_ORDER_BY_ID_FAILURE =
  '[shared state] get my order by id failure';
export const DELETE_ORDER = '[shared state] delete order';
export const DELETE_ORDER_SUCCESS = '[shared state] delete order success';
export const DELETE_ORDER_FAILURE = '[shared state] delete order failure';
export const SET_DELETE_ORDER_MODAL = '[shared state] set delete order modal';

export const SEND_CARD_TOKEN = '[shared state] send card token';
export const SEND_CARD_TOKEN_SUCCESS = '[shared state] send card token success';
export const SEND_CARD_TOKEN_FAILURE = '[shared state] send card token failure';

export const GET_PAYMENT_METHODS = '[shared state] get payment methods';
export const GET_PAYMENT_METHODS_SUCCESS =
  '[shared state] get payment methods success';
export const GET_PAYMENT_METHODS_FAILURE =
  '[shared state] get payment methods failure';
export const DELETE_PAYMENT_METHOD = '[shared state] delete payment method';

export const SET_DELETE_PAYMENT_METHOD_MODAL =
  '[shared state] set delete payment method MODAL';

export const GET_SERVICES = '[shared state] get services';
export const GET_SERVICES_SUCCESS = '[shared state] get services success';
export const GET_SERVICES_FAILURE = '[shared state] get services failure';

export const GET_SERVICE_CATEGORIES = '[shared state] get service categories';
export const GET_SERVICE_CATEGORIES_SUCCESS =
  '[shared state] get service categories success';
export const GET_SERVICE_CATEGORIES_FAILURE =
  '[shared state] get service categories failure';

export const GET_SERVICES_BY_FORM_ID = '[shared state] get services by form id';
export const GET_SERVICES_BY_FORM_ID_SUCCESS =
  '[shared state] get services by form id success';
export const GET_SERVICES_BY_FORM_ID_FAILURE =
  '[shared state] get services by form id failure';

export const GET_ORDERS = '[shared state] get orders';
export const GET_ORDERS_SUCCESS = '[shared state] get orders success';
export const GET_ORDERS_FAILURE = '[shared state] get orders failure';

export const ADD_PAYMENT = '[shared state] add payment';
export const ADD_PAYMENT_SUCCESS = '[shared state] add payment success';
export const ADD_PAYMENT_FAILURE = '[shared state] add payment failure';

export const CLEAR_COMPANY_ID = '[shared state] creal company id';

//add company
export const setLoadingSpinner = createAction(
  SET_LOADING_ACTION,
  props<{ status: boolean }>()
);

export const setLoginErrorMessage = createAction(
  SET_LOGIN_ERROR,
  props<{ message: string | boolean }>()
);

export const setLogoutMessage = createAction(
  SET_LOGOUT_MESSAGE,
  props<{ message: string | boolean }>()
);

export const setLoginErrorCode = createAction(
  SET_LOGIN_ERROR_CODE,
  props<{ code: number; message: string }>()
);

export const setRightSidebarSignIn = createAction(
  SET_RIGTH_SIDEBAR_SIGNIN,
  props<{ props: signInRightSidebarProps[] | null }>()
);
export const setRightSidebarSignInOptions = createAction(
  SET_RIGTH_SIDEBAR_SIGNIN_OPTIONS,
  props<{ options: string[] }>()
);
export const setClickedSubscription = createAction(
  SET_CLICKED_SUBSCRIPTION,
  props<{ options: SubscriptionOptions | null }>()
);

export const autoLogin = createAction(AUTO_LOGIN_ACTION);

export const addProfileImage = createAction(
  ADD_PROFILE_IMAGE,
  props<{ file: any }>()
);

export const sendFile = createAction(SEND_FILE, props<{ data: any }>());

export const sendFileSuccess = createAction(
  SEND_FILE_SUCCESS,
  props<{ data: any }>()
);

export const sendFileFailure = createAction(
  SEND_FILE_FAILURE,
  props<{ error: any }>()
);

/* LOCATIONS */
export const getCountriesData = createAction(GET_COUNTRIES_DATA);
export const getCountriesDataSuccess = createAction(
  GET_COUNTRIES_DATA_SUCCESS,
  props<{
    countries: CountryData[];
  }>()
);
export const getMacroRegionsData = createAction(GET_MACROREGIONS_DATA);
export const getMacroRegionsDataSuccess = createAction(
  GET_MACROREGIONS_DATA_SUCCESS,
  props<{
    macroregions: {
      id: string;
      name: string;
      continent: string;
    }[];
  }>()
);
export const getRegionsData = createAction(GET_REGIONS_DATA);
export const getRegionsDataSuccess = createAction(
  GET_REGIONS_DATA_SUCCES,
  props<{
    regions: {
      id: string;
      name: string;
      inCountry: string;
    }[];
  }>()
);
export const getCitiesData = createAction(GET_CITIES_DATA);
export const getCitiesDataSuccess = createAction(
  GET_CITIES_DATA_SUCCESS,
  props<{
    cities: {
      id: string;
      name: string;
      inRegion: string;
    }[];
  }>()
);

// cities by country id
export const getCitiesByCountry = createAction(
  GET_CITIES_BY_COUNTRY,
  props<{
    countryId: string;
  }>()
);
export const getCitiesByCountrySuccess = createAction(
  GET_CITIES_BY_COUNTRY_SUCCESS,
  props<{
    cities: {
      id: string;
      name: string;
      inRegion: string;
    }[];
  }>()
);
// cities by country id
export const getCountriesByMacroregion = createAction(
  GET_COUNTRIES_BY_MACRO_DATA,
  props<{
    macroregionId: string;
  }>()
);
export const getCountriesByMacroregionSuccess = createAction(
  GET_COUNTRIES_BY_MACRO_DATA_SUCCESS,
  props<{
    countries: CountryData[];
  }>()
);
/* LOCATIONS */

export const setLanguage = createAction(
  SET_LANGUAGE,
  props<{
    lang: string;
  }>()
);

// MAIL CONFIRM ACTIONS //
export const sendConfirmationMailCode = createAction(SEND_CONFIRM_MAIL_CODE);
export const sendConfirmationMailCodeSuccess = createAction(
  SEND_CONFIRM_MAIL_CODE__SUCCESS
);
export const setConfirmMailTimerAction = createAction(
  SET_CONFIRM_MAIL_TIMER,
  props<{ timeStart: boolean; time: number }>()
);
export const sendVerificationMailCode = createAction(
  SEND_VERIFICATION_MAIL_CODE,
  props<{ code: string }>()
);
export const sendVerificationMailCodeSuccess = createAction(
  SEND_VERIFICATION_MAIL_CODE_SUCCESS
);
export const setVerificationMailCodeError = createAction(
  SET_VEREFICATION_MAIL_ERROR,
  props<{ error: string }>()
);
export const setConfirmMailError = createAction(
  SET_CONFIRM_MAIL_ERROR,
  props<{ error: string }>()
);

//SIDEBAR BUTTONS

//save button cabinet info
export const setRigtSidebarSaveCabinetInfo = createAction(
  SET_RIGTH_SIDEBAR_SAVE_BTN_CABINET_INFO,
  props<{ show: boolean }>()
);

// buttons user info
export const setRigtSidebarButtonsUserInfo = createAction(
  SET_RIGTH_SIDEBAR_BUTTONS_USER_INFO,
  props<{ show: boolean }>()
);

//save button user info
export const setRigtSidebarSaveButtonUserInfo = createAction(
  SET_RIGTH_SIDEBAR_SAVE_BTN_USER_INFO,
  props<{ show: boolean }>()
);

//orders buttons
export const setRigtSidebarOrdersButtons = createAction(
  SET_RIGTH_SIDEBAR_ORDERS_BTNS,
  props<{ button: string | null }>()
);

//MODAL WINDOWS//
export const setModalWindowUserInfoSave = createAction(
  SET_MODAL_USER_INFO_SAVE_WINDOW,
  props<{ show: boolean }>()
);
export const setModalWindowConfirmMail = createAction(
  SET_MODAL_CONFIRM_MAIL,
  props<{ show: boolean }>()
);
export const setModalWindowLogout = createAction(
  SET_MODAL_LOGOUT,
  props<{ show: boolean }>()
);

/* ADD COMPANY */
export const setCompanyShares = createAction(
  SET_COMPANY_SHARES,
  props<{ number: number | string; act: string }>()
);
export const setAddCompanyStep = createAction(
  SET_ADD_COMPANY_STEP,
  props<{ step?: number; show?: boolean }>()
);

export const setEditableCompanyStep = createAction(
  SET_EDITABLE_COMPANY_STEP,
  props<{ value: string }>()
);

export const registerCompany = createAction(
  REGISTER_COMPANY,
  props<{ company: CompanyData }>()
);

export const SuccessRegisterCompany = createAction(
  REGISTER_COMPANY_SUCCESS,
  props<{ company: CompanyResponceData[] }>()
);
export const setCompanyErrorMessage = createAction(
  REGISTER_COMPANY_ERROR,
  props<{ message: string }>()
);
export const addCompanyDraft = createAction(
  ADD_COMPANY_DRAFT,
  props<{ data: CompanyDraftData }>()
);
export const addCompanyDraftSuccess = createAction(
  ADD_COMPANY_DRAFT_SUCCESS,
  props<{ data: CompanyDraftResponceData }>()
);
export const addCompanyDraftDataState = createAction(
  ADD_COMPANY_DRAFT_DATA_TO_STATE,
  props<{ data: CompanyData | null }>()
);
export const getCompanyOptions = createAction(GET_COMPANY_OPTIONS);
export const getCompanyOptionsSuccess = createAction(
  GET_COMPANY_OPTIONS_SUCCESS,
  props<{ options: companyOption[] | null }>()
);

export const getCompanyById = createAction(
  GET_COMPANY_BY_ID,
  props<{ id: string }>()
);
export const getCompanyByIdSuccess = createAction(
  GET_COMPANY_BY_ID_SUCCESS,
  props<{ data: any }>()
);
export const getCompanyByIdFailure = createAction(
  GET_COMPANY_BY_ID_FAILURE,
  props<{ error: any }>()
);
export const clearCompanyId = createAction(CLEAR_COMPANY_ID);

export const createTemplate = createAction(
  CREATE_TEMPLATE,
  props<{ data: any }>()
);
export const createTemplateSuccess = createAction(
  CREATE_TEMPLATE_SUCCESS,
  props<{ data: string }>()
);
export const createTemplateFailure = createAction(
  CREATE_TEMPLATE_FAILURE,
  props<{ error: any }>()
);

export const updateTemplate = createAction(
  UPDATE_TEMPLATE,
  props<{ data: any; id: string }>()
);
export const updateTemplateSuccess = createAction(UPDATE_TEMPLATE_SUCCESS);
export const updateTemplateFailure = createAction(
  UPDATE_TEMPLATE_FAILURE,
  props<{ error: any }>()
);
/* ADD COMPANY */

export const getOrderList = createAction(GET_MY_ORDERS);
export const getOrderListSuccess = createAction(
  GET_MY_ORDERS_SUCCESS,
  props<{ data: any }>()
);
export const getOrderListFailure = createAction(
  GET_MY_ORDERS_FAILURE,
  props<{ error: any }>()
);

export const getOrderById = createAction(
  GET_MY_ORDER_BY_ID,
  props<{ id: string }>()
);
export const getOrderByIdSuccess = createAction(
  GET_MY_ORDER_BY_ID_SUCCESS,
  props<{ data: any }>()
);
export const getOrderByIdFailure = createAction(
  GET_MY_ORDER_BY_ID_FAILURE,
  props<{ error: any }>()
);

export const deleteOrder = createAction(DELETE_ORDER, props<{ id: string }>());
export const deleteOrderSuccess = createAction(
  DELETE_ORDER_SUCCESS, 
  props<{ data: any }>()
);
export const deleteOrderFailure = createAction(
  DELETE_ORDER_FAILURE, 
  props<{ error: any }>()
);

export const setDeleteOrderModal = createAction(
  SET_DELETE_ORDER_MODAL,
  props<{ open: boolean; order: string }>()
);
export const sendCardToken = createAction(
  SEND_CARD_TOKEN,
  props<{ data: any }>()
);
export const sendCardTokenSuccess = createAction(
  SEND_CARD_TOKEN_SUCCESS,
  props<{ data: any }>()
);
export const sendCardTokenFailure = createAction(
  SEND_CARD_TOKEN_FAILURE,
  props<{ error: any }>()
);

export const getPaymentMethods = createAction(GET_PAYMENT_METHODS);

export const getPaymentMethodsSuccess = createAction(
  GET_PAYMENT_METHODS_SUCCESS,
  props<{ data: any }>()
);
export const getPaymentMethodsFailure = createAction(
  GET_PAYMENT_METHODS_FAILURE,
  props<{ error: any }>()
);

export const deletePaymentMethod = createAction(
  DELETE_PAYMENT_METHOD,
  props<{ id: string }>()
);
export const setDeletePaymentMethodModal = createAction(
  SET_DELETE_PAYMENT_METHOD_MODAL,
  props<{ open: boolean; card: string }>()
);

export const getServices = createAction(GET_SERVICES, props<{ data: any }>());
export const getServicesSuccess = createAction(
  GET_SERVICES_SUCCESS,
  props<{ data: any }>()
);
export const getServicesFailure = createAction(
  GET_SERVICES_FAILURE,
  props<{ error: any }>()
);

export const getServiceCategories = createAction(GET_SERVICE_CATEGORIES);
export const getServiceCategoriesSuccess = createAction(
  GET_SERVICE_CATEGORIES_SUCCESS,
  props<{ data: any }>()
);
export const getServiceCategoriesFailure = createAction(
  GET_SERVICE_CATEGORIES_FAILURE,
  props<{ error: any }>()
);

export const getServicesByFormId = createAction(
  GET_SERVICES_BY_FORM_ID,
  props<{ data: any }>()
);
export const getServicesByFormIdSuccess = createAction(
  GET_SERVICES_BY_FORM_ID_SUCCESS,
  props<{ data: any }>()
);
export const getServicesByFormIdFailure = createAction(
  GET_SERVICES_BY_FORM_ID_FAILURE,
  props<{ error: any }>()
);

export const getOrders = createAction(GET_ORDERS, props<{ data: any }>());
export const getOrdersSuccess = createAction(
  GET_ORDERS_SUCCESS,
  props<{ data: any }>()
);
export const getOrdersFailure = createAction(
  GET_ORDERS_FAILURE,
  props<{ error: any }>()
);

export const addPayment = createAction(ADD_PAYMENT, props<{ data: any }>());
export const addPaymentSuccess = createAction(
  ADD_PAYMENT_SUCCESS,
  props<{ data: any }>()
);
export const addPaymentFailure = createAction(
  ADD_PAYMENT_FAILURE,
  props<{ error: any }>()
);

/* REUESTS */
export const setRequestError = createAction(
  SET_REQUEST_ERROR,
  props<{ message: string | null }>()
);
export const setRequestSuccess = createAction(
  SET_REQUEST_SUCCESS,
  props<{ message: string | null }>()
);
/* REQUESTS */
