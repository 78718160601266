import { UserInfoPostProps, UserInfoProps } from './models/userInfo.model';

export interface UserInfoState {
  UserInfoData: UserInfoProps | null;
  errMessage: string | null;
  UserInfoPostData?: {
    data: UserInfoPostProps | null;
    token: string;
  };
}

export const initialState: UserInfoState = {
  UserInfoData: null,
  errMessage: null,
};
