import { Component, Input, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  CityData,
  CountryData,
  MacroregionData,
  RegionData,
} from 'app/store/Shared/model/SharedModel';
import {
  getCitiesByCountry,
  getCitiesByCountrySuccess
} from 'app/store/Shared/shared.actions';
import { UserInfoProps } from 'app/store/UserInfo/models/userInfo.model';
import { AppState } from 'app/store/app.state';
import { BehaviorSubject } from 'rxjs';
import { DropdownType1Eement } from 'shared/ui/dropdown-type-1/circle-img/dropdown-type-1';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-company-jurisdiction',
  templateUrl: './ui/AddCompanyJurisdiction.html',
  styleUrls: ['./ui/AddCompanyJurisdiction.scss'],
})
export class AddCompanyJurisdiction {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private rootFormGroup: FormGroupDirective,
    private translateService: TranslateService
  ) {}
  @Input() formGroupName: string;

  userInfo: UserInfoProps | null;
  jurisdictionForm: FormGroup;
  structureForm: FormGroup;
  countries: CountryData[];
  filteredCountries: CountryData[];
  macroregions: MacroregionData[];

  subs: Subscription[] = [];

  @Input() nextStep: any;
  @Input() prevStep: any;
  @Input() prewiewStep: any;

  dropdown: number | string = '';
  editable: string;
  editable2: boolean = true;

  //continue button
  continueButton: boolean = false;

  ngOnInit() {
    //initialization of root form group
    this.jurisdictionForm = this.rootFormGroup.control.get(
      this.formGroupName
    ) as FormGroup;
    this.structureForm = this.rootFormGroup.control.get(
      'structure'
    ) as FormGroup;

    // this.participantsForm.valueChanges.subscribe((data) => {});

    // let keys = Object.keys(this.structureForm.value);
    // let num = 0;
    // keys.map((key) => {
    //   this.structureForm.value[key] !== '' ? '' : num++;
    // });

    // num > 0 ? (this.editable2 = false) : (this.editable2 = true);


    /*this.store
      .select((state) => state.shared.companyDraftState)
      .subscribe((draft) => {
        this.registrationOptionId = draft?.registrationOptionId
        this.legalFormId = draft?.legalFormId
      });*/

    //setting counties array
    this.subs.push(
      this.store
        .select((state) => state.shared.countries)
        .subscribe((countries) => {
          if (countries) {
            this.countries = countries;
          }
        })
    )

    //filtered countries
    this.subs.push(
      this.store
        .select((state) => state.shared.countriesByMacro)
        .subscribe((countries) => {
          if (countries) {
            this.filteredCountries = countries;
          }
        })
    )

    //editable
    this.subs.push(
      this.store
        .select((state) => state.shared.editableCompanyStep)
        .subscribe((editable) => {
          if (editable) {
            this.editable = editable;
          }
        })
    )

    //setting regions array
    this.subs.push(
      this.store
        .select((state) => state.shared.macroregions)
        .subscribe((macroRegions) => {
          if (macroRegions) {
            this.macroregions = macroRegions;
            if (this.jurisdictionForm.get('region')?.value) {
              this.jurisdictionForm.get('region')!.patchValue(
                this.macroregions.find(e => e.id === this.jurisdictionForm.get('region')!.value)!.id || ''
              )
            }
          }
        })
    )

    console.log(this.jurisdictionForm);
    //setting virtual to undefined when ihaveanaddress switches
    this.jurisdictionForm.valueChanges.subscribe((data) => {
      this.setContinueBtn();
    });

    if (this.macroregions) {
      let macroRegion = this.macroregions.find(mr => mr.id === this.jurisdictionForm.value.region);
      if (macroRegion) {
        this.filteredCountries = this.countries.filter(
          (c) => c.macroRegion == macroRegion!.id
        );
      } else {
        this.filteredCountries = [];
        this.jurisdictionForm.get('country')!.patchValue("");
      }
      if (this.jurisdictionForm.get('country')?.value) {
        this.jurisdictionForm.get('country')!.patchValue(
          this.filteredCountries.find(e => e.id === this.jurisdictionForm.get('country')!.value)!.id || ''
        )
      }
    }

    this.jurisdictionForm.controls.region.valueChanges.subscribe((data) => {
      let macroRegion = this.macroregions.find(mr => mr.id === data);
      if (macroRegion) {
        this.filteredCountries = this.countries.filter(
          (c) => c.macroRegion == macroRegion!.id
        );
      } else {
        this.filteredCountries = [];
        this.jurisdictionForm.get('country')!.patchValue("");
      }
    });

    this.setContinueBtn();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  setCountry = (country: string) => {
    this.jurisdictionForm.setValue({
      ...this.jurisdictionForm.value,
      region: this.jurisdictionForm.value.region,
      country: country,
      country2: country,
    });
  };

  setRegion = (region: string) => {
    if (this.jurisdictionForm.get('region')!.value !== region) {
      this.jurisdictionForm.setValue({
        ...this.jurisdictionForm.value,
        region: region,
        country: null,
      });
    }
  };

  //   setFoundersForm = (index: number, element: string) => {
  //     this.legalFoundersFormArray.controls[index].setValue({
  //       legal: this.legalFoundersFormArray.value[index].legal,
  //       country: this.legalFoundersFormArray.value[index].country,
  //       form: element,
  //       name: this.legalFoundersFormArray.value[index].name,
  //       regNumber: this.legalFoundersFormArray.value[index].regNumber,
  //       nameAndLastName: this.legalFoundersFormArray.value[index].nameAndLastName,
  //       citizenship: this.legalFoundersFormArray.value[index].country,
  //       passport: this.legalFoundersFormArray.value[index].passport,
  //     });
  //   };

  setContinueBtn = () => {
    let fulfilled = false;
    if (
      this.jurisdictionForm.value.region !== '' &&
      this.jurisdictionForm.value.country !== ''
    ) {
      fulfilled = true;
    }
    this.continueButton = fulfilled;
  };
  setDropdown(drop: string): void {
    this.dropdown = drop;
    console.log(drop);
  }

  //button-back
  buttonBack = () => {
    this.prevStep();
  };
  //submits structere and forfards step
  submitStructure = () => {
    this.editable == 'jurisdiction' ? this.prewiewStep() : this.nextStep();
  };

  public getMacroRegionName(id: string) {
    return this.macroregions.find(c => c.id === id)!.title;
  }

  public getCountryName(id: string) {
    return this.filteredCountries.find(c => c.id === id)!.title;
  }
}
