import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, tap } from 'rxjs';
import { AppState } from 'app/store/app.state';
import {
  SuccessRegisterCompany,
  addCompanyDraft,
  addCompanyDraftSuccess,
  getCitiesByCountry,
  getCitiesByCountrySuccess,
  getCitiesData,
  getCitiesDataSuccess,
  getCompanyOptions,
  getCompanyOptionsSuccess,
  getCountriesByMacroregion,
  getCountriesByMacroregionSuccess,
  getCountriesData,
  getCountriesDataSuccess,
  getMacroRegionsData,
  getMacroRegionsDataSuccess,
  getRegionsData,
  getRegionsDataSuccess,
  registerCompany,
  sendConfirmationMailCode,
  sendConfirmationMailCodeSuccess,
  sendVerificationMailCode,
  sendVerificationMailCodeSuccess,
  setCompanyErrorMessage,
  setConfirmMailError,
  setConfirmMailTimerAction,
  setLoadingSpinner,
  setModalWindowConfirmMail,
  setVerificationMailCodeError,
  sendFile,
  sendFileSuccess,
  sendFileFailure,
  setRequestError,
  setRequestSuccess,
  getOrderList,
  getOrderListSuccess,
  getOrderListFailure,
  getOrderById,
  getOrderByIdSuccess,
  getOrderByIdFailure,
  sendCardToken,
  sendCardTokenSuccess,
  sendCardTokenFailure,
  getCompanyById,
  getCompanyByIdSuccess,
  getCompanyByIdFailure,
  createTemplate,
  createTemplateSuccess,
  createTemplateFailure,
  updateTemplate,
  updateTemplateSuccess,
  updateTemplateFailure,
  getPaymentMethods,
  getPaymentMethodsSuccess,
  getPaymentMethodsFailure,
  getServices,
  getServicesSuccess,
  getServicesFailure,
  getServicesByFormId,
  getServicesByFormIdSuccess,
  getServicesByFormIdFailure,
  addPayment,
  addPaymentSuccess,
  addPaymentFailure,
  getOrders,
  getOrdersSuccess,
  getOrdersFailure,
  getServiceCategories,
  getServiceCategoriesSuccess,
  getServiceCategoriesFailure,
  deletePaymentMethod,
  deleteOrder,
  deleteOrderSuccess,
  deleteOrderFailure,
} from 'app/store/Shared/shared.actions';
import { Router } from '@angular/router';

import { setPasswordReset } from 'app/providers/AppRouter/authState/auth.actions';
import { SharedService } from './services/SharedServices';

@Injectable()
export class SharedEffects {
  constructor(
    private actions$: Actions,
    private sharedService: SharedService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  /*LOCATIONS*/
  onGetCounries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCountriesData),
      switchMap((action) => {
        return this.sharedService.getAllCountries().pipe(
          map((data) => {
            const sucess = data.success;
            const errCode = data.code;

            return getCountriesDataSuccess({
              countries: data.data,
            });
          }),
          catchError((errorRes) => {
            return of(setRequestError({ message: errorRes.error.message }));
          })
        );
      })
    );
  });

  onGetRegions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getRegionsData),
      switchMap((action) => {
        return this.sharedService.getAllRegions().pipe(
          map((data) => {
            return getRegionsDataSuccess({
              regions: data.data,
            });
          }),
          catchError((errorRes) => {
            return of(setRequestError({ message: errorRes.error.message }));
          })
        );
      })
    );
  });

  onGetCities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCitiesData),
      switchMap((action) => {
        return this.sharedService.getAllCities().pipe(
          map((data) => {
            return getCitiesDataSuccess({
              cities: data.data,
            });
          }),
          catchError((errorRes) => {
            return of(setRequestError({ message: errorRes.error.message }));
          })
        );
      })
    );
  });

  //cities by country
  onGetCitiesByCountry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCitiesByCountry),
      switchMap((action) => {
        return this.sharedService.getCitiesByCountry(action.countryId).pipe(
          map((data) => {
            return getCitiesByCountrySuccess({
              cities: data.data,
            });
          }),
          catchError((errorRes) => {
            return of(setRequestError({ message: errorRes.error.message }));
          })
        );
      })
    );
  });

  //get countries by macro
  onGetCountriesByMacro$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCountriesByMacroregion),
      switchMap((action) => {
        return this.sharedService
          .getCountriesByMacro(action.macroregionId)
          .pipe(
            map((data) => {
              return getCountriesByMacroregionSuccess({
                countries: data.data,
              });
            }),
            catchError((errorRes) => {
              return of(setRequestError({ message: errorRes.error.message }));
            })
          );
      })
    );
  });
  /*LOCATIONS*/

  onSendEmailCode = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendConfirmationMailCode),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.sharedService.sendMailVereficationCode().pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.store.dispatch(
              setRequestSuccess({ message: 'success sending code' })
            );
            this.store.dispatch(
              setConfirmMailTimerAction({ timeStart: true, time: 30 })
            );
            setConfirmMailError({
              error: '',
            });
            return sendConfirmationMailCodeSuccess();
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.store.dispatch(
              setRequestError({ message: 'error sending code' })
            );
            return of(
              setConfirmMailError({
                error: errorRes.error.message,
              })
            );
          })
        );
      })
    );
  });

  onSendEmailCodeSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(sendConfirmationMailCodeSuccess),
        tap(() => {
          this.store.dispatch(setConfirmMailError({ error: '' }));
          this.sharedService.codeTimer();
          return this.router.navigateByUrl('account/confirm-mail');
        })
      );
    },
    { dispatch: false }
  );

  onConfirmEmailCode = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendVerificationMailCode),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.sharedService.sendMailConfirm(action.code).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return sendVerificationMailCodeSuccess();
          }),
          catchError((err) => {
            setRequestError({ message: 'error confirm code' });
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(
              setConfirmMailError({
                error: err.error.message,
              })
            );
          })
        );
      })
    );
  });

  onConfirmEmailCodeSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(sendVerificationMailCodeSuccess),
        tap(() => {
          this.store.dispatch(setModalWindowConfirmMail({ show: true }));
        })
      );
    },
    { dispatch: false }
  );

  onGetMacroRegions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getMacroRegionsData),
      switchMap((action) => {
        return this.sharedService.getAllMacroRegions().pipe(
          map((data) => {
            return getMacroRegionsDataSuccess({
              macroregions: data.data,
            });
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(setRequestError({ message: errorRes.error.message }));
          })
        );
      })
    );
  });
  onRegCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(registerCompany),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.sharedService.addCompany(action.company).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const success = data.success;
            const errCode = data.code;
            this.store.dispatch(
              setRequestSuccess({ message: 'Company registartion success' })
            );
            if (success) {
              return SuccessRegisterCompany({ company: data.data });
            } else {
              return setCompanyErrorMessage({ message: 'Invalid company' });
            }
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(setRequestError({ message: errorRes.error.message }));
          })
        );
      })
    );
  });
  onRegCompanyDraft$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addCompanyDraft),
      switchMap((action) => {
        return this.sharedService.addCompanyDraft(action.data).pipe(
          map((data) => {
            const success = data.success;
            const errCode = data.code;
            if (success) {
              return addCompanyDraftSuccess({ data: data.data });
            } else {
              return setCompanyErrorMessage({ message: 'Invalid company' });
            }
          }),
          catchError((errorRes) => {
            return of();
          })
        );
      })
    );
  });

  onGetCompanyOptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCompanyOptions),
      switchMap((action) => {
        return this.sharedService.getCompanyOptions().pipe(
          map((data) => {
            const success = data.success;
            const errCode = data.code;
            if (success) {
              return getCompanyOptionsSuccess({ options: data.data });
            } else {
              return setCompanyErrorMessage({ message: 'Invalid company' });
            }
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(setRequestError({ message: errorRes.error.message }));
          })
        );
      })
    );
  });

  onSendFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendFile),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.sharedService.sendFile(action).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return sendFileSuccess({ data });
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(sendFileFailure(errorRes));
          })
        );
      })
    );
  });

  onGetCompanyById = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCompanyById),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.sharedService.getCompanyById(action.id).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            if (data.code == 200) {
              return getCompanyByIdSuccess({ data: data.data });
            }
            return getCompanyByIdFailure({
              error: 'something went wrong, try again later',
            });
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(getCompanyByIdFailure(errorRes));
          })
        );
      })
    );
  });

  onCreateTemplate = createEffect(() => {
    return this.actions$.pipe(
      ofType(createTemplate),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.sharedService.createTemplate(action.data).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            if (data.code == 200) {
              return createTemplateSuccess({ data: data.data.id });
            }
            return createTemplateFailure({
              error: 'something went wrong, try again later',
            });
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(createTemplateFailure(errorRes));
          })
        );
      })
    );
  });

  onUpdateTemplate = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateTemplate),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.sharedService.updateTemplate(action.data, action.id).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            if (data.code == 200) {
              return updateTemplateSuccess();
            }
            return updateTemplateFailure({
              error: 'something went wrong, try again later',
            });
          }),
          catchError((errorRes) => {
            this.store.dispatch(
              setRequestError({ message: errorRes.error.message || errorRes.message })
            )
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(updateTemplateFailure(errorRes));
          })
        );
      })
    );
  });

  onGetMyOrdersList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getOrderList),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.sharedService.getOrderList().pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return getOrderListSuccess({ data: data.data });
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(getOrderListFailure(errorRes));
          })
        );
      })
    );
  });

  onGetOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getOrders),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.sharedService.getOrders(action).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return getOrdersSuccess({ data: data.data });
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(getOrdersFailure(errorRes));
          })
        );
      })
    );
  });

  getMyOrderById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getOrderById),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.sharedService.getOrderById(action.id).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return getOrderByIdSuccess({ data: data.data });
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(getOrderByIdFailure(errorRes));
          })
        );
      })
    );
  });

  onSendCardToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendCardToken),
      switchMap((action) => {
        return this.sharedService.sendCardToken(action).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return sendCardTokenSuccess({ data: data.data });
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(sendCardTokenFailure(errorRes));
          })
        );
      })
    );
  });

  onGetPaymentMethods$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPaymentMethods),
      switchMap((action) => {
        return this.sharedService.getPaymentMethods().pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return getPaymentMethodsSuccess({ data: data.data });
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(getPaymentMethodsFailure(errorRes));
          })
        );
      })
    );
  });

  onDeletePaymentMethod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deletePaymentMethod),
      switchMap((action) => {
        return this.sharedService.deletePaymentMethod(action.id).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            window.location.reload();
            return setRequestSuccess({
              message: 'Successfully deleted payment method!',
            });
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(
              setRequestError({ message: 'Error deleting payment method!' })
            );
          })
        );
      })
    );
  });
  onDeleteOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteOrder),
      switchMap((action) => {
        return this.sharedService.delteOrderById(action.id).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            // window.location.reload();
            setRequestSuccess({
              message: 'Successfully deleted payment method!',
            });
            return deleteOrderSuccess({ data });
          }),
          catchError((error) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            setRequestError({ message: 'Error deleting payment method!' });
            return of(deleteOrderFailure(error));
          })
        );
      })
    );
  });

  onGetServiceCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getServiceCategories),
      switchMap((action) => {
        return this.sharedService.getServiceCategories().pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return getServiceCategoriesSuccess({ data: data.data });
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(getServiceCategoriesFailure(errorRes));
          })
        );
      })
    );
  });

  onGetServices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getServices),
      switchMap((action) => {
        return this.sharedService.getServices(action).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return getServicesSuccess({ data: data.data });
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(getServicesFailure(errorRes));
          })
        );
      })
    );
  });

  onGetServicesByFormId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getServicesByFormId),
      switchMap((action) => {
        return this.sharedService.getServicesByFormId(action).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return getServicesByFormIdSuccess({ data: data.data });
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(getServicesByFormIdFailure(errorRes));
          })
        );
      })
    );
  });

  onAddPayment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addPayment),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.sharedService.addPayment(action).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return addPaymentSuccess({ data: data.data });
          }),
          catchError((errorRes) => {
            this.store.dispatch(
              setRequestError({ message: errorRes.error.message })
            );
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(addPaymentFailure(errorRes));
          })
        );
      })
    );
  });
}
