<div
  class="circle-img"
  [ngClass]="upload ? 'circle-img-btn' : ''"
  [style]="{ width: size, height: size }"
>
  <img
    [src]="pic"
    alt=""
    [style]="{ height: sizeImg, width: sizeImg, 'object-fit': 'cover' }"
    [id]="imgId"
  />
  <input
    type="file"
    accept="image/png, image/jpeg"
    name="image-to-upload"
    *ngIf="upload"
    (change)="selectImage($event)"
  />
  <div *ngIf="upload">
    <img [src]="'assets/icons/camera.svg'" alt="" />
  </div>
</div>
