import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'app/services/translate-config.service';
import { registrationData } from 'app/store/Registration/models/registrationData.model';
import { getRegistrationData } from 'app/store/Registration/registration.seleсtor';
import { AppState } from 'app/store/app.state';
import { map } from 'rxjs';

@Component({
  selector: 'app-form-user-code',
  templateUrl: './ui/form-user-code.component.html',
  styleUrls: ['./ui/form-user-code.component.scss'],
})
export class FormUserCodeComponent {
  Object: Object;

  phoneNumber: number | string;
  regData: registrationData | null;
  @Input() formGroupName: string;
  @Input() codeError: string | null;
  @Input() restorePwd: boolean;
  @Input() errMessage: string | null;

  form: FormGroup;
  inputs: string[];
  constructor(
    private rootFormGroup: FormGroupDirective,
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.inputs = Object.keys(this.form.controls);
    this.store.select(getRegistrationData).subscribe((reg) => {
      this.regData = reg;
    });

    if (this.restorePwd) {
      this.store
        .select((state) => state.resetPwd)
        .subscribe((state) => {
          if (state.login) {
            this.phoneNumber = state.login;
          }
        });
    }
    console.log(this.regData);
    //this.phoneNumber = regData
  }
}
