import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import {
  setLoadingSpinner,
  sendCardToken,
  sendCardTokenSuccess,
  sendCardTokenFailure
} from 'app/store/Shared/shared.actions';

import { 
  StripeService,
  StripeCardNumberComponent
} from "ngx-stripe";
import {
  StripeCardElementOptions,
  StripeElementsOptions,
  StripeCardCvcElementOptions,
  StripeCardNumberElementOptions,
} from '@stripe/stripe-js';
import { Subscription } from 'rxjs';

@Component({
    selector: 'AddCardPage',
    templateUrl: './ui/AddCardPage.component.html',
    styleUrls: ['./ui/AddCardPage.component.scss'],
})
export class AddCardPage implements OnInit {
  subs: Subscription[] = [];
  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;

  baseStyles: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#FFF',
        color: '#FFF',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  cardOptions: StripeCardNumberElementOptions = {
    ...this.baseStyles,
    showIcon: true,
  };

  cvvOptions: StripeCardCvcElementOptions = {
    ...this.baseStyles,
    placeholder: 'CVV',
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'auto',
  };

  constructor(
    private stripeService: StripeService, 
    private router: Router,
    private route: ActivatedRoute,
    private actions: Actions,
    private store: Store<any>,
  ) {}

  ngOnInit() {
    this._responseListener();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _responseListener() {
    this.subs.push(
      this.actions
        .pipe(ofType(sendCardTokenSuccess))
        .subscribe(({ data }) => {
          this.route.queryParams.subscribe(params => {
            let id = params['draft_id'];
            if (id) {
              this.router.navigate(['/account/add-company'], {
                queryParams: { id: id }
              });
            } else {
              this.router.navigate(['/account/add-payment-method']);
            }
          });
        })
    );

    this.subs.push(
      this.actions
        .pipe(ofType(sendCardTokenFailure))
        .subscribe(({ error }) => {
          const errorElement = document.getElementById('card-errors')!;
          errorElement.textContent = error.message ?? null;
        })
    )
  }

  public createToken() {
    this.store.dispatch(setLoadingSpinner({ status: true }));
    this.stripeService.createToken(this.card.element)
      .subscribe((result) => {
        if (result.error) {
          const errorElement = document.getElementById('card-errors')!;
          errorElement.textContent = result.error.message ?? null;
          this.store.dispatch(setLoadingSpinner({ status: false }));
        } else {
          this.store.dispatch(sendCardToken({ data:{
            "paymentType": 1,
            "name": "test card",
            "stripeToken": result.token.id,
            "isDefault": false
           }}))
        }
      }
    );
  }
}


