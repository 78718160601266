import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { TranslateConfigService } from 'app/services/translate-config.service';

@Component({
  selector: 'MainPage',
  templateUrl: './ui/MainPage.component.html',
  styleUrls: ['./ui/MainPage.component.scss'],
})
export class MainPageComponent {
  constructor(
    private router: Router,
    private translateConfigService: TranslateConfigService
  ) {
    this.getScreenSize();
  }
  loadingScr: boolean = true;
  screenHeight: number;
  screenWidth: number;
  loginButtonDisabled = false;

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    console.log(this.screenHeight, this.screenWidth);
  }

  ngOnInit() {
    let userInfo = localStorage.getItem('firmderWebUser');
    this._getCookies();
    setTimeout(() => {
      this.loadingScr = false;
      if (userInfo) {
        this.router.navigate(['/account/services-catalog']);
      } else {
        this.Repeat();
      }
    }, 2000);
  }

  NavSignUp = (): void => {
    this.router.navigate(['/registration']);
  };

  NavLogin = (): void => {
    this.router.navigate(['/login']);
  };

  Repeat() {
    this.showSlides();
  }

  slideIndex = 0;
  showSlides(): void {
    let i;
    let slides = document.getElementsByClassName(
      'MainPage__slider__slide'
    ) as HTMLCollectionOf<HTMLElement>;
    let pics = document.getElementsByClassName(
      'MainPage__images__pic'
    ) as HTMLCollectionOf<HTMLElement>;

    if (!slides) {
      this.Repeat();
    }

    const showSlidesTimout = setTimeout(() => this.showSlides(), 3000); // Change image every 2 seconds
    if (this.router.url !== '/') {
      return clearTimeout(showSlidesTimout);
    }

    let dots = document.getElementsByClassName('dot');
    for (i = 0; i < slides.length; i++) {
      if (slides[i].style) {
        slides[i].style.display = 'none';
      }
      if (pics[i].style) {
        pics[i].style.display = 'none';
      }
    }
    this.slideIndex++;
    if (this.slideIndex > slides.length) {
      this.slideIndex = 1;
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(' active', '');
    }

    if (slides[this.slideIndex - 1]) {
      slides[this.slideIndex - 1].style.display = 'block';
    }
    if (pics[this.slideIndex - 1]) {
      pics[this.slideIndex - 1].style.display = 'flex';
    }
    dots[this.slideIndex - 1].className += ' active';
  }

  private _getCookies() {
    let dc = document.cookie;
    let prefix = 'firmderLoginBan=';
    this.loginButtonDisabled = dc.indexOf("; " + prefix) >= 0;
  }
}
