<div class="dialog-screen" [ngClass]="open ? 'dialog-screen-open' : ''">
  <div *ngIf="isMessage" #myDialog class="dialog-box">
    <p class="dialog-box__title">{{ title }}</p>
    <p class="dialog-box__text">{{ text }}</p>
    <div
      class="dialog-box__buttons"
      [ngClass]="!cancelText ? 'dialog-box__button' : ''"
    >
      <button *ngIf="cancelText" (click)="cancelFunction(open)">
        {{ cancelText }}
      </button>

      <button type="button" (click)="okFunction()">{{ okText }}</button>
    </div>
  </div>

  <div *ngIf="!isMessage && convertedHex" class="dialog-box html-box">
    <div #formDiv class="is-full-page"></div>
  </div>
</div>
