import { Component, Input, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import {
  CityData,
  CompanyData,
  CountryData,
  MacroregionData,
  companyOption,
} from 'app/store/Shared/model/SharedModel';
import {
  addCompanyDraftDataState,
  getCitiesByCountry,
  getCitiesByCountrySuccess,
  registerCompany,
  setAddCompanyStep,
  setEditableCompanyStep,
  addPayment,
  addPaymentSuccess
} from 'app/store/Shared/shared.actions';
import { UserInfoProps } from 'app/store/UserInfo/models/userInfo.model';
import { AppState } from 'app/store/app.state';
import { BehaviorSubject } from 'rxjs';
import { DropdownType1Eement } from 'shared/ui/dropdown-type-1/circle-img/dropdown-type-1';
import { SharedService } from 'app/store/Shared/services/SharedServices';


@Component({
  selector: 'app-add-company-prewiew',
  templateUrl: './ui/AddCompanyPrewiew.html',
  styleUrls: ['./ui/AddCompanyPrewiew.scss'],
})
export class AddCompanyPrewiew {
  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private router: Router,
    private rootFormGroup: FormGroupDirective,
    private translateService: TranslateService,
    private sharedService: SharedService
  ) {}
  
  @Input() formGroupaName: string;
  userInfo: UserInfoProps | null;
  mainForm: FormGroup;
  //address form
  addressForm: FormGroup;
  @Input() addressFormName: string;
  //address form
  //structure form
  structureForm: FormGroup;
  @Input() structureFormName: string;
  //structure form
  //directors form
  directorsForm: FormGroup;
  directorsFormArray: FormArray;
  @Input() directorsFormName: string;
  //founders form
  foundersForm: FormGroup;
  foundersFormArray: FormArray;
  @Input() foundersFormName: string;
  //founders form
  //title vars form
  varinatsOfTitle: FormGroup;
  @Input() varinatsOfTitleFormName: string;
  //title vars form
  //jursdiction form
  jurisdictionForm: FormGroup;
  //jurisdiction form

  //edit
  editStructure: boolean = false;
  editAddress: boolean = false;
  editCountry: boolean = false;
  variantsTitleEditable: boolean = false;
  //edit

  //submit btn
  submitBtn: boolean = false;
  countries: CountryData[];
  macroregions: MacroregionData[];
  cities: CityData[];

  @Input() nextStep: any;
  @Input() prevStep: any;

  dropdown: number | string = '';

  //continue button
  continueButton: boolean = false;
  showPaymentCardsModal: boolean = false;

  companyOptions: companyOption[];
  public companyOption = new BehaviorSubject<companyOption | undefined>(
    undefined
  );

  @Input() price: number;
  @Input() isRenew: number;
  @Input() regPrice: number;
  @Input() currency: string | undefined;

  subs: Subscription[] = [];

  //payment
  paymentMethodId: string;
  serviceId: string;
  companyId: string | undefined;
  showModal: boolean = false;

  is3dsPayment: boolean = false;
  formHexCode: any;
  _3dsPaymentInterval: any = null;
  _3dsOrderId: string;
  _3dsPaymentSuccessful: boolean = false;
  _3dsPaymentFailure: boolean = false;

  ngOnInit() {
    //initialization of root form group
    this.mainForm = this.rootFormGroup.control.get(
      this.formGroupaName
    ) as FormGroup;
    //initialization inside groups
    this.addressForm = this.rootFormGroup.control.get(
      this.addressFormName
    ) as FormGroup;
    this.structureForm = this.rootFormGroup.control.get(
      this.structureFormName
    ) as FormGroup;
    this.directorsForm = this.rootFormGroup.control.get(
      this.directorsFormName
    ) as FormGroup;
    this.directorsFormArray = this.directorsForm.get('directors') as FormArray;
    this.foundersForm = this.rootFormGroup.control.get(
      this.foundersFormName
    ) as FormGroup;
    this.foundersFormArray = this.foundersForm.get('legal') as FormArray;
    this.varinatsOfTitle = this.rootFormGroup.control.get(
      this.varinatsOfTitleFormName
    ) as FormGroup;
    this.jurisdictionForm = this.rootFormGroup.control.get(
      'jurisdiction'
    ) as FormGroup;

    this.addressForm.valueChanges.subscribe((data) => {});
    this.structureForm.valueChanges.subscribe((data) => {
      console.log('structure', data);
    });
    // this.setContinueBtn(this.rootFormGroup.value);

    this.rootFormGroup.valueChanges?.subscribe((data) => {
      // this.setContinueBtn(data);
    });

    //setting counties array
    this.subs.push(
      this.store
        .select((state) => state.shared.countries)
        .subscribe((countries) => {
          if (countries) {
            this.countries = countries;
          }
        })
    );

    this.subs.push(
      this.store
        .select((state) => state.shared.macroregions)
        .subscribe((macroRegions) => {
          if (macroRegions) {
            this.macroregions = macroRegions;
          }
        })
    )

    this.subs.push(
      this.store
        .select((state) => state.shared.companyOptions)
        .subscribe((options) => {
          if (options) {
            this.companyOptions = options;
          }
        })
    )

    this.subs.push(
      this.store
        .select((state) => state.shared.citiesByCoutry)
        .subscribe((cities) => {
          if (cities) {
            this.cities = cities;
          }
        })
    )

    //setting virtual to undefined when ihaveanaddress switches
    this.addressForm.valueChanges.subscribe((data) => {
      console.log(data);
    });

    this._getPaymentMethods();
    this._getServices();
    this._companyIdListener();
    this._listenForSubmit();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  // setContinueBtn = (data: any) => {
  //   let empty = 0;
  //   Object.keys(data).forEach((key) => {
  //     Object.keys(data[key]).forEach((value) => {
  //       if (key == 'address') {
  //         if (this.addressForm.value.haveAnAdress == false) {
  //           if (
  //             value !== 'country' &&
  //             value !== 'city' &&
  //             value !== 'address1' &&
  //             value !== 'address2' &&
  //             value !== 'postalCode' &&
  //             value !== 'haveAnAdress' &&
  //             value !== 'postalCode'
  //           ) {
  //             data[key][value]
  //               ? ''
  //               : (empty++, console.log(data[key][value], value));
  //           }
  //         } else if (this.addressForm.value.haveAnAdress == true) {
  //           if (value !== 'city2' && value !== 'country2' && value !== 'real') {
  //             data[key][value]
  //               ? ''
  //               : (empty++, console.log(data[key][value]), value);
  //           }
  //         }
  //       }
  //       if (key == 'titleVariants') {
  //         data[key][value]
  //           ? ''
  //           : (empty++, console.log(data[key][value]), value);
  //       }
  //       if (key == 'structure') {
  //         data[key][value]
  //           ? ''
  //           : (empty++, console.log(data[key][value]), value);
  //       }
  //     });
  //   });
  //   empty == 0 ? (this.submitBtn = true) : '';
  //   console.log('SUBMIT', empty, this.submitBtn);
  // };

  setContinueBtn() {
    return this.serviceId && this.companyId;
  }

  setDropdown(drop: string): void {
    this.dropdown = drop;
    console.log(drop);
    //getting options state
    this.store
      .select((state) => state.shared.companyOptions)
      .subscribe((options) => {
        if (options) {
          this.companyOptions = options;
        }
      });
  }

  //setting edits
  editInfo = () => {
    this.store.dispatch(setAddCompanyStep({ step: 1 }));
    this.store.dispatch(setEditableCompanyStep({ value: 'info' }));
  };
  editOffice = () => {
    this.store.dispatch(setAddCompanyStep({ step: 2 }));
    this.store.dispatch(setEditableCompanyStep({ value: 'address' }));
  };
  editTitles = () => {
    this.store.dispatch(setAddCompanyStep({ step: 3 }));
    this.store.dispatch(setEditableCompanyStep({ value: 'titleVariants' }));
  };
  editDirectors = () => {
    this.store.dispatch(setAddCompanyStep({ step: 4 }));
    this.store.dispatch(setEditableCompanyStep({ value: 'directors' }));
  };
  editFounders = () => {
    this.store.dispatch(setAddCompanyStep({ step: 5 }));
    this.store.dispatch(setEditableCompanyStep({ value: 'founders' }));
  };
  editTitleVariants = (val: boolean) => {
    this.variantsTitleEditable = val;
  };
  editJurisdiction = () => {
    this.store.dispatch(setAddCompanyStep({ step: 0 }));
    this.store.dispatch(setEditableCompanyStep({ value: 'jurisdiction' }));
  };
  //setting edits
  //button-back
  buttonBack = () => {};

  // вопросы - registrationOptionId, акции, макрорегион,айди формы, префикс названия компании, просто про названия кампании,по директору - владелец значит учредитель?, количество директоров/увредителей, физические уредители кол-во не должно быть пустым, про минимальный капитал

  //submits structere and forwards step
  // submitStructure = () => {
  //   let countryId = '';
  //   let macroId = '';
  //   if (this.addressForm.value.haveAnAdress) {
  //     let country = this.countries.find(
  //       (country) => country.title === this.addressForm.value.country
  //     );
  //     let macro = this.macroregions.find(
  //       (macro) => macro.title === this.addressForm.value.region
  //     );
  //     if (country?.id) {
  //       countryId = country.id;
  //     }
  //     if (macro?.id) {
  //       macroId = macro.id;
  //     }
  //   }
  //   if (!this.addressForm.value.haveAnAdress) {
  //     let country = this.countries.find(
  //       (country) => country.title === this.addressForm.value.country2
  //     );
  //     let macro = this.macroregions.find(
  //       (macro) => macro.title === this.addressForm.value.region
  //     );
  //     if (country?.id) {
  //       countryId = country.id;
  //     }
  //     if (macro?.id) {
  //       macroId = macro.id;
  //     }
  //   }

  //   let companyOption = this.companyOptions?.find(
  //     (option) => option.countryId == countryId
  //   );
  //   console.log;
  //   let form = companyOption?.legalForms?.find(
  //     (form) =>
  //       (this.rootFormGroup.value.structure.jurisdictionalForm = form.title)
  //   );

  //   console.log(
  //     'AAAA',
  //     companyOption,
  //     countryId,
  //     this.companyOptions,
  //     form,
  //     this.rootFormGroup.value.structure.jurisdictionalForm
  //   );
  //   //потом национальность надо сдлеать по стране к аббревиатуре макрорегиона
  //   let directors: {
  //     nationality: string;
  //     passport: string;
  //     isOwner: boolean;
  //     fullname: string;
  //   }[] = [];
  //   let shareholdersPhysical: {
  //     nationality: string;
  //     passport: string;
  //     share: number;
  //     fullname: string;
  //   }[] = [];
  //   let shareholdersCompany: {
  //     country: string;
  //     number: string;
  //     share: number;
  //     form: string;
  //     title: string;
  //   }[] = [];

  //   console.log(
  //     this.directorsFormArray.value.map((directors: any) => directors),
  //     this.foundersFormArray
  //   );
  //   this.directorsFormArray.value.map((director: any) => {
  //     directors.push({
  //       nationality: director.citizenship,
  //       passport: director.passport,
  //       isOwner: director.founder,
  //       fullname: director.nameAndLastName,
  //     });
  //   });
  //   this.foundersFormArray.value.map((founder: any) => {
  //     if (!founder.legal) {
  //       shareholdersPhysical.push({
  //         nationality: founder.citizenship,
  //         passport: founder.passport,
  //         share: founder.share, // потом надо изменить
  //         fullname: founder.nameAndLastName,
  //       });
  //     }
  //     if (founder.legal) {
  //       shareholdersCompany.push({
  //         country: founder.country,
  //         number: founder.regNumber,
  //         share: founder.share, // потом надо изменить
  //         form: founder.form,
  //         title: founder.name,
  //       });
  //     }
  //   });
  //   let company: CompanyData = {
  //     registrationOptionId: 'OPT-001',
  //     // macroRegionId: macroId,
  //     // countryId: countryId,
  //     legalFormId: form?.legalFormId || '', //jurisdictional form id
  //     directorsCount: this.structureForm.value.numberOfDirectors,
  //     shareholdersCount: this.structureForm.value.numberOfFounders,
  //     authorizedCapital: this.structureForm.value.capital,
  //     titleVariants: [
  //       {
  //         prefix: this.varinatsOfTitle.value.suffix1,
  //         title: this.varinatsOfTitle.value.variant1,
  //       },
  //       {
  //         prefix: this.varinatsOfTitle.value.suffix2,
  //         title: this.varinatsOfTitle.value.variant2,
  //       },
  //       {
  //         prefix: this.varinatsOfTitle.value.suffix3,
  //         title: this.varinatsOfTitle.value.variant3,
  //       },
  //     ],
  //     director: [...directors],

  //     shareholderPhysical: [...shareholdersPhysical],

  //     shareholderCompany: [...shareholdersCompany],
  //   };
  //   if (this.submitBtn) {
  //     this.store.dispatch(registerCompany({ company: company }));
  //   }
  //   this.store.dispatch(registerCompany({ company: company }));
  // };

  public submitStructure() {
    if (!this.paymentMethodId || this.paymentMethodId === 'invoice') {
      this.showPaymentCardsModal = true;
    } else {
      let data = {
        companyId: this.companyId, 
        paymentMethodId: this.paymentMethodId, 
        serviceId: this.serviceId
      };

      this.store.dispatch(addPayment({data}));
    }
  }

  private _listenForSubmit() {
    this.subs.push(
      this.actions
      .pipe(ofType(addPaymentSuccess))
      .subscribe(({data}) => {
        if (data.actions && data.actions.base64FormData.length) {
          this.formHexCode = data.actions.base64FormData;
          this.is3dsPayment = true;
          this._3dsOrderId = data.order.id;
          this._3dsPaymentInterval = setInterval(() => {
            this._getOrderState();
          }, 2000);
        } else {
          this.showModal = true;
        }
      })
    );
  }

  closeModal = () => {
    this.showModal = false;
    this._3dsPaymentSuccessful = false;
    this.router.navigate(['/account/my-orders']);
  }

  closeModalWithoutRedirect = () => {
    this._3dsPaymentFailure = false;
  }

  navigateToCards = () => {
    this.showPaymentCardsModal = false;
    // this.router.navigate([`/account/add-payment-method`], {
    this.router.navigate([`/account/add-card`], {
      queryParams: { draft_id: this.companyId },
    });
  }

  public getCountryName(id: string) {
    return this.countries.find(c => c.id === id)!.title;
  }

  public getCityName(id: string) {
    if (this.cities) {
      return this.cities.find(c => c.id === id)?.title;
    } else {
      return "Unknown";
    }
  }

  private _getPaymentMethods() {
    this.sharedService.getPaymentMethods()
      .subscribe(({data}) => {
        this.paymentMethodId = data.find((e: any) => e.isDefault)!.paymentMethodId;
      });
  }

  private _getServices() {
    this.sharedService.getServices({})
      .subscribe(({data}) => {
        this.serviceId = data.find((e: any) => e.code === 'company_registration')!.id;
        this.setContinueBtn();
      });
  }

  private _companyIdListener() {
    this.subs.push(
      this.store
        .select((state) => state.shared.currentTemplateId)
        .subscribe((id) => {
          this.companyId = id || undefined;
          this.setContinueBtn();
        })
    )
  }

  public formIdToName(id: string) {
    if (this.companyOptions) {
      let lf = this.companyOptions[0].legalForms.find((e: any) => e.legalFormId === id);
      if (lf) {
        return lf.title;
      } else {
        return id;
      }
    } else {
      return id;
    }
    console.log(id);
  }

  private _getOrderState() {
    this.sharedService.getOrdersById(this._3dsOrderId)
      .subscribe(({data}) => {
        if (data.state === 4) {
          clearInterval(this._3dsPaymentInterval);
          this.is3dsPayment = false;
          this._3dsPaymentSuccessful = true;
        } else if (data.state === 5) {
          clearInterval(this._3dsPaymentInterval);
          this.is3dsPayment = false;
          this._3dsPaymentFailure = true;
        }
      })
  }
}
