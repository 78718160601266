import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { RouteConfig } from 'shared/config/routeConfig/routeConfig';
import { AUTH_STATE_NAME } from './authState/auth.selector';
import { AuthReducer } from './authState/auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './authState/auth.effects';
import { AuthGuard } from 'app/services/auth.guard';
import { RestorePwdGuard } from 'app/services/restorePwd.guard';
import { MatSnackBar } from '@angular/material/snack-bar';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../../ui/SIgnOut/SignOut.module').then((m) => m.SignOutModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('../../ui/SignIn/SignIn.module').then((m) => m.SignInModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'account',
  },
  {
    path: RouteConfig.not_found.path,
    pathMatch: 'full',
    component: RouteConfig.not_found.component,
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    StoreModule.forFeature(AUTH_STATE_NAME, AuthReducer),
    EffectsModule.forFeature([]),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
