import { Component, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  CityData,
  CountryData,
  MacroregionData,
} from 'app/store/Shared/model/SharedModel';
import {
  getCitiesByCountry,
  getCitiesByCountrySuccess,
  getCountriesByMacroregion,
  updateTemplate,
  updateTemplateSuccess,
} from 'app/store/Shared/shared.actions';
import { UserInfoProps } from 'app/store/UserInfo/models/userInfo.model';
import { AppState } from 'app/store/app.state';
import { BehaviorSubject } from 'rxjs';
import { DropdownType1Eement } from 'shared/ui/dropdown-type-1/circle-img/dropdown-type-1';
import { Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-add-company-address',
  templateUrl: './ui/Address.html',
  styleUrls: ['./ui/Address.scss'],
})
export class AddCompanyAddress {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private rootFormGroup: FormGroupDirective,
    private translateService: TranslateService,
    private actions: Actions,
  ) {}
  @Input() formGroupName: string;
  userInfo: UserInfoProps | null;
  haveAdress: boolean = false;
  addressForm: FormGroup;
  structureForm: FormGroup;
  countries: CountryData[];
  filteredCountries: CountryData[];
  cities: CityData[];
  editable: string;
  macroregions: MacroregionData[];

  subs: Subscription[] = [];

  @Input() nextStep: any;
  @Input() prevStep: any;
  @Input() prewiewStep: any;

  dropdown: string = '0';

  //cities and countries
  country = new BehaviorSubject<any>('');
  country2 = new BehaviorSubject<any>('');
  city = new BehaviorSubject<any>('');
  city2 = new BehaviorSubject<any>('');
  virtual = new BehaviorSubject<any>({
    title: '',
    option: '',
  });

  //continue button
  continueButton: boolean = false;

  saveMode: boolean | undefined = false;

  setCountry(elem: any) {
    this.country.next(elem);
    this.addressForm.controls.country.setValue(this.country.value);
    let countryFound = this.countries.find((c) => elem == c.id);
    const countryId = countryFound?.id;
    if (countryId) {
      this.store.dispatch(getCitiesByCountry({ countryId: countryId }));
    }
  }

  setCountry2(elem: any) {
    this.country2.next(elem);
    this.addressForm.controls.country2.setValue(this.country2.value);
    let countryFound = this.countries.find((c) => elem == c.id);
    const countryId = countryFound?.id;
    if (countryId) {
      this.store.dispatch(getCitiesByCountry({ countryId: countryId }));
    }
  }
  setCity(elem: any) {
    this.city.next(elem);
    this.addressForm.controls.city.setValue(this.city.value);
  }
  setCity2(elem: any) {
    this.city2.next(elem);
    this.addressForm.controls.city2.setValue(this.city2.value);
  }
  setVirtualAddress(elem: any) {
    this.virtual.next(elem);
    if (this.virtual.value == '1') {
      this.addressForm.controls.real.setValue('1');
    } else {
      this.addressForm.controls.real.setValue('2');
    }
  }

  ngOnInit() {
    //initialization of root form group
    this.addressForm = this.rootFormGroup.control.get(
      this.formGroupName
    ) as FormGroup;

    this.subs.push(
      this.addressForm.valueChanges.subscribe((data) => {
        this.haveAdress = data.haveAnAdress;
        //clears cities on change have address
      })
    );

    this.country.next(this.addressForm.value.country);
    this.country2.next(this.addressForm.value.country2);
    this.city.next(this.addressForm.value.city);
    this.city2.next(this.addressForm.value.city2);
    this.virtual.next(this.addressForm.value.real);

    //setting regions array
    this.subs.push(
      this.store
        .select((state) => state.shared.macroregions)
        .subscribe((macroRegions) => {
          if (macroRegions) {
            this.macroregions = macroRegions;
            let macroFound = macroRegions.find(
              (c) => this.addressForm.value.region == c.id
            );
            if (macroFound) {
              const macroId = macroFound?.id;
              this.store.dispatch(
                getCountriesByMacroregion({ macroregionId: macroId })
              );
            }
          }
        })
    )

    //countries
    this.subs.push(
      this.store
        .select((state) => state.shared.countries)
        .subscribe((countries) => {
          if (countries) {
            this.countries = countries;
          }
        })
    )

    //filtered countries
    this.subs.push(
      this.store
        .select((state) => state.shared.countriesByMacro)
        .subscribe((countries) => {
          if (countries) {
            this.filteredCountries = countries;
            this.setCountry(this.addressForm.value.country);
            this.setCountry2(this.addressForm.value.country2);
          }
        })
    );

    //cities array
    this.subs.push(
      this.store
        .select((state) => state.shared.citiesByCoutry)
        .subscribe((cities) => {
          if (cities) {
            this.cities = cities;
          }
        })
    )

    //editable country
    this.subs.push(
      this.store
        .select((state) => state.shared.editableCompanyStep)
        .subscribe((editable) => {
          if (editable) {
            this.editable = editable;
          }
        })
    )

    //setting virtual to undefined when ihaveanaddress switches
    this.subs.push(
      this.addressForm.valueChanges.subscribe((data) => {
        if (
          this.addressForm.value.haveAnAdress == true &&
          this.addressForm.value.real !== ''
        ) {
          this.addressForm.controls.real.setValue('');
          this.virtual.next('');
          this.addressForm.controls.city2.setValue('');
          this.city2.next('');
        } else if (
          this.addressForm.value.haveAnAdress === false &&
          this.addressForm.value.country !== '' &&
          this.addressForm.value.address1 !== '' &&
          // this.addressForm.value.address2 !== '' &&
          this.addressForm.value.city !== '' &&
          this.addressForm.value.postalCode !== ''
        ) {
          this.addressForm.controls.address1.setValue('');
          // this.addressForm.controls.address2.setValue('');
          this.addressForm.controls.city.setValue('');
          this.addressForm.controls.postalCode.setValue('');
        }
        this.setContinueBtn();
      })
    );
    this.setContinueBtn();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  //sets have an adress form value
  setHaveAnAdress = () => {
    this.addressForm.controls.haveAnAdress.setValue(true);
    console.log(this.addressForm.controls.haveAnAdress.value);
  };

  setContinueBtn = () => {
    console.log('addressForm:', this.addressForm.value);
    if (
      this.addressForm.value.country !== '' &&
      this.addressForm.value.address1 !== '' &&
      // this.addressForm.value.address2 !== '' &&
      this.addressForm.value.city !== '' &&
      this.addressForm.value.postalCode !== ''
    ) {
      this.continueButton = true;
    } else if (
      this.addressForm.value.real !== '' &&
      this.addressForm.value.country2 !== '' &&
      this.addressForm.value.city2 !== ''
    ) {
      this.continueButton = true;
    } else {
      this.continueButton = false;
    }
  };
  setDropdown(drop: string): void {
    this.dropdown = drop;
    console.log(drop);
  }

  //button-back
  buttonBack = () => {};

  //submits structere and forfards step
  submitStructure = (saveMode?: boolean) => {
    this.saveMode = saveMode;
    let data = this.prepairValues();
    this.subs.push(
      this.store
        .select((state) => state.shared.currentTemplateId)
        .subscribe((id) => {
          if (id) {
            this.store.dispatch(updateTemplate({
              data: {
                companyAddress: {
                  ...data,
                },
                processStep: 3
              },
              id: id
            }));
          }
        })
    )

    this._listenForUpdateSuccess();
    
    // if (!saveMode) {
    //   this.editable == 'address' ? this.prewiewStep() : this.nextStep();
    // }
  };

  private _listenForUpdateSuccess() {
    this.subs.push(
      this.actions
        .pipe(ofType(updateTemplateSuccess))
        .subscribe(() => {
          if (!this.saveMode) {
            this.editable == 'address' ? this.prewiewStep() : this.nextStep();
          }
        })
    );  
  }

  private prepairValues() {
    console.log(this.addressForm.value);
    return {
      zip: this.addressForm.get('postalCode')!.value,
      city: {
        cityId: 
        this.addressForm.get('haveAnAdress')!.value
          /*? this.cities.find(e => e.title === this.addressForm.get('city')!.value)!.id
          : this.cities.find(e => e.title === this.addressForm.get('city2')!.value)!.id*/
          ? this.addressForm.get('city')!.value
          : this.addressForm.get('city2')!.value
        },
      street: this.addressForm.get('address1')!.value,
      type: this.addressForm.get('real')!.value || 3
    }
  }

  public getCountryName(id: string) {
    if (this.filteredCountries) {
      return this.filteredCountries.find(c => c.id === id)?.title;
    } else {
      return "";
    }
  }

  public getCityName(id: string) {
    if (this.cities) {
      return this.cities.find(c => c.id === id)?.title;
    } else {
      return "";
    }
  }
}
