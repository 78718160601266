<div class="chat-container">
	<div class="messages-container" #messagesContainer [scrollTop]="messagesContainer.scrollHeight">
		<div *ngFor="let msg of messages" class="message-container" [ngClass]="{'my-message-container': msg.self}">
			<div class="sender-info">
				<div class="avatar">
					<img [src]="msg.avatar">
				</div>
				<div class="sender-name">
					{{ msg.name }}
				</div>

			</div>
			<div class="text-container">
				{{ msg.message }}
			</div>
		</div>
	</div>

	<div class="message-input-container">
		<input 
			class="message-input" 
			type="text" 
			name="message"
			autocomplete="off"
			[placeholder]="'chat.input-placeholder' | translate"
			(keyup.enter)="sendMsg()"
			#textInput
		>
		<div class="icon-container" (click)="sendMsg()">
			<img
	      src="assets/icons/add-blue-icon.svg"
	    />
		</div>
	</div>
</div>