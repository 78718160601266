<section class="service-catalog">
  <h3 class="service-catalog__title">
    {{ "service-catalog.title" | translate }}
  </h3>
  <div class="service-catalog__container">
    <div class="service-catalog__container__items">
      <add-company-widget
        [title]="'service-catalog.add-company.title' | translate"
        [info]="'service-catalog.add-company.info' | translate"
        [plus]="true"
        [callBackFunk]="navigateToStartACompany"
      ></add-company-widget>
      <add-company-widget
        [status]="ordersCount"
        [title]="'service-catalog.orders.title' | translate"
        [info]="'service-catalog.orders.info' | translate"
        [pic]="'assets/icons/my-orders-pic.svg'"
        [callBackFunk]="navigateToMyOrders"
      ></add-company-widget>
      <add-company-widget
        [status]="companiesCount"
        [title]="'service-catalog.companies.title' | translate"
        [info]="'service-catalog.companies.info' | translate"
        [pic]="'assets/icons/my-companies-pic.svg'"
        [callBackFunk]="navigateToMyCompanies"
      ></add-company-widget>
      <add-company-widget
        [title]="'service-catalog.services.title' | translate"
        [info]="'service-catalog.services.info' | translate"
        [pic]="'assets/icons/additional-services-pic.svg'"
        [callBackFunk]="navigateToAdditionalServices"
      ></add-company-widget>
    </div>
  </div>
</section>
<section class="service-catalog-mobile">
  <h3 class="service-catalog__title">
    {{ "service-catalog.title" | translate }}
  </h3>
  <button
    class="service-catalog-mobile__user"
    (click)="navigateToUserCabinetInfo()"
  >
    <div class="service-catalog-mobile__user__left">
      <img
        src="assets/icons/success-sign.svg"
        class="animate__animated animate__zoomIn"
        [ngClass]="
          !userInfo?.emailIsConfirmed
            ? 'service-catalog-mobile__user__img-gray'
            : ''
        "
        alt=""
      />
      <div class="service-catalog-mobile__user__text">
        <p class="service-catalog-mobile__user__text__name">{{ name }}</p>
        <p
          *ngIf="!userInfo?.emailIsConfirmed"
          class="service-catalog-mobile__user__text__status"
        >
          {{ "right-side-sign-in.verification-needed" | translate }}
        </p>
        <p
          *ngIf="userInfo?.emailIsConfirmed"
          class="service-catalog-mobile__user__text__status-approved"
        >
          {{ "right-side-sign-in.verified" | translate }}
        </p>
      </div>
    </div>

    <img src="assets/icons/sm-arrow-right.svg" alt="arrow-right" />
  </button>
  <div class="service-catalog-mobile__container">
    <div class="service-catalog-mobile__container__items">
      <add-company-widget
        [status]="'16'"
        [title]="'service-catalog.add-company.title' | translate"
        [info]="'service-catalog.add-company.info' | translate"
        [plus]="true"
        [callBackFunk]="navigateToStartACompany"
      ></add-company-widget>
      <add-company-widget
        [status]="'16'"
        [title]="'service-catalog.orders.title' | translate"
        [info]="'service-catalog.orders.info' | translate"
        [pic]="'assets/icons/my-orders-pic.svg'"
        [callBackFunk]="navigateToMyOrders"
      ></add-company-widget>
      <add-company-widget
        [status]="'16'"
        [title]="'service-catalog.companies.title' | translate"
        [info]="'service-catalog.companies.info' | translate"
        [pic]="'assets/icons/my-companies-pic.svg'"
        [callBackFunk]="navigateToMyCompanies"
      ></add-company-widget>
      <add-company-widget
        [status]="'16'"
        [title]="'service-catalog.services.title' | translate"
        [info]="'service-catalog.services.title' | translate"
        [pic]="'assets/icons/additional-services-pic.svg'"
        [callBackFunk]="navigateToAdditionalServices"
      ></add-company-widget>
    </div>
  </div>
</section>
