<button
  [ngClass]="[changeClass(), disabled ? 'button-disabled' : '']"
  class="Button"
  (click)="callBackFunc()"
  [ngStyle]="{
    width: PutWidth ? PutWidth : maxWidth ? '100%' : 'fit-content',
    padding: padding ? padding : '',
    paddingTop: paddingVert ? paddingVert : '',
    paddingBottom: paddingVert ? paddingVert : ''
  }"
  [disabled]="disabled"
>
  {{ btnName }}
</button>
