<div class="user-cabinet">
  <div class="user-cabinet__user-main">
    <app-circle-img
      [size]="'112px'"
      [pic]="avatar ? avatar : 'assets/icons/camera.svg'"
      [sizeImg]="avatar ? '100%' : '35px'"
    />
    <p class="user-cabinet__user-main__name">
      {{ userInfo?.firstName }} {{ userInfo?.lastName }}
    </p>
    <buttonComponent
      [color]="'blue-frame'"
      [btnName]="'user-cabinet.info' | translate"
      [maxWidth]="false"
      [padding]="'8px 20px'"
      [callBackFunc]="navigateToInfo"
    />
    <div class="user-cabinet__buttons">
      <div class="user-cabinet__buttons__column">
        <div class="user-cabinet__buttons__btn">
          <label class="user-cabinet__buttons__btn__label">{{
            "user-cabinet.payment-method" | translate
          }}</label>
          <buttonComponenType2
            *ngIf="this.defaultCard == -1"
            [btnName]="'user-cabinet.add-btn' | translate"
            [maxWidth]="true"
            [padding]="'22px 20px'"
            [callBackFunc]="navigateToPayment"
          />
          <buttonComponenType2
            *ngIf="this.defaultCard !== -1 && this.cardsData"
            [btnName]="
              this.cardsData[this.defaultCard]
                ? this.cardsData[this.defaultCard].paymentMetaData.brand +
                  ' ' +
                  this.cardsData[this.defaultCard].paymentMetaData.lastDigits
                : ''
            "
            [maxWidth]="true"
            [padding]="'22px 20px'"
            [callBackFunc]="navigateToPayment"
          />
        </div>
        <div class="user-cabinet__buttons__btn">
          <label class="user-cabinet__buttons__btn__label">{{
            "user-cabinet.subscribtions-and-transactions" | translate
          }}</label>
          <buttonComponenType2
            [btnName]="'user-cabinet.more-btn' | translate"
            [maxWidth]="true"
            [padding]="'22px 20px'"
            [callBackFunc]="navigateToTransactions"
          />
        </div>
        <div class="user-cabinet__buttons__btn">
          <label class="user-cabinet__buttons__btn__label">{{
            "user-cabinet.mail" | translate
          }}</label>

          <buttonComponenType2
            *ngIf="!userInfo?.email"
            [btnName]="'user-cabinet.mail-btn' | translate"
            [maxWidth]="true"
            [padding]="'22px 20px'"
            [color]="'blue'"
            [callBackFunc]="navigateToInfo"
          />

          <button
            class="user-cabinet-status"
            *ngIf="userInfo?.email"
            (click)="naviagateToConfirmMail()"
            [disabled]="userInfo?.emailIsConfirmed"
          >
            <div class="user-cabinet-status__info">
              <p class="user-cabinet-status__title">
                {{ userInfo?.email }}
              </p>
              <p
                class="user-cabinet-status__text"
                *ngIf="userInfo?.emailIsConfirmed"
              >
                {{ "user-cabinet.approve-status.approved" | translate }}
              </p>
              <p
                class="user-cabinet-status__text-normal"
                *ngIf="!userInfo?.emailIsConfirmed"
              >
                {{ "user-cabinet.approve-status.discard" | translate }}
              </p>
            </div>
            <img
              src="assets/icons/success-sign.svg"
              class="animate__animated animate__zoomIn"
              [ngClass]="
                !userInfo?.emailIsConfirmed
                  ? 'user-cabinet-status__img-gray'
                  : ''
              "
              alt=""
            />
          </button>
        </div>
      </div>
      <div class="user-cabinet__buttons__column">
        <div class="user-cabinet__buttons__btn">
          <label class="user-cabinet__buttons__btn__label">{{
            "user-cabinet.phone" | translate
          }}</label>

          <buttonComponenType2
            *ngIf="!userInfo?.phoneNumber"
            [btnName]="'user-cabinet.phone' | translate"
            [maxWidth]="true"
            [padding]="'22px 20px'"
            [color]="'blue'"
            [callBackFunc]="navigateToInfo"
          />

          <button
            class="user-cabinet-status"
            *ngIf="userInfo?.phoneNumber"
            [disabled]="true"
          >
            <div class="user-cabinet-status__info">
              <p class="user-cabinet-status__title">
                {{ userInfo?.phoneNumber }}
              </p>
              <p class="user-cabinet-status__text" *ngIf="true">
                {{ "user-cabinet.approve-status.approved" | translate }}
              </p>
              <p class="user-cabinet-status__text-normal" *ngIf="false">
                {{ "user-cabinet.approve-status.discard" | translate }}
              </p>
            </div>
            <img
              src="assets/icons/success-sign.svg"
              class="animate__animated animate__zoomIn"
              alt=""
            />
          </button>
          <!-- [ngClass]="
          !userInfo?.phone?.confirmState
            ? 'user-cabinet-status__img-gray'
            : ''
        " -->
        </div>

        <div class="user-cabinet__buttons__btn">
          <label class="user-cabinet__buttons__btn__label">{{
            "user-cabinet.citizenship" | translate
          }}</label>
          <button
            class="user-cabinet-status"
            [disabled]="userInfo?.address?.country?.title"
            (click)="router.navigateByUrl('account/user-cabinet-info')"
            [ngStyle]="{
              cursor: userInfo?.address?.country?.title ? 'default' : 'pointer'
            }"
          >
            <div class="user-cabinet-status__info">
              <p class="user-cabinet-status__title">
                {{
                  userInfo?.address?.country?.title
                    ? userInfo?.address?.country?.title
                    : ("user-cabinet.add-citizenship" | translate)
                }}
              </p>
            </div>
            <img
              *ngIf="!userInfo?.address?.country?.title"
              src="assets/icons/sm-arrow-right.svg"
            />
          </button>
        </div>

        <div class="user-cabinet__buttons__btn">
          <label class="user-cabinet__buttons__btn__label">{{
            "user-cabinet.residency" | translate
          }}</label>
          <button
            class="user-cabinet-status"
            [disabled]="userInfo?.residency?.title"
            (click)="router.navigateByUrl('account/user-cabinet-info')"
            [ngStyle]="{
              cursor: userInfo?.residency?.title ? 'default' : 'pointer'
            }"
          >
            <div class="user-cabinet-status__info">
              <p class="user-cabinet-status__title">
                {{
                  userInfo?.residency?.title
                    ? userInfo?.residency?.title
                    : ("user-cabinet.add-residency" | translate)
                }}
              </p>
            </div>
            <img
              *ngIf="!userInfo?.residency?.title"
              src="assets/icons/sm-arrow-right.svg"
            />
          </button>
        </div>
        <!-- <div class="user-cabinet__buttons__btn">
          <label class="user-cabinet__buttons__btn__label">{{
            "user-cabinet.residency" | translate
          }}</label>
          <app-dropdown-type-1
            [name]="
              rezidenship.value
                ? rezidenship.value
                : ('user-cabinet.add-rezidency' | translate)
            "
            [nameDefault]="'user-cabinet.save-btn' | translate"
            [elements]="countriesTitles"
            [size]="'100%'"
            [color]="'#262A34'"
            (setItemFromButton)="setRezedenship($event)"
            (settingDropdownOpen)="setDropdown($event)"
            [dropdownOpen]="dropdown == '2' ? true : false"
            [dropdown]="'2'"
          />
        </div> -->
      </div>
    </div>
    <div class="user-cabinet__user-main__save" *ngIf="saveBtn">
      <buttonComponent
        [maxWidth]="false"
        [padding]="'9px 20px'"
        [color]="'blue'"
        [btnName]="'user-cabinet.save-btn' | translate"
        [callBackFunc]=""
      />
    </div>

    <a
      class="user-cabinet__user-main__logout"
      (click)="openLogoutModalWindow()"
    >
      {{ "user-cabinet.logout" | translate }}
    </a>
  </div>
</div>

<!--modal for logout confirm -->
<app-modal-window
  [open]="modalLogout"
  [title]="'alerts.logout.title' | translate"
  [text]="'alerts.logout.text' | translate"
  [cancelText]="'alerts.cancel-btn' | translate"
  [cancelFunction]="closeLogoutModalWindow"
  [okText]="'alerts.ok-btn' | translate"
  [okFunction]="onLogout"
></app-modal-window>
