import { Component, Input, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CityData, CountryData } from 'app/store/Shared/model/SharedModel';
import {
  getCitiesByCountry,
  getCitiesByCountrySuccess,
  updateTemplate,
  updateTemplateSuccess,
} from 'app/store/Shared/shared.actions';
import { UserInfoProps } from 'app/store/UserInfo/models/userInfo.model';
import { AppState } from 'app/store/app.state';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DropdownType1Eement } from 'shared/ui/dropdown-type-1/circle-img/dropdown-type-1';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-add-company-founders',
  templateUrl: './ui/AddCompanyFounders.html',
  styleUrls: ['./ui/AddCompanyFounders.scss'],
})
export class AddCompanyFounders {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private rootFormGroup: FormGroupDirective,
    private translateService: TranslateService,
    private actions: Actions,
  ) {}
  @Input() formGroupaName: string;
  @Input() formGroupa2Name: string;
  @Input() bigFormGroupName: string;
  @Input() structureFormGroupName: string;
  legalFoundersFormArray: FormArray;
  individualFoundersFormArray: FormArray;
  directorsFormArray: FormArray;
  directorsFormGroup: FormGroup;

  legalForms = [{ title: 'LTD' }];

  userInfo: UserInfoProps | null;
  foundersForm: FormGroup;
  structureForm: FormGroup;
  countries: CountryData[];
  citizenship: string;
  @Input() addFounder: any;
  @Input() addIndividualFounder: any;

  @Input() nextStep: any;
  @Input() prevStep: any;
  @Input() prewiewStep: any;

  dropdown: number | string = '';
  numberOfFounders: number[] = [];
  editable: string;
  shares: number = 100;

  //cities and countries
  suffix = new BehaviorSubject<any>('');
  //continue button
  continueButton: boolean = false;

  saveMode: boolean | undefined = false;

  subs: Subscription[] = [];

  setSuffix(elem: any) {
    this.suffix.next(elem);
  }

  ngOnInit() {
    //initialization of root form group
    this.foundersForm = this.rootFormGroup.control.get(
      this.bigFormGroupName
    ) as FormGroup;

    this.legalFoundersFormArray = this.foundersForm.get(
      this.formGroupaName
    ) as FormArray;
    this.individualFoundersFormArray = this.foundersForm.get(
      this.formGroupa2Name
    ) as FormArray;
    this.directorsFormGroup = this.rootFormGroup.control.get(
      'directorsGroup'
    ) as FormGroup;
    this.directorsFormArray = this.directorsFormGroup.get(
      'directors'
    ) as FormArray;

    this.structureForm = this.rootFormGroup.control.get(
      this.structureFormGroupName
    ) as FormGroup;

    // this.participantsForm.valueChanges.subscribe((data) => {});

    //setting counties array
    this.subs.push(
      this.store
        .select((state) => state.shared.countries)
        .subscribe((countries) => {
          if (countries) {
            this.countries = countries;
          }
        })
    )
    //setting virtual to undefined when ihaveanaddress switches
    this.foundersForm.valueChanges.subscribe((data) => {
      this.setContinueBtn();
      this.calculateShare();
    });

    //editable
    this.subs.push(
      this.store
        .select((state) => state.shared.editableCompanyStep)
        .subscribe((editable) => {
          if (editable) {
            this.editable = editable;
          }
        })
    )

    /*this.legalFoundersFormArray.setValue([]);
    while (formArray.length !== 0) { //clear form
      formArray.removeAt(0)
    }*/

    let founder = this.directorsFormArray.value.find((e: any) => e.founder);

    for (let num = this.structureForm.value.numberOfFounders; num > 0; num--) {
      this.numberOfFounders.push(Number(num));
    }

    if (founder && this.legalFoundersFormArray.value.length === this.numberOfFounders.length) {
      this.legalFoundersFormArray.controls[0].setValue({
        ...this.legalFoundersFormArray.controls[0].value,
        nameAndLastName: founder.nameAndLastName,
        citizenship: founder.citizenship,
        passport: founder.passport
      })
    }

    for (let i = this.legalFoundersFormArray.value.length; i < this.numberOfFounders.length; i++) {
      this.addFounder(founder && i == 0 ? founder : null, this.numberOfFounders.length === 1);
    }

    while (this.legalFoundersFormArray.value.length > this.numberOfFounders.length) {
      this.deleteFounder(this.legalFoundersFormArray.value.length - 1);
    }
    this.calculateShare();
    this.setContinueBtn();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  setFoundersCountry = (index: number, element: string) => {
    this.legalFoundersFormArray.controls[index].setValue({
      ...this.legalFoundersFormArray.value[index],
      country: element,
    });
  };
  setFoundersForm = (index: number, element: string) => {
    this.legalFoundersFormArray.controls[index].setValue({
      ...this.legalFoundersFormArray.value[index],
      form: element,
    });
  };

  setLegalEntity = (index: number, value: boolean) => {
    this.legalFoundersFormArray.controls[index].setValue({
      ...this.legalFoundersFormArray.value[index],
      legal: value,
    });
  };

  setFoundersCitizenship = (index: number, element: string) => {
    this.legalFoundersFormArray.controls[index].setValue({
      ...this.legalFoundersFormArray.value[index],
      citizenship: element,
    });
  };

  deleteFounder = (index: number) => {
    this.legalFoundersFormArray.removeAt(index);
  };

  setContinueBtn = () => {
    let length = this.legalFoundersFormArray.length;
    let fulfilled = false;

    for (let i = 0; i < length; i++) {
      if (this.legalFoundersFormArray.value[i].legal) {
        if (
          this.legalFoundersFormArray.value[i].country == '' ||
          this.legalFoundersFormArray.value[i].country == null ||
          this.legalFoundersFormArray.value[i].form == '' ||
          this.legalFoundersFormArray.value[i].form == null ||
          this.legalFoundersFormArray.value[i].regNumber == '' ||
          this.legalFoundersFormArray.value[i].regNumber == null ||
          this.legalFoundersFormArray.value[i].share == '' ||
          this.legalFoundersFormArray.value[i].share == '0' ||
          this.legalFoundersFormArray.value[i].share == null
        ) {
          fulfilled = false;
          break;
        } else {
          fulfilled = true;
        }
      }

      if (!this.legalFoundersFormArray.value[i].legal) {
        if (
          this.legalFoundersFormArray.value[i].nameAndLastName == '' ||
          this.legalFoundersFormArray.value[i].nameAndLastName == null ||
          this.legalFoundersFormArray.value[i].citizenship == '' ||
          this.legalFoundersFormArray.value[i].citizenship == null ||
          this.legalFoundersFormArray.value[i].passport == '' ||
          this.legalFoundersFormArray.value[i].passport == null ||
          this.legalFoundersFormArray.value[i].share == '' ||
          this.legalFoundersFormArray.value[i].share == '0' ||
          this.legalFoundersFormArray.value[i].share == null
        ) {
          fulfilled = false;
          break;
        } else {
          fulfilled = true;
        }
      }
    }
      
    this.continueButton = fulfilled;
  };

  setDropdown(drop: string): void {
    this.dropdown = drop;
    console.log(drop);
  }

  //calculate shares
  calculateShare = () => {
    let shares = 100;
    let number = 0;
    console.log(this.directorsFormArray);
    /*if (this.directorsFormArray) {
      for (let i = 0; i < this.directorsFormArray.length; i++) {
        let dirShare = this.directorsFormArray.value[i].share;
        number += Number(dirShare);
      }
    }*/
    if (this.legalFoundersFormArray) {
      for (let i = 0; i < this.legalFoundersFormArray.length; i++) {
        let founderShare = this.legalFoundersFormArray.value[i].share;
        number += Number(founderShare);
      }
    }
    if (this.individualFoundersFormArray) {
      for (let i = 0; i < this.individualFoundersFormArray.length; i++) {
        let founderShare = this.individualFoundersFormArray.value[i].share;
        number += Number(founderShare);
      }
    }

    let amount = shares - number;
    amount > 0
      ? (this.shares = amount)
      : amount == 0
      ? (this.shares = 0)
      : (this.shares = -1);
  };

  //button-back
  buttonBack = () => {
    this.prevStep();
  };

  //submits structere and forfards step
  submitStructure = (saveMode?: boolean) => {
    this.saveMode = saveMode;
    let data = this.prepairValues();
    this.subs.push(
      this.store
        .select((state) => state.shared.currentTemplateId)
        .subscribe((id) => {
          if (id) {
            this.store.dispatch(updateTemplate({
              data,
              id: id
            }));
          }
        })
    )

    this._listenForUpdateSuccess();
    // if (!saveMode) {
    //   this.editable == 'founders' ? this.prewiewStep() : this.nextStep();
    // }
  };

  private _listenForUpdateSuccess() {
    this.subs.push(
      this.actions
        .pipe(ofType(updateTemplateSuccess))
        .subscribe(() => {
          if (!this.saveMode) {
            this.editable == 'founders' ? this.prewiewStep() : this.nextStep();
          }
        })
    );  
  }

  private prepairValues() {
    console.log(this.legalFoundersFormArray.value);
    //this.numberOfFounders -> 1 - individual, 2 - legal 
    let pdata = this.legalFoundersFormArray.value.map((el: any) => {
      return {
        nationality: el.citizenship || "",
        passport: el.passport || "",
        share: el.share * +!el.legal,
        fullname: el.nameAndLastName || "",
      }
    });

    let cdata = this.legalFoundersFormArray.value.map((el: any) => {
      return {
        share: el.share * +el.legal,
        form: el.form || "",
        title: el.name || "",
        country: el.country || "",
        number: el.regNumber || ""
      }
    });
    return {
      shareholderPhysical: pdata,
      shareholderCompany: cdata,
      processStep: 6
    }
  }

  _setFounder = (f: any) => {

  }

  public getCountryName(id: string) {
    return this.countries.find(c => c.id === id)!.title;
  }
}
