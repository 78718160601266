import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-new-pwd',
  templateUrl: './ui/form-new-pwd.component.html',
  styleUrls: ['./ui/form-new-pwd.component.scss'],
})
export class FormNewPwdComponent {
  Object: Object;

  @Input() formGroupName: string;

  form: FormGroup;
  inputs: string[];

  constructor(
    private rootFormGroup: FormGroupDirective,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.inputs = Object.keys(this.form.controls);
  }
}
