import { Component, Input, Output, EventEmitter } from '@angular/core';

export type DropdownType1Eement = {
  id?: string;
  title: string;
  option?: string;
};

@Component({
  selector: 'app-dropdown-type-1',
  templateUrl: './ui/dropdown-type-1.component.html',
  styleUrls: ['./ui/dropdown-type-1.component.scss'],
})
export class DropdownType1Component {
  constructor() {}

  @Input() name: string | undefined = '';
  @Input() nameDefault: string | undefined = '';
  @Input() size: string = '';
  @Input() elements: DropdownType1Eement[] = [];
  @Input() color: string = '';
  @Output() setItemFromButton = new EventEmitter<string>();
  @Output() settingDropdownOpen = new EventEmitter<string>();
  @Output() settingSaveButton = new EventEmitter<boolean>();
  @Input() dropdown: string = '';
  @Input() dropdownOpen: boolean = false;
  @Input() disabled: boolean = false;
  @Input() nullValue?: string | undefined;

  ngOnInit() {
  }

  setDropdownOpen(event?: string) {
    this.settingDropdownOpen.emit(event);
    this.settingSaveButton.emit(true);
  }

  buttonFunc(elem: any): void {
    this.setItemFromButton.emit(elem);
    this.setDropdownOpen('');
    console.log(elem);
  }
}
