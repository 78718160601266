<button class="add-company-widget" (click)="callBackFunk()">
  <div class="add-company-widget__widgets">
    <div *ngIf="plus" class="add-company-widget__widgets__plus"></div>
    <div *ngIf="pic" class="add-company-widget__widgets__pic">
      <img [src]="pic" alt="" />
    </div>
    <p
      class="add-company-widget__widgets__status"
      [style]="{ color: statusColor }"
    >
      {{ status }}
    </p>
  </div>
  <div class="add-company-widget__text">
    <p class="add-company-widget__text__title">{{ title }}</p>
    <p class="add-company-widget__text__info">{{ info }}</p>
  </div>
</button>

<button class="add-company-widget-mobile" (click)="callBackFunk()">
  <div class="add-company-widget-mobile__content">
    <div *ngIf="plus" class="add-company-widget-mobile__content__plus"></div>
    <div *ngIf="pic" class="add-company-widget-mobile__content__pic">
      <img [src]="pic" alt="" />
    </div>
    <div class="add-company-widget-mobile__content__text">
      <p class="add-company-widget-mobile__content__text__title">{{ title }}</p>
      <p class="add-company-widget-mobile__content__text__info">{{ info }}</p>
    </div>
  </div>

  <div class="add-company-widget-mobile__widgets">
    <p class="add-company-widget-mobile__widgets__status">{{ status }}</p>
    <div class="add-company-widget-mobile__widgets__arrow">
      <img src="assets/icons/sm-arrow-right.svg" alt="arrow-right" />
    </div>
  </div>
</button>
