import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  autoLogout,
  loginStart,
  loginSuccess,
  loginFailure,
  logoutSuccess,
} from './auth.actions';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { AppState } from 'app/store/app.state';
import {
  autoLogin,
  setLoadingSpinner,
  setLoginErrorCode,
  setLoginErrorMessage,
  setLogoutMessage,
  setRequestError,
  setRequestSuccess,
} from 'app/store/Shared/shared.actions';
import { Router } from '@angular/router';
import { getUserInfo } from 'app/store/UserInfo/userInfo.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginStart),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.authService.login(action.phoneNumber, action.password).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const error = data.error;
            const errCode = data.code;

            const user = this.authService.formatUser(data.data);
            this.authService.setUserInLocalStorage(user);
            this.store.dispatch(
              setRequestSuccess({ message: 'login success' })
            );

            //this.store.dispatch(getUserInfo({ token: user.userToken }));
            setTimeout(
              () => this.router.navigate(['/account/services-catalog']),
              100
            );
            return loginSuccess({ user });
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));

            return of(
              loginFailure({ errorRes }),
              setRequestError({ message: errorRes.error.message }),
              setLoginErrorMessage({ message: errorRes.error.message })
            );

            // return loginFailure({ errorRes });
          })
        );
      })
    );
  });

  loginRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loginSuccess),
        tap((action) => {
          if (this.authService.getUserFromLocalStorage()) {
            //this.router.navigate(['/account/user-cabinet']);
            /*if (localStorage.getItem('FirmderUrl')) {
              const urlFromLS: string =
                localStorage.getItem('FirmderUrl') || '/';
              if (urlFromLS.split('/')[1] == 'account') {
                this.router.navigateByUrl(urlFromLS);
              } else {
                this.router.navigate(['/account/user-cabinet']);
              }
            } else if (!localStorage.getItem('FirmderUrl')) {
              this.router.navigate(['/account/user-cabinet']);
            }*/
          } else {
            this.router.navigate(['/']);
          }
        })
      );
    },
    { dispatch: false }
  );

  autoLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(autoLogin),
      map((action) => {
        const user = this.authService.getUserFromLocalStorage();
        if (user) {
          this.store.dispatch(getUserInfo({ token: user.userToken }));
        }

        return loginSuccess({ user });
      })
    );
  });

  errorMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setLoginErrorCode),
      map((action) => {
        return setLoginErrorMessage({ message: action.message });
      })
    );
  });

  //   logout$ = createEffect(() => {});

  logoutDid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logoutSuccess),
      exhaustMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.authService.logout(action.userId).pipe(
          map((data) => {
            localStorage.clear();
            window.location.reload();
            this.store.dispatch(
              setRequestSuccess({ message: 'logout success' })
            );
            return setLogoutMessage({ message: data.message });
          }),
          catchError((errorRes) => {
            const errorMessage = this.authService.getErrorMessage(
              errorRes.error
            );
            setLoginErrorMessage({ message: errorMessage });
            this.store.dispatch(setLoadingSpinner({ status: false }));
            localStorage.clear();
            this.router.navigate(['/']);
            return of(setRequestError({ message: 'logout error' }));
          })
        );
      })
    );
  });
}
