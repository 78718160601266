import { OnInit, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { AppState } from 'app/store/app.state';
import { Subscription } from 'rxjs';
import { SharedService } from 'app/store/Shared/services/SharedServices';

@Component({
  selector: 'app-my-orders-to-registration-page',
  templateUrl: './ui/MyOrdersToRegistration.component.html',
  styleUrls: ['./ui/MyOrdersToRegistration.component.scss'],
})
export class MyOrdersToRegistration implements OnInit {
  step = 0;
  selectedCategory: any;
  parentForm: FormGroup<any> = new FormGroup({});
  orderId: string;
  directors: any = [];
  founders: any = [];

  categoryName: string;
  subCategoryName: string;
  selectedTypes: any = [];

  showModal: boolean = false;

  subs: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.orderId = this.route.snapshot.queryParams['id']
    if (this.orderId) {
      this._initForm();
      this._getOrder();
    } else {

    }
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _initForm() {
    this.parentForm.addControl(
      'category',
      new FormControl(
        '',
        Validators.required
      )
    );

    this.parentForm.addControl(
      'sub-category',
      new FormControl(
        '',
        Validators.required
      )
    );

    this.parentForm.addControl(
      'type',
      new FormControl(
        '',
        Validators.required
      )
    );

    this.parentForm.addControl(
      'directors',
      new FormArray(
        [],
        Validators.required
      )
    );

    this.parentForm.addControl(
      'founders',
      new FormArray(
        [],
        Validators.required
      )
    );
  }

  private _getOrder() {
    this.sharedService.getOrderById(this.orderId)
      .subscribe(({data}: any) => {
        if (data.categories) {
          this.parentForm.get('category')!.setValue((data.categories?.id || "")as never);
          this.categoryName = data.categories.name;

          let subs = data.categories.subCategories.map((e: any) => e.id).join(', ');
          this.parentForm.get('sub-category')!.setValue(subs as never);
          this.subCategoryName = data.categories.subCategories.map((e: any) => e.name).join(', ');
        }
        
        if (data.activities) {        
          let types = data.activities.map((e: any) => e.id).join(', ');
          this.parentForm.get('type')!.setValue(types as never);
          this.selectedTypes = data.activities;
        }

        this.directors = data.director;
        for (let i = 0; i < data.director.length; i++) {
          (this.parentForm.controls.directors as FormArray).push(
            this._createItem(data.director[i].addressProofUrl, data.director[i].passportUrl)
          );
        }
        this.founders = data.shareholderPhysical;
        for (let i = 0; i < data.shareholderPhysical.length; i++) {
          let required = !(i === 0 && data.director[0].isOwner);
          (this.parentForm.controls.founders as FormArray).push(
            this._createItem(data.shareholderPhysical[i].addressProofUrl, data.shareholderPhysical[i].passportUrl, required)
          );
        }
      });
  }

  private _createItem(addressProofUrl?: string, passportUrl?: string, required: boolean = true) {
    return this.fb.group({
      passportUrl: this.fb.control(addressProofUrl || '', required ? Validators.required : null),
      addressProofUrl: this.fb.control(passportUrl || '', required ? Validators.required : null),
    })
  }

  public onCategorySelect(event: any) {
    if (event.rowSelected) {
      this.selectedCategory = event.id;
      this.parentForm.get('category')!.setValue(event.id as never);
      this.categoryName = event.name;
      this.step = 2;
    } else {
      this.selectedCategory = null;
      this.parentForm.get('category')!.setValue("" as never);
      this.parentForm.get('sub-category')!.setValue("" as never);
      this.categoryName = "";
      this.step = 0;
    }
  }

  public onSubCategorySelect(event: any) {
    let ids = event.map((e: any) => e.id);
    this.parentForm.get('sub-category')!.setValue(ids.join(', ') as never);
    if (event.length) {
      let names = event.map((e: any) => e.name);
      this.subCategoryName = names.join(', ');
    }
    this.step = 0;
  }

  public onTypeSelect(event: any) {
    let ids = event.map((e: any) => e.id);
    this.parentForm.get('type')!.setValue(ids.join(', ') as never);
    if (event.length) {
      this.selectedTypes = event;
    }
    this.step = 0;
  }

  public handleFileInput(event: any, person: string, type: string, index: number) {
    const file:File = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("path", "documents");
    
    this.sharedService.uploadFile(formData)
    .subscribe(({data}: any) => {
        if (person === 'director') {
          this.directors[index][type] = data.url;
          (this.parentForm.get('directors') as FormArray).at(index).patchValue({[type]: data.url});
        } else {
          this.founders[index][type] = data.url;
          (this.parentForm.get('founders') as FormArray).at(index).patchValue({[type]: data.url});
        }
      })
  }

  public removeType(type: any) {
    const ind = this.selectedTypes.findIndex((e: any) => e.id === type.id);
    this.selectedTypes.splice(ind, 1);

    let ids = this.selectedTypes.map((e: any) => e.id);
    this.parentForm.get('type')!.setValue(ids.join(', ') as never);
  }

  public onSubmit() {
    let subs = (this.parentForm.get('sub-category')!.value as any).split(", ")!.map((e: any) => { return {id: e}});
    let types = (this.parentForm.get('type')!.value as any).split(", ")!.map((e: any) => { return {id: e}});
    let directors = structuredClone(this.directors);
    directors.map((e: any) => {
      delete e.addressProofState;
      delete e.passportState;
    })
    let founders = structuredClone(this.founders);
    founders.map((e: any) => {
      delete e.addressProofState;
      delete e.passportState;
    })
    let data = {
      categories: {
        id: this.parentForm.get('category')!.value,
        subCategories: subs
      },
      activities: types,
      director: directors,
      shareholderPhysical: founders
    }
    this.sharedService.updateTemplate(data, this.orderId)
      .subscribe((data: any) => {
        this.showModal = true;
      });
  }

  closeModal = () => {
    this.router.navigate(['/account/my-orders']);
    this.showModal = false;
  }

  validDocuments() {
    return this.parentForm.get('directors')!.valid && this.parentForm.get('founders')!.valid;
  }
}
