<form class="confirm-mail-page" [formGroup]="confirmMailForm">
  <h1 class="confirm-mail-page__title">
    {{ "confirm-mail-page.title" | translate }}
  </h1>
  <div class="confirm-mail-inputs">
    <p class="confirm-mail-inputs__text-top">
      {{ "confirm-mail-page.mail-sent-to" | translate }}
      <span>{{ mailAdress }}</span>
    </p>
    <div class="confirm-mail-inputs__input-box">
      <input
        type="text"
        class="confirm-mail-inputs__input-box__input"
        [placeholder]="'confirm-mail-page.email-code' | translate"
        formControlName="code"
        [ngClass]="
          confirmMailForm.get('code')?.invalid || codeError
            ? 'confirm-mail-inputs__input-box__input-invalid'
            : code.value !== ''
            ? 'confirm-mail-inputs__input-box__input-valid'
            : ''
        "
        #code
      />
      <p
        *ngIf="confirmMailForm.get('code')?.errors?.required"
        class="confirm-mail-inputs__input-box__error"
      >
        {{ "reg-page.errors.empty" | translate }}
      </p>
      <p
        *ngIf="confirmMailForm.get('code')?.errors?.pattern"
        class="confirm-mail-inputs__input-box__error"
      >
        {{ "reg-page.errors.code" | translate }}
      </p>
      <p *ngIf="codeError" class="confirm-mail-inputs__input-box__error">
        {{ codeError }}
      </p>
    </div>
  </div>
  <button
    class="Button Button-blue"
    (click)="onSubmit()"
    [disabled]="!Valid()"
    [ngClass]="Valid() ? 'ss' : 'Button-blue-disabled'"
  >
    {{ "reg-page.continue-btn" | translate }}
  </button>
  <p *ngIf="time > 0" class="confirm-mail-page__bottom-text">
    {{ "reset-pwd.new-send-in" | translate }}
    <span>{{ time }} {{ word }}</span>
  </p>
  <button
    *ngIf="time <= 0"
    class="confirm-mail-page__bottom-button"
    (click)="sendCodeAgain()"
  >
    <p class="confirm-mail-page__bottom-button__link">
      {{ "reset-pwd.code-send-again" | translate }}
    </p>
  </button>
</form>

<!--modal for mail confirm submit-->
<app-modal-window
  [open]="modalConfirmMail"
  [title]="'alerts.save2' | translate"
  [okText]="'alerts.ok-btn' | translate"
  [okFunction]="closeConfirmMailWindow"
></app-modal-window>
