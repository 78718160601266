<div class="user-cabinet-info">
  <div class="user-cabinet-info__main">
    <app-circle-img
      [size]="'112px'"
      [pic]="avatar ? avatar : 'assets/icons/camera.svg'"
      [sizeImg]="avatar ? '100%' : '35px'"
      [upload]="saveActive"
      [imgId]="'avatarUserInfo'"
    />
    <form class="user-cabinet-info__inputs" [formGroup]="changeUserInfoForm">
      <div
        class="user-cabinet-info__inputs__input-box"
        [ngStyle]="{ gap: '2px' }"
      >
        <label for="" class="user-cabinet-info__inputs__input-box__label">{{
          "user-cabinet-info.name" | translate
        }}</label>
        <input
          type="text"
          class="user-cabinet-info__inputs__input-box__input"
          placeholder=""
          [ngStyle]="{ padding: '2px 0' }"
          formControlName="Name"
          [readonly]="!saveActive"
          #Name
        />
      </div>
      <div
        class="user-cabinet-info__inputs__input-box"
        [ngStyle]="{ gap: '2px' }"
      >
        <label for="" class="user-cabinet-info__inputs__input-box__label">{{
          "user-cabinet-info.lastName" | translate
        }}</label>
        <input
          type="text"
          class="user-cabinet-info__inputs__input-box__input"
          placeholder=""
          [ngStyle]="{ padding: '2px 0' }"
          formControlName="LastName"
          [readonly]="!saveActive"
        />
      </div>
      <div
        class="user-cabinet-info__inputs__input-box"
        [ngStyle]="{ gap: '2px' }"
      >
        <label for="" class="user-cabinet-info__inputs__input-box__label">{{
          "user-cabinet-info.phone" | translate
        }}</label>
        <input
          type="text"
          class="user-cabinet-info__inputs__input-box__input"
          placeholder=""
          [ngStyle]="{ padding: '2px 0' }"
          formControlName="Phone"
          [readonly]="!saveActive"
        />
      </div>
      <div
        class="user-cabinet-info__inputs__input-box"
        [ngStyle]="{ gap: '2px' }"
      >
        <label for="" class="user-cabinet-info__inputs__input-box__label">{{
          "user-cabinet-info.mail" | translate
        }}</label>
        <input
          type="text"
          class="user-cabinet-info__inputs__input-box__input"
          placeholder=""
          [ngStyle]="{ padding: '2px 0' }"
          formControlName="Mail"
          [readonly]="!saveActive"
        />
      </div>
      <div
        class="user-cabinet-info__inputs__input-box"
        [ngStyle]="{ gap: '2px' }"
      >
        <label for="" class="user-cabinet-info__inputs__input-box__label">{{
          "user-cabinet-info.dateOfBirth" | translate
        }}</label>

        <!-- #docregion toggle -->
        <mat-form-field>
          <!-- #docregion toggle -->
          <input
            #input
            matInput
            [matDatepicker]="picker"
            (focus)="picker.open()"
            formControlName="BirthDate"
            readonly="true"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #picker
            [disabled]="!saveActive"
            (closed)="input.blur()"
          ></mat-datepicker>
          <!-- #enddocregion toggle -->
        </mat-form-field>
      </div>
      <div
        class="user-cabinet-info__inputs__input-box"
        [ngStyle]="{ gap: '5px' }"
      >
        <label for="" class="user-cabinet-info__inputs__input-box__label">{{
          "citizenship" | translate
        }}</label>
        <app-dropdown-type-1
          [name]="Country.value"
          [nameDefault]="'select-citizenship' | translate"
          [elements]="countriesTitles"
          [size]="'100%'"
          [color]="'#262A34'"
          (setItemFromButton)="setCountry($event)"
          (settingDropdownOpen)="setDropdown($event)"
          [dropdownOpen]="dropdown == '1' ? true : false"
          [dropdown]="'1'"
          [disabled]="!saveActive"
          [nullValue]="'select-citizenship' | translate"
        />
      </div>
      <div
        class="user-cabinet-info__inputs__input-box"
        [ngStyle]="{ gap: '5px' }"
      >
        <label for="" class="user-cabinet-info__inputs__input-box__label">{{
          "residency" | translate
        }}</label>
        <app-dropdown-type-1
          [name]="Country2.value"
          [nameDefault]="'select-residency' | translate"
          [elements]="countriesTitles"
          [size]="'100%'"
          [color]="'#262A34'"
          (setItemFromButton)="setCountry2($event)"
          (settingDropdownOpen)="setDropdown($event)"
          [dropdownOpen]="dropdown == '2' ? true : false"
          [dropdown]="'2'"
          [disabled]="!saveActive"
          [nullValue]="'select-residency' | translate"
        />
      </div>
      <div
        class="user-cabinet-info__inputs__input-box"
        [ngStyle]="{ gap: '5px' }"
      >
        <label for="" class="user-cabinet-info__inputs__input-box__label">{{
          "user-cabinet-info.city" | translate
        }}</label>
        <app-dropdown-type-1
          [name]="City.value"
          [nameDefault]="'select-city' | translate"
          [elements]="citiesTitles"
          [size]="'100%'"
          [color]="'#262A34'"
          (setItemFromButton)="setCity($event)"
          (settingDropdownOpen)="setDropdown($event)"
          [dropdownOpen]="dropdown == '3' ? true : false"
          [dropdown]="'3'"
          [disabled]="!saveActive"
          [nullValue]="'select-city' | translate"
        />
      </div>
      <div
        class="user-cabinet-info__inputs__input-box"
        [ngStyle]="{ gap: '2px' }"
      >
        <label for="" class="user-cabinet-info__inputs__input-box__label">{{
          "street" | translate
        }}</label>
        <input
          type="text"
          class="user-cabinet-info__inputs__input-box__input"
          placeholder=""
          [ngStyle]="{ padding: '2px 0' }"
          formControlName="street"
          [readonly]="!saveActive"
        />
      </div>
      <div
        class="user-cabinet-info__inputs__input-box"
        [ngStyle]="{ gap: '2px' }"
      >
        <label for="" class="user-cabinet-info__inputs__input-box__label">{{
          "building" | translate
        }}</label>
        <input
          type="text"
          class="user-cabinet-info__inputs__input-box__input"
          placeholder=""
          [ngStyle]="{ padding: '2px 0' }"
          formControlName="building"
          [readonly]="!saveActive"
        />
      </div>
      <div
        class="user-cabinet-info__inputs__input-box"
        [ngStyle]="{ gap: '2px' }"
      >
        <label for="" class="user-cabinet-info__inputs__input-box__label">{{
          "room" | translate
        }}</label>
        <input
          type="text"
          class="user-cabinet-info__inputs__input-box__input"
          placeholder=""
          [ngStyle]="{ padding: '2px 0' }"
          formControlName="room"
          [readonly]="!saveActive"
        />
      </div>
      <div
        class="user-cabinet-info__inputs__input-box"
        [ngStyle]="{ gap: '2px' }"
      >
        <label for="" class="user-cabinet-info__inputs__input-box__label">{{
          "user-cabinet-info.mailIndex" | translate
        }}</label>
        <input
          type="text"
          class="user-cabinet-info__inputs__input-box__input"
          placeholder=""
          [ngStyle]="{ padding: '2px 0' }"
          formControlName="index"
          [readonly]="!saveActive"
        />
      </div>
    </form>
  </div>
</div>
