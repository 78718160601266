import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { getToken } from 'app/providers/AppRouter/authState/auth.selector';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  getToken(): any {
    try {
      return localStorage.getItem('firmderWebUser') || null;
    } catch {}
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // return this.store.select(getToken).pipe(
    //   exhaustMap((token) => {
    //     let data = this.getToken();
    //     if (data) {
    //       let token = JSON.parse(data).token;
    //     }
    //     if (!token) {
    //       return next.handle(req);
    //     }
    //     let modifiedReq = req.clone({
    //       setHeaders: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     });
    //     return next.handle(modifiedReq).pipe(
    //       catchError((err) => {
    //         if (err instanceof HttpErrorResponse) {
    //           if (err.status === 401) {
    //           }
    //         }
    //         return throwError(err);
    //       })
    //     );
    //   })
    // );

    let data = this.getToken();
    let token = null;
    if (data) {
      token = JSON.parse(data).token;
    }
    if (!token) {
      return next.handle(req);
    }
    let modifiedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    return next.handle(modifiedReq).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
          }
        }
        return throwError(err);
      })
    );
  }
}
