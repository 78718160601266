<div class="info">
  <p class="label">{{ 'add-company-page.structure.AmountOfAuthorizedCapital' | translate }}</p>
  <div class="slider-values">
    <div class="value">{{currency}}{{minValue}}</div>
    <div class="value">{{currency}}{{maxValue}}</div>
  </div>
  <div class="slider-container">
    <input
      type="range"
      class="slider"
      [min]="minValue"
      [max]="maxValue"
      [(ngModel)]="amount"
      [value]="amount"
      #capital
    />
  </div>
  <div class="slider-values">
    <!-- bottom magic -->
    <div class="value dynamic" [ngStyle]="setDynamicPosition()">{{currency}}{{amount}}</div>
  </div>
</div>