import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResetPwdState } from './resetPwd.state';

export const RESET_PWD_STATE_NAME = 'resetPwd';

const getResetPwdState =
  createFeatureSelector<ResetPwdState>(RESET_PWD_STATE_NAME);

export const getResetPwdData = createSelector(getResetPwdState, (state) => {
  return state.login;
});
