import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';
import { setValidationRequired } from 'shared/lib';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { AppState } from 'app/store/app.state';
import { loginStart } from 'app/providers/AppRouter/authState/auth.actions';
import { getLoginErrorMessage } from 'app/store/Shared/shared.seleсtor';
import { Subscription } from 'rxjs';

import {
  loginFailure,
} from 'app/providers/AppRouter/authState/auth.actions';

export type Step = 'personalInfo' | 'codeInfo';

@Component({
  selector: 'app-login-page',
  templateUrl: './ui/login-page.component.html',
  styleUrls: ['./ui/login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  step: any = 0;
  loginForm: FormGroup;
  loginErrorMessage: Observable<string | boolean>;

  showLoginModal = false;

  subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private actions: Actions,
  ) {}

  ngOnInit(): void {
    this._loginErrorListener();

    this.loginForm = this.fb.group({
      LoginInfo: this.fb.group({
        login: this.fb.control('', [
          Validators.pattern(
            // /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
            /^(\+)(7|49(1?))[\s\-]?\(?[0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
          ),
        ]),
        password: this.fb.control(''),
      }),
    });

    setValidationRequired(this.loginForm, 'LoginInfo', null);
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  onSubmit = (): void => {
    const userInfo = this.loginForm.get('LoginInfo') as FormArray;
    userInfo !== null
      ? Object.keys(userInfo.controls).forEach((key) => {
          let userInfoItem = userInfo.get(key);
          if (userInfoItem?.value == '') {
            userInfoItem?.setValidators(Validators.required);
          } else {
            userInfoItem?.removeValidators(Validators.required);
          }
          userInfoItem?.updateValueAndValidity();
        })
      : '';

    const phone = this.loginForm.controls.LoginInfo.value.login;
    const newPhone = phone.split('');
    // if (newPhone[0] == '8' || newPhone[0] == '7') {
    //   newPhone[0] = '+7';
    // }
    const phoneNumber = newPhone.join('');
    const password = this.loginForm.controls.LoginInfo.value.password;
    console.log(phoneNumber, password);

    if (
      password &&
      phoneNumber &&
      !userInfo.get('login')?.errors?.required &&
      !userInfo.get('login')?.errors?.pattern
    ) {
      this.store.dispatch(loginStart({ phoneNumber, password }));
    }
  };

  private _loginErrorListener() {
    this.subs.push(
      this.actions
      .pipe(ofType(loginFailure))
      .subscribe(({ errorRes }) => {
        if (errorRes.status === 429) {  //login ban
          let timeLeft = errorRes.error.message.replace(/\D/g, "");
          let date = new Date();
          date.setTime(date.getTime() + timeLeft * 60 * 1000); // timeLeft minutes
          let expires = `expires=${date.toUTCString()}`;
          document.cookie = `firmderLoginBan=true; ${expires}`;

          this.showLoginModal = true;
        }
      })
    );
  }

  closeModal = () => {
    this.showLoginModal = false;
    location.reload();
  };
}
