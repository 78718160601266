import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { AppState } from 'app/store/app.state';
import { Company } from 'app/store/Shared/model/SharedModel';
import { Subscription } from 'rxjs';

import {
  getOrderList,
  getOrderListSuccess,
  getCompanyOptions,
  getCompanyOptionsSuccess,
} from 'app/store/Shared/shared.actions';

@Component({
  selector: 'app-my-companies-page',
  templateUrl: './ui/MyCompaniesPage.component.html',
  styleUrls: ['./ui/MyCompaniesPage.component.scss'],
})
export class MyCompaniesPage {
  loading: boolean = false;
  myCompanies: any[] = [];
  countries: any = [];
  forms: any = [];
  amount: any = [];
  
  subs: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private router: Router,
  ) {}

  ngOnInit() {
    this._getCompanies();
    this._getCountries();
    this._getForms();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _getCompanies() {
    this.loading = true;
    this.store.dispatch(getOrderList());
    this.subs.push(
      this.actions
        .pipe(ofType(getOrderListSuccess.type))
        .subscribe(({data}: any) => {
          this.loading = false;
          if (data && data.length) {
            this.myCompanies = (data as any).filter((e: any) => e.state === 3 || e.state === 4);
            this.myCompanies.map((company: any) => {
              this.amount.push(company.authorizedCapital);
            })
          }
        })
      )
  }

  private _getCountries() {
    this.store
      .select((state) => state.shared.countries)
      .subscribe((countries) => {
        if (countries) {
          this.countries = countries;
        }
      });
  }

  private _getForms() {
    this.store.dispatch(getCompanyOptions());

    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyOptionsSuccess.type))
        .subscribe(({options}: any) => {
          if (options) {
            options.map((option: any) => {
              option.legalForms.map((lf: any) => {
                this.forms.push(lf);
              })
            })
          }
        })
      )
  }

  public addCompanyClick() {
    this.router.navigate([`account/add-company/`]);
  }

  public getRenewalDate(date: any) {
    const renewalDate = new Date(date);
    renewalDate.setFullYear(renewalDate.getFullYear() + 1);
    return renewalDate;
  }

  public getCountryById(id: string) {
    if (this.countries.length) {
      return this.countries.find((e: any) => e.id === id).title;
    }
  }

  public getFormNameById(id: string) {
    if (this.forms.length) {
      return this.forms.find((e: any) => e.legalFormId === id).title;
    }
  }

  public getAmount(value: string, id: string) {
    if (this.forms.length) {
      let capital = this.forms.find((e: any) => e.legalFormId === id).authorizedCapital;
      return value === 'max' 
        ? capital.maxSumm + capital.type === 0 
          ? capital.summ
          : 0
        : capital.minSumm + capital.type === 0 
          ? capital.summ
          : 0;
    }
  }

  public goToCompany(id: string) {
    this.router.navigate([`account/my-companies/${id}`]);
  }

  public downloadFiles(link: string) {
    console.log('download button clicked');
  }
}
