import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';
import { setQueryParams } from '@shared/helpers/set-query-headers';

@Component({
  selector: 'app-capital-slider-component',
  templateUrl: './ui/capital-slider.component.html',
  styleUrls: ['./ui/capital-slider.component.scss'],
})
export class CapitalSliderComponent implements OnInit, OnDestroy  {
  @Input() amount: number = 1;
  @Input() minValue: number = 1;
  @Input() maxValue: number = 1;
  @Input() currency: string = '$';

  @Output() onSelect = new EventEmitter<any>();

  subs: Subscription[] = [];

  constructor(
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  public setDynamicPosition() {
    if (this.maxValue - this.minValue > 0) {
      let shift = this.amount / (this.maxValue - this.minValue / 100) * 100;
      let val = `calc(${shift * 1.3}% + 140px - (${shift * 1.1}px)`;
      return {
        'left': val
      };
    } else {
      return {
        'left': '100%'
      };
    }
  }
}
