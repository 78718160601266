import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlDirective, FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-form-input-component',
  templateUrl: './ui/form-input-component.component.html',
  styleUrls: ['./ui/form-input-component.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true
    }
  ]
})
export class FormInputComponent implements ControlValueAccessor  {
  @Input() label: "";
  @Input() name: string;
  @Input() formName: string;
  @Input() InputId = ''

  errorMessages: Record<string , string> = {
    required: ''
  }

  form: FormGroup;
  value = '';
  constructor() { }

  ngOnInit(): void {
    this.errorMessages = {
      required: `Поле ${this.name} должно быть заполнено`
    }
  }


  onChange: any = () => { };
  onTouched: any = () => { };


  writeValue(value: string): void {
   
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    
  }

}
