import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { responceData } from 'app/models/responce.model';
import { Observable } from 'rxjs';
import { AppState } from 'app/store/app.state';
import { Store } from '@ngrx/store';
import { UserInfoPostProps, UserInfoProps } from '../models/userInfo.model';
import { environment } from '@environment/environment';


interface UserInfoResponceData extends Omit<responceData, 'data'> {
  data: UserInfoProps;
}
interface UserInfoPostResponceData extends Omit<responceData, 'data'> {
  data: UserInfoPostProps;
}

@Injectable({
  providedIn: 'root',
})
export class userinfoService {
  BASE_URL = `${environment.API_BASE_URL}`;
  constructor(private http: HttpClient, private store: Store<AppState>) {}

  getProfileData(token: string): Observable<UserInfoResponceData> {
    return this.http.get<responceData>(
      `${this.BASE_URL}/profile`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
  postProfileData(
    userInfoData: UserInfoPostProps,
    token: string
  ): Observable<UserInfoPostResponceData> {
    let uploadFiles;
    const formData = new FormData();
    // formData.append('userID', userInfoData.userID);
    // formData.append('countryId', userInfoData.countryId);
    // formData.append('regionId', userInfoData.regionId);
    // formData.append('cityId', userInfoData.cityId);
    // formData.append('zip', userInfoData.zip);
    // formData.append('street', userInfoData.street);
    // formData.append('email', userInfoData.email);
    // formData.append('dateOfBirth', userInfoData.dateOfBirth);
    /*this.store
      .select((state) => state.shared)
      .subscribe((data) => {
        uploadFiles = data.newProfileImage;
        formData.append('file', data.newProfileImage);
      });*/

    // let data = Object.fromEntries(Object.entries(userInfoData).filter(([_, v]) => v != null && v != ""));

    return this.http.put<UserInfoPostResponceData>(
      `${this.BASE_URL}/profile/update`,
      // data,
      userInfoData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
  postProfileDataImage(
    userInfoData: UserInfoPostProps,
    token: string
  ): Observable<UserInfoPostResponceData> {
    let uploadFiles;
    const formData = new FormData();
    // formData.append('countryId', userInfoData.countryId);
    // formData.append('regionId', userInfoData.regionId);
    // formData.append('cityId', userInfoData.cityId);
    // formData.append('zip', userInfoData.zip);
    // formData.append('street', userInfoData.street);
    // formData.append('email', userInfoData.email);
    // formData.append('dateOfBirth', userInfoData.dateOfBirth);
    this.store
      .select((state) => state.shared)
      .subscribe((data) => {
        uploadFiles = data.newProfileImage;
        formData.append('file', data.newProfileImage);
      });

    return this.http.put<UserInfoPostResponceData>(
      `${this.BASE_URL}/profile/update`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}
