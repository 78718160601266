import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { AppState } from 'app/store/app.state';
import { Store } from '@ngrx/store';
import {
  setClickedSubscription,
  setRightSidebarSignIn,
  setRightSidebarSignInOptions,
} from 'app/store/Shared/shared.actions';
import { signInRightSidebarProps } from 'app/store/Shared/shared.state';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'TransactionsPage',
  templateUrl: './ui/TransactionsPage.component.html',
  styleUrls: ['./ui/TransactionsPage.component.scss'],
})
export class TransactionsPage {
  @ViewChild('subscriptionBtn') subscriptionBtn: ElementRef;

  temporalState: boolean = false;
  clicked: string | number = 1;
  sidebarOptions: signInRightSidebarProps[];
  sidebarOptionsSelected: string[];
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private renderer: Renderer2,
    private translateService: TranslateService
  ) {
    this.router.events.subscribe((val) => {
      if (this.router.url !== '/transactions' && val instanceof NavigationEnd) {
        this.store.dispatch(setRightSidebarSignIn({ props: null }));
      }
    });
    this.renderer.listen('window', 'click', (e: Event) => {
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      e.stopPropagation();
      if (e.target) {
        const event = e.target as HTMLElement;
        const elements2 = document.getElementsByClassName(
          'subscription-button'
        );
        let b = 0;
        for (let i = 0; i < elements2.length; i++) {
          if (!elements2[i].contains(event)) {
            b += 1;
          }
        }

        console.log(b, elements2.length);
        if (b == elements2.length) {
          console.log('nuuuuje');
          this.clicked = '';
          this.store.dispatch(
            setClickedSubscription({
              options: null,
            })
          );
        }
      }
    });
  }

  options: signInRightSidebarProps[] = [
    {
      title: '1.title',
      options: ['1.subscriptions', '1.transactions'],
    },
    {
      title: '2.title',
      options: ['2.Apple Inc', '2.Google Inc', '2.Occidental Petroleum Inc'],
    },
  ];
  ngOnInit() {
    this.store.dispatch(setRightSidebarSignIn({ props: this.options }));
    this.store
      .select((state) => state.shared)
      .subscribe((state) => {
        if (state.signInRightSidebar) {
          this.sidebarOptions = state.signInRightSidebar;
        }
      });
    this.store
      .select((state) => state.shared)
      .subscribe((state) => {
        if (state.signInRightSidebarSelected) {
          this.sidebarOptionsSelected = state.signInRightSidebarSelected;
        }
      });
  }

  setSidebarProps(index: number | string, option: string) {
    const arr = [...this.sidebarOptionsSelected];
    console.log(arr[Number(index)]);
    arr[Number(index)] = option;
    this.store.dispatch(setRightSidebarSignInOptions({ options: arr }));
  }

  onSubscrtionButtonClick = (val: string | number): void => {
    this.clicked = val;
    this.store.dispatch(
      setClickedSubscription({
        options: {
          title: val,
        },
      })
    );
  };

  setTemporalState(value: boolean) {
    if (value == false) {
      this.temporalState = true;
    }
    if (value == true) {
      this.temporalState = false;
      console.log(this.temporalState);
    }
  }
}
