import { registrationData } from './models/resetPwdData.model';

export interface ResetPwdState {
  login: string | null;
  loginErrorMessage: string | null;
  step: string | number;
  timer: boolean;
  time: number;
  resetPwd: boolean;
  token: string;
}

export const initialState: ResetPwdState = {
  login: null,
  loginErrorMessage: null,
  step: 0,
  timer: false,
  time: 60,
  resetPwd: false,
  token: '',
};
