import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar-button',
  templateUrl: './ui/sidebar-button.component.html',
  styleUrls: ['./ui/sidebar-button.component.scss'],
})
export class SidebarButtonComponent {
  @Input() name: string = '';
  @Input() amount: string = '';
  @Input() pic: string = '';
  @Input() hovered: boolean = false;
}
