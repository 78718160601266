<button
  class="subscription-button"
  [ngClass]="clicked ? 'subscription-button-clicked' : ''"
  (click)="onClick(title, $event)"
  #subscriptionBtn
>
  <app-status-sign-component
    [confirmedState]="true"
  ></app-status-sign-component>
  <div class="subscription-button__content">
    <p class="subscription-button__content__info">
      {{ "subscription-component.date" | translate }}: {{ payDate }}<br />{{
        "subscription-component.period" | translate
      }}: <br />{{ period }}
    </p>
    <p class="subscription-button__content__title">
      {{ title }}
    </p>
    <p class="subscription-button__content__amount">{{ amount }}</p>
  </div>
</button>
<button *ngIf="clicked" class="subscription-button-delete-mob">
  {{ "cancel-subscription" | translate }}
</button>
