import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RegistrationState } from './registration.state';

export const REGISTRATION_STATE_NAME = 'registration';

const getRegistrationState = createFeatureSelector<RegistrationState>(
  REGISTRATION_STATE_NAME
);

export const getRegistrationData = createSelector(
  getRegistrationState,
  (state) => {
    return state.registrationData;
  }
);
