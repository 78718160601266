import { AuthReducer } from 'app/providers/AppRouter/authState/auth.reducer';
import { AUTH_STATE_NAME } from 'app/providers/AppRouter/authState/auth.selector';
import { AuthState } from 'app/providers/AppRouter/authState/auth.state';
import { SHARED_STATE_NAME } from './Shared/shared.seleсtor';
import { SharedState } from './Shared/shared.state';
import { SharedReducer } from './Shared/shared.reducer';
import { REGISTRATION_STATE_NAME } from './Registration/registration.seleсtor';
import { RegistrationState } from './Registration/registration.state';
import { RegistrationReducer } from './Registration/registration.reducer';
import { RESET_PWD_STATE_NAME } from './ResetPwd/resetPwd.seleсtor';
import { ResetPwdReducer } from './ResetPwd/resetPwd.reducer';
import { ResetPwdState } from './ResetPwd/resetPwd.state';
import { USERINFO_STATE_NAME } from './UserInfo/userInfo.seleсtor';
import { UserInfoReducer } from './UserInfo/userInfo.reducer';
import { UserInfoState } from './UserInfo/userInfo.state';

export interface AppState {
  [AUTH_STATE_NAME]: AuthState;
  [SHARED_STATE_NAME]: SharedState;
  [REGISTRATION_STATE_NAME]: RegistrationState;
  [RESET_PWD_STATE_NAME]: ResetPwdState;
  [USERINFO_STATE_NAME]: UserInfoState;
}

export const appReducer = {
  [AUTH_STATE_NAME]: AuthReducer,
  [SHARED_STATE_NAME]: SharedReducer,
  [REGISTRATION_STATE_NAME]: RegistrationReducer,
  [RESET_PWD_STATE_NAME]: ResetPwdReducer,
  [USERINFO_STATE_NAME]: UserInfoReducer,
};
