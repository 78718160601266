import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-sign-component',
  templateUrl: './ui/statusSign.component.html',
  styleUrls: ['./ui/statusSign.component.scss'],
})
export class statusSign {
  @Input() confirmedState: boolean = false;
}
