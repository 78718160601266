export class registrationData {
  constructor(
    private name: string,
    private lastName: string,
    private phoneNumber: string | number
  ) {}
  get getPhoneNumber() {
    return this.phoneNumber;
  }
  get getName() {
    return this.name;
  }
  get getLastName() {
    return this.lastName;
  }
}
