<form action="" class="login-page" [formGroup]="loginForm">
  <h2 class="login-page__title">{{ "login.header" | translate }}</h2>
  <!-- user info container -->
  <app-form-user-login *ngIf="step == 0" formGroupName="LoginInfo">
  </app-form-user-login>
  <!-- user info container -->

  <!-- user code container -->
  <app-form-user-code *ngIf="step == 'codeReset'" formGroupName="codeInfo">
  </app-form-user-code>
  <!-- user code container -->

  <!-- user info button -->
  <button
    class="login-page__submit"
    *ngIf="step == 0"
    (click)="onSubmit()"
  >
    {{ "login.login-btn" | translate }}
  </button>
  <!-- user info button -->

  <div class="login-page__bottom">
    <p class="login-page__text">{{ "login.or-login-by" | translate }}</p>
    <div class="login-page__buttons">
      <button>
        <img src="assets/icons/google.svg" alt="googlePic" />
      </button>
      <button>
        <img src="assets/icons/apple.svg" alt="applePic" />
      </button>
      <button>
        <img src="assets/icons/facebook.svg" alt="facebookPic" />
      </button>
    </div>
  </div>
</form>
<p class="login-page-bottomLink text-link">
  <a
    [routerLink]="'/registration'"
    class="login-page-bottomLink__link"
    >{{ "login.bottom-text.reg" | translate }}</a
  >
  {{ "login.bottom-text.for-full-access" | translate }}
</p>

<app-modal-window
  [open]="showLoginModal"
  [title]="'alerts.login-ban.title' | translate"
  [text]="'alerts.login-ban.text' | translate"
  [okText]="'alerts.ok-btn' | translate"
  [okFunction]="closeModal"
></app-modal-window>