import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { fromEvent, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
} from 'rxjs/operators';
import { setQueryParams } from '@shared/helpers/set-query-headers';

@Component({
  selector: 'app-async-select-component',
  templateUrl: './ui/async-select.component.html',
  styleUrls: ['./ui/async-select.component.scss'],
})
export class AsyncSelectComponent implements OnInit, AfterViewInit, OnDestroy  {

  // @Input() parentForm: FormGroup;
  @Input() formControl: string;
  @Input() type: string;
  @Input() apiUrl: string;
  @Input() defParams: any;
  @Input() additionalParams: any;
  // @Input() itemId: string = "";
  @Input() title: string;

  @Output() onSelect = new EventEmitter<any>();
  dataSources: any = [];
  searchText: any;
  selectedRows: any = [];

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef | any;

  subs: Subscription[] = [];

  constructor(
    private http: HttpClient
  ) {}

  ngOnInit() {
    this._getItems();
    this._listenToSearchInput();
  }

  ngAfterViewInit() {
    this.searchInput.nativeElement.focus();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _listenToSearchInput() {
    this.subs.push(
      fromEvent(this.searchInput.nativeElement, 'keyup')
        .pipe(
          map((event: any) => event.target.value),
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe(() => this._getItems())
    );
  }

  private _getItems() {
    this.dataSources = null;
    let params = {
      ...this.defParams,
      ...this.additionalParams,
      name: this.searchText || ""
    }
    
    const url = `${environment.API_BASE_URL}/${this.apiUrl}/`;
    this.http.get(url, setQueryParams(params))
      .subscribe(({data}: any) => {
        this.dataSources = data;
      })
  }

  public isChecked(data: any) {
    return this.selectedRows.filter((e: any) => {
      e.id === data.id;
    }).length;
  }

  public onCheckboxChange(data: any) {
    if (this.isChecked(data)) {
      let ind = this.selectedRows.findIndex((e: any) => e.id === data.id);
      this.selectedRows.splice(ind, 1);
    } else {
      this.selectedRows.push(data);
    }
  }

  public goBack() {
    this.onSelect.emit(this.selectedRows);
  }

  public onRowSelected(data: any) {
    this.onSelect.emit(Object.assign(data, {rowSelected: true}));
  }
}
