import { state } from '@angular/animations';
import { ResetPwdState, initialState } from './resetPwd.state';
import { Action, createReducer, on } from '@ngrx/store';
import {
  ResetPwdLoginSuccess,
  resetPwdSendCodeSuccess,
  setResetPwdLogin,
  setResetPwdLoginErrorMessage,
  setResetPwdTimerAction,
} from './resetPwd.actions';

const _resetPwdReducer = createReducer(
  initialState,
  on(setResetPwdLogin, (state, action) => {
    return {
      ...state,
      login: action.login,
    };
  }),
  on(setResetPwdLoginErrorMessage, (state, action) => {
    return {
      ...state,
      loginErrorMessage: action.message,
    };
  }),
  on(ResetPwdLoginSuccess, (state, action) => {
    return {
      ...state,
      step: 1,
    };
  }),
  on(resetPwdSendCodeSuccess, (state, action) => {
    return {
      ...state,
      step: 1,
      resetPwd: true,
      token: action.token
    };
  }),
  on(setResetPwdTimerAction, (state, action) => {
    return {
      ...state,
      timer: action.timeStart,
      time: action.time,
    };
  })
);

export function ResetPwdReducer(
  state: ResetPwdState | undefined,
  action: Action
) {
  return _resetPwdReducer(state, action);
}
