import { Component, Input, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { AppState } from 'app/store/app.state';
import { Store } from '@ngrx/store';
import { getLoginErrorMessage } from 'app/store/Shared/shared.seleсtor';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'app/services/translate-config.service';

@Component({
  selector: 'app-modal-window',
  templateUrl: './ui/modal-window.component.html',
  styleUrls: ['./ui/modal-window.component.scss'],
})
export class ModalWindowComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() text: string;
  @Input() okFunction: () => void;
  @Input() cancelFunction: any;
  @Input() okText: string;
  @Input() cancelText: string | undefined;
  @Input() isMessage: boolean = true;
  @Input() formHex: string;

  @Input() open: boolean = false;

  convertedHex: any;
  @ViewChild('formDiv') formDiv: ElementRef;

  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (!this.isMessage) {
      this.convertedHex = atob(this.formHex) || '';
      setTimeout(() => this.formDiv.nativeElement.innerHTML = this.convertedHex, 200);
      console.log(this.convertedHex);
    }
  }

  setOpen(value: boolean) {
    if (value) {
      this.open = false;
    }
    if (!value) {
      this.open = true;
    }
  }
}
