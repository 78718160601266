import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { AppState } from 'app/store/app.state';
import { Store } from '@ngrx/store';
import { getLoginErrorMessage } from 'app/store/Shared/shared.seleсtor';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'app/services/translate-config.service';

@Component({
  selector: 'app-form-user-login',
  templateUrl: './ui/form-user-login.component.html',
  styleUrls: ['./ui/form-user-login.component.scss'],
})
export class FormUserLoginComponent implements OnInit {
  Object: Object;

  @Input() formGroupName: string;

  form: FormGroup;
  inputs: string[];
  pwdVisible: boolean = false;
  loginErrorMessage: string | boolean;
  constructor(
    private rootFormGroup: FormGroupDirective,
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.store.select(getLoginErrorMessage).subscribe((value) => {
      this.loginErrorMessage = value;
    });
    this.inputs = Object.keys(this.form.controls);
    console.log(this.loginErrorMessage);
  }

  setPwdVisibility = (): void => {
    this.pwdVisible = !this.pwdVisible;
  };

  public plusHandler() {
    let phoneNumber = this.form.get('login')!.value;

    if (phoneNumber === '') {
      this.form.get('login')!.setValue('+');
    } else if (!phoneNumber.includes('+')) {
      this.form.get('login')!.setValue('+' + phoneNumber);
    }
  }
}
