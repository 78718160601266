import { User } from 'app/models/user.model';

export interface AuthState {
  user: User | null;
  passwordReset: boolean;
}

export const initialState: AuthState = {
  user: null,
  passwordReset: true,
};
