<div class="login-form" [formGroup]="form">
  <div class="login-form__input-box">
    <p class="login-form__input-box__label">
      {{ "login.login-step.your-login" | translate }}
    </p>
    <input
      type="text"
      class="login-form__input-box__input"
      [ngClass]="
          form.get('login')?.invalid || loginErrorMessage
          ? 'login-form__input-box__input-invalid'
          : login.value !== ''
          ? 'login-form__input-box__input-valid'
          : ''
      "
      placeholder="Login"
      formControlName="login"
      (keyup)="plusHandler()"
      #login
    />
    <p
      *ngIf="form.get('login')?.errors?.pattern"
      class="login-form__input-box__error"
    >
      {{ "login.login-step.errors.login" | translate }}
    </p>
    <p
      *ngIf="form.get('login')?.errors?.required"
      class="login-form__input-box__error"
    >
      {{ "login.login-step.errors.empty" | translate }}
    </p>
  </div>
  <div class="login-form__input-box">
    <label class="login-form__input-box__label">{{
      "login.login-step.your-password" | translate
    }}</label>
    <div class="login-form__input-box__pwd-box">
      <input
        autocomplete="off"
        [type]="pwdVisible ? 'text' : 'password'"
        class="login-form__input-box__input"
        [ngClass]="
          form.get('password')?.invalid || loginErrorMessage
            ? 'login-form__input-box__input-invalid'
            : password.value !== ''
            ? 'login-form__input-box__input-valid'
            : ''
        "
        placeholder="Password"
        formControlName="password"
        #password
      />
      <button
        (click)="setPwdVisibility()"
        class="login-form__input-box__pwd-setter"
        [ngClass]="pwdVisible ? '' : 'login-form__input-box__pwd-setter-hide'"
      >
        <img src="assets/icons/eye.svg" alt="" />
      </button>
    </div>
    <p
      *ngIf="form.get('password')?.errors?.pattern"
      class="login-form__input-box__error"
    >
      {{ "login.login-step.errors.pwd" | translate }}
    </p>
    <p
      *ngIf="form.get('password')?.errors?.required"
      class="login-form__input-box__error"
    >
      {{ "login.login-step.errors.empty" | translate }}
    </p>
    <p *ngIf="loginErrorMessage" class="login-form__input-box__error">
      {{ "login.login-step.errors.request" | translate }}
    </p>
  </div>
  <p class="login-form-bottomLink text-link">
    <a [routerLink]="'/reset-pwd'" class="login-form-bottomLink__link">{{
      "login.login-step.forgot-pwd" | translate
    }}</a>
  </p>
</div>
