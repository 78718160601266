import { FormArray, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';

export function setValidationRequired(
  form: FormGroup<any>,
  groupName?: string,
  numberError?: string | null
): void {
  if (groupName) {
    const userInfo = form.get(groupName) as FormArray;
    console.log(numberError);
    userInfo !== null
      ? Object.keys(userInfo.controls).forEach((key) => {
          let userInfoItem = userInfo.get(key);
          userInfoItem?.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
              if (value == '' || value == null) {
                userInfoItem?.addValidators(Validators.required);
              } else {
                userInfoItem?.removeValidators(Validators.required);
              }
              userInfoItem?.updateValueAndValidity();
            });
        })
      : '';
  }
}

export function setAllValidationRequired(
  form: FormGroup<any>,
  groupName: string,
  error: boolean
) {
  const formInfo = form.get(groupName) as FormArray;
  Object.keys(formInfo.controls).forEach((key) => {
    let formItem = formInfo.get(key);
    if (error) {
      formItem?.addValidators(Validators.required);
    } else {
      formItem?.removeValidators(Validators.required);
    }
    formItem?.updateValueAndValidity();
    console.log(formItem);
  });
}
