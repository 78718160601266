import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserInfoState } from './userInfo.state';

export const USERINFO_STATE_NAME = 'userinfo';

const getUserInfoState =
  createFeatureSelector<UserInfoState>(USERINFO_STATE_NAME);

export const getUserInfoData = createSelector(getUserInfoState, (state) => {
  return state.UserInfoData;
});
