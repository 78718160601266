<div *ngIf="!myCompanies.length && !loading" class="no-companies-container">
  <div class="title">
    {{ 'my-companies.title' | translate }}
  </div>

  <div class="is-full-page">
    <div class="no-items-found">
      <img src="assets/icons/add-icon-48px.svg" style="cursor: pointer;" (click)="addCompanyClick()"/>
      <div class="title">{{ 'my-companies.add-company' | translate }}</div>
    </div>
  </div>

</div>

<div *ngIf="myCompanies.length && !loading" class="my-companies-container">
  <div class="title" style="margin-bottom: 30px;">
    {{ 'my-companies.title' | translate }}
  </div>

  <div class="grid">
    <div *ngFor="let company of myCompanies; index as i" class="company-container">
      <div class="inner-company-container">
        <div class="company-title" (click)="goToCompany(company.id)">{{ company.titleVariants[0].title }} {{ company.titleVariants[0].prefix }}</div>
        <div *ngIf="company.state === 4" class="subscription-container">
          <div class="subscription-text">{{ 'my-companies.renewal-date' | translate }}: {{ (getRenewalDate(company.createdAt)) | date }}</div>
          <div class="subscription-text">{{ 'my-companies.renewal-cost' | translate }}: {{ company.renewalCost }} {{company.currency }}</div>
        </div>
        <div *ngIf="company.state === 3" class="loading-pink-container">
          <p class="state-title">{{ "my-orders.states." + company.state | translate }}</p>
          <div class="loading-pink">
            <div
              class="loading-pink__bar"
              [style]="{ width: company.state * 25 + '%' }"
            ></div>
          </div>
        </div>

        <div class="block-of-2">
          <div class="block">
            <div class="title">{{ 'my-companies.country' | translate }}</div>
            <div class="value">{{ getCountryById(company.countryId) }}</div>
          </div>
          <div class="block">
            <div class="title">{{ 'my-companies.form' | translate }}</div>
            <div class="value">{{ getFormNameById(company.legalFormId) }}</div>
          </div>
        </div>

        <div class="block-of-2">
          <div class="block">
            <div class="title">{{ 'my-companies.directors' | translate }}</div>
            <div class="value">{{ company.directorsCount }}</div>
          </div>
          <div class="block">
            <div class="title">{{ 'my-companies.founders' | translate }}</div>
            <div class="value">{{ company.shareholdersCount }}</div>
          </div>
        </div>

        <div class="block-of-2">
          <div class="block">
            <div class="title">{{ 'my-companies.reg-number' | translate }}</div>
            <div class="value long-value">{{ company.id }}</div>
          </div>
          <div class="block">
            <div class="title">{{ 'my-companies.reg-date' | translate }}</div>
            <div class="value">{{ company.createdAt | date }}</div>
          </div>
        </div>

        <app-capital-slider-component
          [amount]="amount[i]"
          [minValue]="getAmount('min', company.legalFormId)"
          [maxValue]="getAmount('max', company.legalFormId)"
          [currency]="company.currency"
        ></app-capital-slider-component>

        <button *ngIf="company.state === 4" class="pay-button">
          {{ 'my-companies.pay-for-renewal' | translate }}
        </button>

        <button 
          *ngIf="company.state === 4"
          class="download-button"
          [disabled]="!company.foundingDocumentsUrl"
          (click)="downloadFiles(company.foundingDocumentsUrl)"
        >
          {{ 'my-companies.download-documents' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>