import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthResponceData } from 'app/models/AuthResponseData.model';
import { responceData } from 'app/models/responce.model';
import { User } from 'app/models/user.model';
import { getToken } from 'app/providers/AppRouter/authState/auth.selector';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  BASE_URL = `${environment.API_BASE_URL}`;
  constructor(private http: HttpClient) {}

  logoutDid() {
    localStorage.removeItem('firmderWebUser');
  }
  login(phoneNumber: string, password: string): Observable<responceData> {
    return this.http.post<responceData>(
      `${this.BASE_URL}/login`,
      { phoneNumber, password }
    );
  }

  logout(userId: string): Observable<responceData> {
    return this.http.post<responceData>(
      `${this.BASE_URL}/logout`,
      { userID: userId }
    );
  }

  formatUser(data: AuthResponceData) {
    const user = new User(data.token, data.user);
    return user;
  }

  getErrorMessage(message: string) {
    return message;
  }

  getErrorCode(code: number) {
    return code;
  }

  setUserInLocalStorage(user: User) {
    localStorage.setItem('firmderWebUser', JSON.stringify(user));
  }

  getUserFromLocalStorage() {
    const userDataString = localStorage.getItem('firmderWebUser');
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      const user = new User(userData.token, userData.user);
      return user;
    }
    return null;
  }

  checkToken(): Observable<responceData> {
    return this.http.get<responceData>(
      `${this.BASE_URL}/check`,
      {}
    );
  }
}
