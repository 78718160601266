import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateConfigService } from 'app/services/translate-config.service';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { setLanguage } from 'app/store/Shared/shared.actions';

export enum Languages {
  EN = 'en',
  DE = 'de',
}

@Component({
  selector: 'langSwitcher',
  templateUrl: './ui/LangSwitcher.component.html',
  styleUrls: ['./ui/LangSwitcher.component.scss'],
})
export class langSwitcherComponent {
  @Input() maxWidth: boolean = false;
  @Input() signIn: boolean = false;
  @Input() onlyMob: boolean = false;
  language: string = Languages.DE;
  allLanguages: string[] = [Languages.DE, Languages.EN];
  localesList = [
    { code: Languages.EN, label: 'en' },
    { code: Languages.DE, label: 'de' },
  ];

  constructor(
    private router: Router,
    private translateConfigService: TranslateConfigService,
    private store: Store<AppState>
  ) {
    this.getScreenSize();
  }
  loadingScr: boolean = true;
  screenHeight: number;
  screenWidth: number;

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnInit() {
    const lang = JSON.parse(
      localStorage.getItem('FirmderWebsiteLanguage') || '{}'
    );

    if (lang.length > 0) {
      this.translateConfigService.changeLanguage(lang);
      this.store.dispatch(setLanguage({ lang: lang }));
    } else {
      this.translateConfigService.changeLanguage('en');
      this.store.dispatch(setLanguage({ lang: 'en' }));
    }

    this.store
      .select((state) => state.shared)
      .subscribe((state) => {
        if (state.lang) {
          this.language = state.lang;
        }
      });
  }

  changeLang(lang: string): void {
    console.log(lang);
    this.store.dispatch(setLanguage({ lang: lang }));
    this.translateConfigService.changeLanguage(lang);
    localStorage.setItem('FirmderWebsiteLanguage', JSON.stringify(lang));
  }
  changeMob(): void {
    let key = 0;
    this.allLanguages.map((lang, index) => {
      if (this.language == lang) {
        key = index + 1;
      }
    });
    if (key == this.allLanguages.length) {
      this.store.dispatch(setLanguage({ lang: this.allLanguages[0] }));
      this.translateConfigService.changeLanguage(this.allLanguages[0]);
      localStorage.setItem(
        'FirmderWebsiteLanguage',
        JSON.stringify(this.allLanguages[0])
      );
    } else {
      this.store.dispatch(setLanguage({ lang: this.allLanguages[key] }));
      this.translateConfigService.changeLanguage(this.allLanguages[key]);
      localStorage.setItem(
        'FirmderWebsiteLanguage',
        JSON.stringify(this.allLanguages[key])
      );
    }
    console.log(this.language);
  }
}
