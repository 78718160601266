import { OnInit, Component, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HostListener } from '@angular/core';
import { RouteConfig } from 'shared/config/routeConfig/routeConfig';
import { SharedService } from 'app/store/Shared/services/SharedServices';
import {
  deletePaymentMethod,
  setDeletePaymentMethodModal,
  setLoadingSpinner,
} from 'app/store/Shared/shared.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map } from 'rxjs';

@Component({
  selector: 'AddPaymentMethodPage',
  templateUrl: './ui/AddPaymentMethodPage.component.html',
  styleUrls: ['./ui/AddPaymentMethodPage.component.scss'],
})
export class AddPaymentMethodPage implements OnInit {
  cardsData: any; //cards data not used now
  defaultCard: number;
  loading: boolean = false;

  deleteWindowOpen: boolean = false;
  deleteCardId: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private sharedService: SharedService,
  ) {}

  ngOnInit() {
    this._getPaymentMethods();
    this.store
      .select((state) => state.shared.ModalWindowDeleteCard)
      .subscribe((modal) => {
        this.deleteWindowOpen = modal.open;
        this.deleteCardId = modal.card;
      });

    this.store
      .select((state) => state.shared.ModalWindowDeleteCard)
      .subscribe(async (modal) => {
        this.deleteWindowOpen = modal.open;
        this.deleteCardId = modal.card;
      });
  }

  navigateToAddCard() {
    if (RouteConfig['add-card'].path) {
      this.router.navigate([`account/add-card/`]);
    }
  }

  public deletePaymentMethod() {
    const deleting = this.store
      .select((state) => state.shared.ModalWindowDeleteCard)
      .subscribe((modal) => {
        if (modal.open) {
          this.store.dispatch(
            setDeletePaymentMethodModal({ open: false, card: '' })
          );
          this.store.dispatch(deletePaymentMethod({ id: modal.card }));
        }
        return;
      });
    deleting.unsubscribe();
  }

  public _getPaymentMethods() {
    console.log('payment');

    this.sharedService.getPaymentMethods().subscribe(({ data }) => {
      console.log('baba');

      this.cardsData = data.filter((e: any) => e.paymentMetaData.brand);
      this.cardsData.map(
        (e: any) =>
          (e.paymentMetaData.lastDigits = e.paymentMetaData.lastDigits.replace(
            /^.{2}/g,
            '**'
          ))
      );
      this.defaultCard = this.cardsData.findIndex((e: any) => {
        return e.isDefault;
      });
    });
  }

  public openDeleteWindow(id: string) {
    this.store.dispatch(setDeletePaymentMethodModal({ open: true, card: id }));
  }
  public closeDeleteWindow() {
    this.store.dispatch(setDeletePaymentMethodModal({ open: false, card: '' }));
  }
  public onDefaultChange(i: number) {
    this.loading = true;
    this.store.dispatch(setLoadingSpinner({ status: true }));
    this.sharedService
      .updatePaymentMethods(
        { isDefault: true },
        this.cardsData[i].paymentMethodId
      )
      .subscribe(
        (data: any) => {
          this.defaultCard = i;
          this.loading = false;
          this.store.dispatch(setLoadingSpinner({ status: false }));
        },
        (error: any) => {
          this.store.dispatch(setLoadingSpinner({ status: false }));
          console.log(error);
          this.loading = false;
        }
      );
  }
}
