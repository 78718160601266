import {
  CityData,
  CompanyData,
  CompanyDraftData,
  CompanyDraftResponceData,
  CompanyResponceData,
  CountryData,
  MacroregionData,
  PaymentMethodData,
  RegionData,
  SubscriptionOptions,
  companyOption,
} from './model/SharedModel';

export type signInRightSidebarProps = {
  title: string;
  options: string[];
};

export interface SharedState {
  showLoading: boolean;
  errorMessage: string | boolean;
  logoutMessage: string | boolean;
  errorCode: number;
  signInRightSidebar?: signInRightSidebarProps[] | null;
  signInRightSidebarSelected?: string[];
  clickedSubscription?: SubscriptionOptions | null;
  newProfileImage?: any;

  /*LOCATIONS*/
  countries?: CountryData[];
  countriesByMacro?: CountryData[];
  regions?: RegionData[];
  macroregions?: MacroregionData[];
  cities?: CityData[];
  citiesByCoutry?: CityData[];
  /*LOCATIONS*/

  /*PAYMENT METHODS*/
  paymentMethods: PaymentMethodData[];
  /*PAYMENT METHODS*/

  lang?: string;
  timer: boolean;
  time: number;
  confirmMailError?: string;

  saveBtnCabinetInfo: boolean;
  ButtonsUserInfoPage: boolean;
  SaveButtonUserInfoPage: boolean;
  ordersButtons: string | null;

  //windows
  ModalWindowUserInfoSave?: boolean | null;
  ModalWindowConfirmMail?: boolean | null;
  ModalWindowLogoutButton?: boolean | null;
  ModalWindowDeleteCard: {
    open: boolean;
    card: string;
  };
  ModalWindowDeleteOrder: {
    open: boolean;
    order: string;
  };
  //windows

  //add company
  addCompanyStep: number;
  companyShares: number;
  addCompanyStepShow: boolean;
  editableCompanyStep?: string;
  companyDraftState?: CompanyData | null;
  companyOptions?: companyOption[] | null;
  currentCompanyToUpdate?: CompanyResponceData[] | null;
  //add company

  /* REQUESTS*/
  requestError?: string | null;
  requestSuccess?: string | null;
  currentTemplateId?: string | null;
}

export const initialState: SharedState = {
  showLoading: false,
  errorMessage: false,
  logoutMessage: false,
  errorCode: 0,
  timer: false,
  time: 5,
  saveBtnCabinetInfo: false,
  ButtonsUserInfoPage: false,
  ordersButtons: null,

  //add company
  addCompanyStep: 1,
  SaveButtonUserInfoPage: false,

  paymentMethods: [],

  ModalWindowDeleteCard: {
    open: false,
    card: '',
  },
  ModalWindowDeleteOrder: {
    open: false,
    order: '',
  },

  companyShares: 100,
  addCompanyStepShow: false,
  currentTemplateId: null,
};
