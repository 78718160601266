import { Component, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

@Component({
  selector: 'add-company-widget',
  templateUrl: './ui/add-company-widget.component.html',
  styleUrls: ['./ui/add-company-widget.component.scss'],
})
export class AddCompanyWidget {
  @Input() status: string | number = '';
  @Input() statusColor: string = '';

  @Input() title: string = '';
  @Input() info: string = '';
  @Input() pic: string = '';
  @Input() plus: boolean = false;
  @Input() callBackFunk: any;
  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    console.log('status', this.status);
  }
}
