import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from 'app/store/Shared/services/SharedServices';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { AppState } from 'app/store/app.state';
import { Subscription } from 'rxjs';

import {
  getOrderList,
  getOrderListSuccess
} from 'app/store/Shared/shared.actions';

@Component({
  selector: 'app-my-orders-edit',
  templateUrl: './ui/MyOrdersEdit.component.html',
  styleUrls: ['./ui/MyOrdersEdit.component.scss'],
})
export class MyOrdersEdit {
  data: any;
  countries: any;
  amount: any;
  minAmount: number = 1;
  maxAmount: number = 1000000;
  subs: Subscription[] = [];
  orderId: string;
  loading: boolean = false;

  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    if (this.route.snapshot.queryParams['id']) {
      this.orderId = this.route.snapshot.queryParams['id'];
      this._getCompanyInfo(this.orderId);
      this._getCountries();
    } else {
      this.router.navigate([`account/my-orders/`]);
    }
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _getCompanyOptions(id: string, legal: string) {
    this.sharedService.getCompanyOptionById(id)
      .subscribe(({data}: any) => {
        let lf = data.legalForms.find((e: any) => e.legalFormId === legal);
        if (lf) {
          this.minAmount = lf.authorizedCapital.minSumm;
          this.maxAmount = lf.authorizedCapital.maxSumm;
        }
      });
  }

  private _getCompanyInfo(id: string) {
    this.sharedService.getOrderById(id)
      .subscribe(({data}) => {
        this.data = data;
        this._foundersToOneArray();
        this.amount = data.authorizedCapital;
        this._getCompanyOptions(data.registrationOptionId, data.legalFormId);
      });
  }

  private _getCountries() {
    this.sharedService.getAllCountries()
      .subscribe(({data}) => {
        this.countries = data;
      })
  }

  public getCountryName(id: string) {
    if (this.countries) {
      let country = this.countries.find((e: any) => e.id === id);
      return country ? country.title : "Unknown"
    }
  }

  public setDynamicPosition() {
    let shift = this.amount / (this.maxAmount - this.minAmount / 100) * 100;
    // let val = `calc(${shift}% + 100px - (${shift}px)`; //top magic
    let val = `calc(${shift * 1.3}% + 140px - (${shift * 1.1}px)`; //bottom magic
    return {
      'left': val
    };
  }

  public onContinueButtonClick() {
    // this.loading = true;
    // this.sharedService.updateTemplate({authorizedCapital: this.amount}, this.orderId)
    //   .subscribe((data: any) => {
    //     this.router.navigate([`account/order-to-registration/`], {queryParams: {id: this.orderId}});
    //     this.loading = false;
    //   })

    this.router.navigate([`account/order-to-registration/`], {queryParams: {id: this.orderId}});
  }

  private _foundersToOneArray() {
    let array = [];
    for (let i = 0; i < this.data.shareholdersCount; i++) {
      if (this.data.shareholderCompany[i].share > 0) {
        array.push({...this.data.shareholderCompany[i], company: true});
      } else {
        array.push({...this.data.shareholderPhysical[i], company: false});
      }
    }
    this.data = {...this.data, founders: array};
  }
}
