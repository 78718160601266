import { createAction, props } from '@ngrx/store';
import { User } from 'app/models/user.model';

export const LOGIN_START = '[auth page] login start';
export const LOGIN_SUCCESS = '[auth page] login success';
export const LOGIN_FAILURE = '[auth page] login failure';
export const LOGIN_FAIL = '[auth page] login fail';
export const LOGOUT_ACTION = '[auth page] logout action';
export const LOGOUT_SUCCESS = '[auth page] logout success';
export const SET_IS_RESET_PASSWORD = '[auth page] set is reset password';

export const loginStart = createAction(
  LOGIN_START,
  props<{ phoneNumber: string; password: string }>()
);

export const loginSuccess = createAction(
  LOGIN_SUCCESS,
  props<{ user: User | null }>()
);

export const loginFailure = createAction(
  LOGIN_FAILURE,
  props<{ errorRes: any }>()
);

export const autoLogout = createAction(
  LOGOUT_ACTION,
  props<{ userId: string }>()
);

export const logoutSuccess = createAction(
  LOGOUT_SUCCESS,
  props<{ userId: string }>()
);

export const setPasswordReset = createAction(
  SET_IS_RESET_PASSWORD,
  props<{ isReset: boolean }>()
);
