import { createAction, props } from '@ngrx/store';
import { registrationData } from './models/resetPwdData.model';

export const SET_RESET_PWD_LOGIN_ACTION =
  '[resetPwd state] set resetPwd phone/login';
export const SET_RESET_PWD_LOGIN_SUCCESS_ACTION =
  '[resetPwd state] on phone/login success';
export const SET_RESET_PWD_LOGIN_ERROR_ACTION =
  '[resetPwd state] set resetPwd login err message';
export const RESET_PWD_SEND_CODE_ACTION =
  '[resetPwd state] send code verefication';
export const RESET_PWD_SEND_CODE_SUCCESS_ACTION =
  '[resetPwd state] send code verefication success';
export const SET_RESET_PWD_TIMER = '[resetPwd state] set resetpwd timer';
export const RESET_PWD_RESET = '[resetPwd state] resetpwd RESET request';
export const RESET_PWD_RESET_SUCCESS =
  '[resetPwd state] resetpwd RESET request Success';

export const setResetPwdLogin = createAction(
  SET_RESET_PWD_LOGIN_ACTION,
  props<{ login: string }>()
);
export const ResetPwdLoginSuccess = createAction(
  SET_RESET_PWD_LOGIN_SUCCESS_ACTION
);
export const setResetPwdLoginErrorMessage = createAction(
  SET_RESET_PWD_LOGIN_ERROR_ACTION,
  props<{ message: string }>()
);

export const resetPwdSendCode = createAction(
  RESET_PWD_SEND_CODE_ACTION,
  props<{ code: string; phoneNumber: string }>()
);
export const resetPwdSendCodeSuccess = createAction(
  RESET_PWD_SEND_CODE_SUCCESS_ACTION,
  props<{ token: string }>()
);

export const setResetPwdTimerAction = createAction(
  SET_RESET_PWD_TIMER,
  props<{ timeStart: boolean; time: number }>()
);
export const ResetPwdReset = createAction(
  RESET_PWD_RESET,
  props<{ password: string }>()
);
export const ResetPwdResetSuccess = createAction(
  RESET_PWD_RESET_SUCCESS,
  props<{ id: string }>()
);
