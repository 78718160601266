<div
  class="add-company-jurisdiction"
  style="max-width: 704px"
  [formGroup]="jurisdictionForm"
>
  <div class="add-company-title-container">
    <div
      class="add-company-title-container__nav"
      *ngIf="editable !== 'jurisdiction' && editable2 !== false"
    >
      <button class="add-company-title-container__next" (click)="nextStep()" [disabled]="!continueButton">
        <img src="assets/icons/arrow-left.svg" alt="arrow-left-button" />
      </button>
    </div>
    <h2 class="add-company-title">
      {{ "add-company-page.structure.jurisdictional-information" | translate }}
      <br />
    </h2>
  </div>
  <div class="add-company-step">
    <div class="add-company-step__text">0/5</div>
  </div>
  <ng-content select="[loading]"></ng-content>
  <div class="add-company-jurisdiction__content">
    <div class="add-company-jurisdiction__active-dropdown">
      <app-dropdown-type-1
        [name]="
              jurisdictionForm.value.region
                ? getMacroRegionName(jurisdictionForm.value.region)
                : ('region' | translate)
            "
        [nameDefault]="'region' | translate"
        [elements]="macroregions"
        [size]="'100%'"
        [color]="'#262A34'"
        (setItemFromButton)="setRegion($event)"
        (settingDropdownOpen)="setDropdown($event)"
        [dropdownOpen]="dropdown == '1' ? true : false"
        [dropdown]="'1'"
      />
    </div>
    <div
      [ngClass]=" jurisdictionForm.value.region ?'add-company-jurisdiction__active-dropdown':'add-company-jurisdiction__non-active-dropdown'"
    >
      <app-dropdown-type-1
        [name]="
              jurisdictionForm.value.country
                ? getCountryName(jurisdictionForm.value.country)
                : ('add-company-page.address.country' | translate)
            "
        [nameDefault]="'add-company-page.address.country' | translate"
        [elements]="filteredCountries"
        [size]="'100%'"
        [color]="'#262A34'"
        (setItemFromButton)="setCountry($event)"
        (settingDropdownOpen)="setDropdown($event)"
        [dropdownOpen]="dropdown == '2' ? true : false"
        [dropdown]="'2'"
        [disabled]="jurisdictionForm.value.region === ''"
      />
    </div>
  </div>

  <ng-content select="[price]"></ng-content>
  <button
    class="Button-blue add-company-page__submit-nonActive"
    [ngClass]="continueButton ? 'add-company-page__submit' : ''"
    (click)="submitStructure()"
    [disabled]="!continueButton"
  >
    {{'continue-btn' | translate}}
  </button>
</div>
