<p class="app-rights text-link" [ngClass]="addClass" translate="">
  rights.header.start
  <a
    href="javascript:void(null);"
    (click)="openDialog()"
    class="app-rights__link"
    translate
    >rights.header.conditions</a
  >
  rights.header.and
  <a
    href="javascript:void(null);"
    (click)="openDialog()"
    class="app-rights__link"
    translate
    >rights.header.privacy-policy</a
  >
</p>
