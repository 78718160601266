import { Component } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AppState } from 'app/store/app.state';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import {
  RegistrationPasswordSucess,
  registrationSuccess,
} from 'app/store/Registration/registration.actions';
import { getRegistrationData } from 'app/store/Registration/registration.seleсtor';
import { ResetPwdReset } from 'app/store/ResetPwd/resetPwd.actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-password-page',
  templateUrl: './ui/new-password-page.component.html',
  styleUrls: ['./ui/new-password-page.component.scss'],
})
export class NewPasswordPageComponent {
  newPwdForm: FormGroup;
  step: number = 0;
  word: string;
  submitActive = false;
  isResetPwd: boolean = false;

  pwdValid = {
    symbols: false,
    capital: false,
    smallLett: false,
    numbers: false,
    specialSymbol: false,
  };

  showModal: boolean = false;

  subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private actions: Actions,
    private router: Router,
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {
    //this.userForm.valueChanges.subscribe(value => console.log(value));
  }

  pwdValidation(
    Pwd: AbstractControl<string, any> | null | undefined,
    RepeatedPwd: AbstractControl<string, any> | null | undefined
  ) {
    //надо исправить что когда меняем пароль сверху и все правильно - г выскаивает
    if (
      this.pwdValid.symbols == true &&
      this.pwdValid.capital == true &&
      this.pwdValid.smallLett == true &&
      this.pwdValid.numbers == true &&
      this.pwdValid.specialSymbol == true
    ) {
      Pwd?.setErrors(null);
      if (RepeatedPwd?.value !== Pwd?.value) {
        this.submitActive = false;
        RepeatedPwd?.setErrors({ incorrect: true });
      } else {
        this.submitActive = true;
        RepeatedPwd?.setErrors(null);
        RepeatedPwd?.updateValueAndValidity();
      }
    } else {
      Pwd?.setErrors({ incorrect: true });
    }
  }

  ngOnInit(): void {
    this.newPwdForm = this.fb.group({
      passwords: this.fb.group({
        password: this.fb.control(''),
        repeatedPwd: this.fb.control(''),
      }),
    });

    const RepeatedPwd = this.newPwdForm.get('passwords')?.get('repeatedPwd');

    const Pwd = this.newPwdForm.get('passwords')?.get('password');
    Pwd?.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      this.pwdValid.symbols = value && value.length >= '8' && value.length < 20;
      this.pwdValid.capital = /^(?=.*[A-Z])/.test(value);
      this.pwdValid.smallLett = /^(?=.*[a-z])/.test(value);
      this.pwdValid.numbers = /^(?=.*[0-9])/.test(value);
      this.pwdValid.specialSymbol = /[\^(?=.*[!@#$%^&*\])]/.test(value); //password symbol

      this.pwdValidation(Pwd, RepeatedPwd);
    });

    RepeatedPwd?.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        if (value !== Pwd?.value) {
          RepeatedPwd?.setErrors({ incorrect: true });
          this.submitActive = false;
        } else {
          RepeatedPwd?.setErrors(null);
          this.submitActive = true;
        }
      });

    this.store
      .select((state) => state.resetPwd.resetPwd)
      .subscribe((isResetPwd) => {
        this.isResetPwd = isResetPwd;
      });

    this._registrationSuccessListner();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  submitForm = (): void => {
    const Pwd = this.newPwdForm.get('passwords')?.get('password');
    const repeatedPwd = this.newPwdForm.get('passwords')?.get('repeatedPwd');
    if (
      this.pwdValid.symbols == true &&
      this.pwdValid.capital == true &&
      this.pwdValid.smallLett == true &&
      this.pwdValid.numbers == true
    ) {
      Pwd?.setErrors(null);

      if (Pwd?.value == repeatedPwd?.value && Pwd && repeatedPwd) {
        let name: string;
        let lastName: string;
        let phoneNumber: string | number;
        if (this.isResetPwd) {
          this.store.dispatch(ResetPwdReset({ password: Pwd.value }));
        } else {
          this.store.select(getRegistrationData);
          this.store.select(getRegistrationData).subscribe((reg) => {
            if (reg) {
              this.store.dispatch(
                RegistrationPasswordSucess({
                  password: Pwd.value,
                  lastName: reg.getLastName,
                  firstName: reg.getName,
                  phoneNumber: reg.getPhoneNumber,
                })
              );
            }
          });
        }
      }
    } else {
      Pwd?.setErrors({ incorrect: true });
    }
  };

  private _registrationSuccessListner() {
    this.subs.push(
      this.actions.pipe(ofType(registrationSuccess)).subscribe(() => {
        this.showModal = true;
      })
    );
  }

  closeModal = () => {
    this.showModal = false;
    this.router.navigate(['/login']);
  };
}
