import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, count } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { AppState } from 'app/store/app.state';
import {
  UserInfoPostProps,
  UserInfoProps,
} from 'app/store/UserInfo/models/userInfo.model';
import {
  setUserinfoData,
  postUserinfoActionSuccess,
} from 'app/store/UserInfo/userInfo.actions';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import {
  getCitiesByCountry,
  getCitiesData,
  getCountriesData,
  getRegionsData,
  setRigtSidebarButtonsUserInfo,
  sendFileSuccess,
} from 'app/store/Shared/shared.actions';
import {
  CityData,
  CountryData,
  RegionData,
} from 'app/store/Shared/model/SharedModel';
import { DropdownType1Eement } from 'shared/ui/dropdown-type-1/circle-img/dropdown-type-1';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-cabinet-info-page',
  templateUrl: './ui/user-cabinet-info-page.component.html',
  styleUrls: ['./ui/user-cabinet-info-page.component.scss'],
})
export class UserCabinetInfoPageComponent {
  changeUserInfoForm: FormGroup;
  subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private store: Store<AppState>,
    private actions: Actions,
    public datepipe: DatePipe,
    private translateService: TranslateService
  ) {
    //this.userForm.valueChanges.subscribe(value => console.log(value));
  }

  image: boolean = false;
  cities: CityData[] | undefined;
  citiesTitles: DropdownType1Eement[];
  regions: RegionData[];
  regionsTitles: DropdownType1Eement[];
  countries: CountryData[] | undefined;
  countriesTitles: DropdownType1Eement[];

  dropdown: string = '0';

  userInfo: UserInfoProps | null;
  avatar: string | undefined = '';
  Name: string | undefined = '';
  LastName: string | undefined = '';
  Phone: string | undefined = '';
  Mail: string | undefined = '';
  BirthDate: string | undefined = '';
  Country = new BehaviorSubject<string>('');
  Country2 = new BehaviorSubject<string>('');
  City = new BehaviorSubject<string>('');
  street: string | undefined = '';
  building: string | undefined = '';
  room: string | undefined = '';
  index: string | undefined = '';

  setCountry(elem: string) {
    this.Country.next(elem);
    this.setCity(null);
  }
  setCountry2(elem: string) {
    this.Country2.next(elem);
  }
  setCity(elem: any) {
    this.City.next(elem);
  }

  setDropdown(drop: string): void {
    this.dropdown = drop;
    console.log(drop);
  }

  token: string = '';
  saveActive: boolean = false;

  ngOnInit(): void {
    //form group value
    this.changeUserInfoForm = this.fb.group({
      avatar: this.fb.control(this.avatar, Validators.required),
      Name: this.fb.control(this.Name, Validators.required),
      LastName: this.fb.control(this.LastName, Validators.required),
      Phone: this.fb.control(this.Phone, Validators.required),
      Mail: this.fb.control(this.Mail, Validators.required),
      BirthDate: this.fb.control(
        new Date(String(this.BirthDate)),
        Validators.required
      ),
      Country: this.fb.control(this.Country, Validators.required),
      Country2: this.fb.control(this.Country2, Validators.required),
      City: this.fb.control(this.City, Validators.required),
      street: this.fb.control(this.street, Validators.required),
      room: this.fb.control(this.street, Validators.required),
      building: this.fb.control(this.street, Validators.required),
      index: this.fb.control(this.index, Validators.required),
    });

    this.router.events.subscribe((val) => {
      if (
        this.router.url !== '/user-cabinet-info' &&
        val instanceof NavigationEnd
      ) {
        this.store.dispatch(setRigtSidebarButtonsUserInfo({ show: false }));
      } else if (this.router.url == '/account/user-cabinet-info') {
        this.store.dispatch(setRigtSidebarButtonsUserInfo({ show: true }));
      }
    });
    this.store
      .select((state) => state.shared)
      .subscribe((data) => {
        if (data.newProfileImage) {
          const file = data.newProfileImage;
          const fileReader = new FileReader();
          const imgPreview = document.getElementById('avatarUserInfo');
          fileReader.readAsDataURL(file);
          if (imgPreview) {
            fileReader.addEventListener('load', function () {
              imgPreview.style.display = 'block';
              imgPreview.setAttribute('src', String(this.result));
            });
          }
          this.image = true;
        }
      });
    this.store
      .select((state) => state.userinfo.UserInfoData)
      .subscribe((info) => {
        const dateBirth = String(
          this.datepipe.transform(info?.dateOfBirth, 'dd/MM/YYYY')
        );
        if (info?.address.country.countryId) {
          this.store.dispatch(
            getCitiesByCountry({
              countryId: info?.address.country.countryId,
            })
          );
        }
        this.avatar = info?.avatarImageLink;
        this.userInfo = info;
        this.Name = info?.firstName;
        this.LastName = info?.lastName;
        this.Phone = info?.phoneNumber;
        this.Mail = info?.email;
        this.BirthDate = info?.dateOfBirth;
        this.Country.next(info?.address?.country.title || '');
        this.Country2.next(info?.residency?.title || '');
        this.City.next(info?.address?.city.title || '');
        this.street = info?.address?.street || '';
        this.building = info?.address?.building || '';
        this.room = info?.address?.room || '';
        this.index = info?.address?.zip || '';

        this.changeUserInfoForm.setValue({
          avatar: this.avatar || null,
          Name: info?.firstName || null,
          LastName: info?.lastName || null,
          Phone: info?.phoneNumber || null,
          Mail: info?.email || null,
          BirthDate: new Date(String(this.BirthDate)) || null,
          Country: info?.address?.country.title || null,
          Country2: info?.residency?.title || null,
          City: info?.address?.city.title || null,
          street: info?.address?.street || null,
          room: info?.address?.room || null,
          building: info?.address?.building || null,
          index: info?.address?.zip || null,
        });
      });

    this.store
      .select((state) => state.auth)
      .subscribe((auth) => {
        if (auth.user?.userToken) this.token = auth.user?.userToken;
      });

    this.store
      .select((state) => state.shared)
      .subscribe((sharedState) => {
        this.countries = sharedState.countries;
        this.cities = sharedState.citiesByCoutry;
        if (this.countries) {
          this.countriesTitles = this.countries.map((c) => {
            return { title: c.title };
          });
        }
        if (this.cities) {
          this.citiesTitles = this.cities.map((city) => {
            return { title: city.title };
          });
        }
      });

    //filters regions when country added
    this.Country.subscribe((country) => {
      let selectedCountryId: string = '';
      if (this.countries) {
        this.countries.find((c) => {
          if (c.title == this.Country.value) {
            selectedCountryId = c.id;
          }
        });
      }
      if (selectedCountryId) {
        this.store.dispatch(
          getCitiesByCountry({
            countryId: selectedCountryId,
          })
        );
      }
    });

    //assigning saveActive value to allow editing
    this.store
      .select((state) => state.shared.SaveButtonUserInfoPage)
      .subscribe((btn) => {
        this.saveActive = btn;
      });

    //put data from inputs to state
    this.changeUserInfoForm.valueChanges.subscribe((changes) => {
      this.putUserInfoPostDataToState();
    });

    //put data from inputs to state
    this.Country.subscribe((country) => {
      this.putUserInfoPostDataToState();
    });
    this.Country2.subscribe((country) => {
      this.putUserInfoPostDataToState();
    });
    this.City.subscribe((country) => {
      this.putUserInfoPostDataToState();
    });

    this.actions.pipe(ofType(sendFileSuccess)).subscribe(({ data }) => {
      console.log(data);
      this.avatar = data.data.url;
      this.putUserInfoPostDataToState();
    });

    // this._updateListener();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  putUserInfoPostDataToState() {
    let birthDate;
    if (
      this.changeUserInfoForm.controls.BirthDate.value instanceof Date &&
      !isNaN(this.changeUserInfoForm.controls.BirthDate.value.getTime())
    ) {
      birthDate = new Date(
        this.changeUserInfoForm.controls.BirthDate.value
      ).toISOString();
    }
    let selectedCountryId: string = '';
    let selectedCountryId2: string = '';

    if (this.countries) {
      this.countries.find((c) => {
        if (c.title == this.Country.value) {
          selectedCountryId = c.id;
        }
        if (c.title == this.Country2.value) {
          selectedCountryId2 = c.id;
        }
      });
    }
    let selectedCityId: string | null = null;
    if (this.cities) {
      this.cities.find((c) => {
        if (c.title == this.City.value) {
          selectedCityId = c.id;
        }
      });
    }

    const userInfoPostData: UserInfoPostProps = {
      residencyCountryId: selectedCountryId2,
      citizenshipCountryId: selectedCountryId,
      address: {
        cityId: selectedCityId,
        zip: this.changeUserInfoForm.controls.index?.value,
        street: this.changeUserInfoForm.controls.street.value,
        room: this.changeUserInfoForm.controls.room.value,
        building: this.changeUserInfoForm.controls.building.value,
      },

      email: this.changeUserInfoForm.controls.Mail?.value || '',
      dateOfBirth: birthDate,
      avatarImageLink: this.avatar,
    };

    this.store.dispatch(
      setUserinfoData({
        UserInfoData: userInfoPostData,
        token: this.token,
      })
    );
  }

  onSave = (): void => {
    this.saveActive = false;
  };

  // private _updateListener() {
  //   this.subs.push(
  //     this.actions
  //       .pipe(ofType(postUserinfoActionSuccess))
  //       .subscribe(({ data }) => {
  //         if (!data.emailIsConfirmed) {
  //           this.router.navigate(['/account/confirm-mail']);
  //         }
  //       })
  //   );
  // }
}
