import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoteModalComponent } from '../note-modal';
import { TranslateConfigService } from 'app/services/translate-config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rights',
  templateUrl: './ui/rights.component.html',
  styleUrls: ['./ui/rights.component.scss'],
})
export class RightsComponent {
  @Input() addClass: string = '';

  constructor(
    public dialog: MatDialog,
    private translateService: TranslateService
  ) {}

  ngOnInit() {}
  openDialog(): void {
    this.dialog.open(NoteModalComponent, {
      data: {
        header: this.translateService.instant('rights.conditions.header'),
        text: [
          this.translateService.instant('rights.conditions.first'),
          this.translateService.instant('rights.conditions.second'),
          this.translateService.instant('rights.conditions.third'),
          this.translateService.instant('rights.conditions.fourth'),
        ],
      },
    });
  }
}
