<div *ngIf='data' class="edit-order-container">
	<div class="order-number">#{{data.id}}</div>
	<div class="order-main-container">
		<div class="column">
			<div class="order-status">
				<p>{{ 'my-orders.states.' + data.state | translate }}</p>
				<div class="loading-pink">
          <div
            class="loading-pink__bar"
            [style]="{ width: data.state * 25 + '%' }"
          ></div>
        </div>
			</div>

			<div class="info-container">
				<div class="info">
					<p class="label">{{ 'add-company-page.preview.regCountry' | translate }}</p>
					<p class="value">{{ getCountryName(data.countryId) }}</p>
				</div>

				<div class="info">
					<p class="label">{{ 'add-company-page.preview.form' | translate }}</p>
					<p class="value">{{ data.legalFormId }}</p>
				</div>

				<div class="info">
					<p class="label">{{ 'add-company-page.preview.numberOfDirectors' | translate }}</p>
					<p class="value">{{ data.directorsCount }}</p>
				</div>

				<div class="info">
					<p class="label">{{ 'add-company-page.preview.numberOfFounders' | translate }}</p>
					<p class="value">{{ data.shareholdersCount }}</p>
				</div>

				<div class="info">
					<p class="label">{{ 'add-company-page.preview.variantsOfTitle' | translate }}</p>
					<p class="value" *ngFor="let variant of data.titleVariants">
						{{ variant.title }}
					</p>
				</div>

				<div class="info">
					<p class="label">{{ 'add-company-page.structure.AmountOfAuthorizedCapital' | translate }}</p>
					<div class="slider-values">
						<div class="value">{{data.currency}}{{minAmount}}</div>
						<!-- top magic -->
						<!-- <div class="value dynamic" [ngStyle]="setDynamicPosition()">{{data.currency}}{{amount}}</div> -->

						<div class="value">{{data.currency}}{{maxAmount}}</div>
					</div>
	        <div class="slider-container">
	          <input
			        type="range"
			        class="slider"
	            [min]="minAmount"
	            [max]="maxAmount"
			        [(ngModel)]="amount"
			        [value]="amount"
			        #capital
			        [disabled]="true"
			      />
	        </div>
	        <div class="slider-values">

	        	<!-- bottom magic -->
						<div class="value dynamic" [ngStyle]="setDynamicPosition()">{{data.currency}}{{amount}}</div>

					</div>
				</div>
			</div>
		</div>

		<div class="column">
			<ng-container *ngIf="data.director">
				<ng-container *ngFor="let dir of data.director; index as i">
					<div class="block-title">
						{{ 'add-company-page.preview.director' | translate }} № {{i + 1}} 
					</div>
					<div class="info-container">
						<div class="info">
							<p class="label">{{ 'add-company-page.preview.nameAndLastName' | translate }}</p>
							<p class="value">{{ dir.fullname }}</p>
						</div>
						<div class="info">
							<p class="label">{{ 'add-company-page.preview.citizenship' | translate }}</p>
							<p class="value">{{ dir.nationality }}</p>
						</div>
						<div class="info">
							<p class="label">{{ 'add-company-page.preview.passport' | translate }}</p>
							<p class="value">{{ dir.passport }}</p>
						</div>
					</div>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="data.founders">
				<ng-container *ngFor="let holder of data.founders; index as i">
					<div class="block-title">
						{{ 'add-company-page.preview.founder' | translate }} № {{i + 1}} 
					</div>
					<ng-container *ngIf="!holder.company">
						<div class="info-container">
							<div class="info">
								<p class="label">{{ 'add-company-page.preview.nameAndLastName' | translate }}</p>
								<p class="value">{{ holder.fullname }}</p>
							</div>
							<div class="info">
								<p class="label">{{ 'add-company-page.preview.citizenship' | translate }}</p>
								<p class="value">{{ holder.nationality }}</p>
							</div>
							<div class="info">
								<p class="label">{{ 'add-company-page.preview.passport' | translate }}</p>
								<p class="value">{{ holder.passport }}</p>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="holder.company">
						<div class="info-container">
							<div class="info">
								<p class="label">{{ 'add-company-page.preview.name' | translate }}</p>
								<p class="value">{{ holder.title }}</p>
							</div>
							<div class="info">
								<p class="label">{{ 'add-company-page.preview.country' | translate }}</p>
								<p class="value">{{ holder.country }}</p>
							</div>
							<div class="info">
								<p class="label">{{ 'add-company-page.preview.form' | translate }}</p>
								<p class="value">{{ holder.form }}</p>
							</div>
							<div class="info">
								<p class="label">{{ 'add-company-page.preview.regNumber' | translate }}</p>
								<p class="value">{{ holder.number }}</p>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
	</div>
	<div class="button-container">
		<button 
			(click)="onContinueButtonClick()"
			[disabled]="loading"
		>{{ 'add-company-page.preview.CONTINUE_REGISTRATION' | translate }}</button>
	</div>
</div>