<button
  class="sidebar-button"
  [ngClass]="hovered ? 'sidebar-button-hover' : ''"
>
  <p class="sidebar-button__text">{{ name }}</p>
  <app-blue-amount [number]="amount" />
</button>
<button
  class="sidebar-button-mobile"
  [ngClass]="hovered ? 'sidebar-button-mobile-hover' : ''"
>
  <app-svg-icon [name]="pic"></app-svg-icon>
</button>
