<div class="reset-pwd-form" [formGroup]="form">
  <div class="reset-pwd-form__input-box">
    <label class="reset-pwd-form__input-box__label">{{
      "reset-pwd.login" | translate
    }}</label>
    <input
      type="text"
      class="reset-pwd-form__input-box__input"
      [ngClass]="
        form.get('login')?.invalid
          ? 'reset-pwd-form__input-box__input-invalid'
          : login.value !== ''
          ? 'reset-pwd-form__input-box__input-valid'
          : ''
      "
      [placeholder]="'reset-pwd.your-phone' | translate"
      formControlName="login"
      #login
    />
    <p
      *ngIf="form.get('login')?.errors?.pattern"
      class="reset-pwd-form__input-box__error"
    >
      {{ "reset-pwd.errors.uncorrect" | translate }}
    </p>
    <p
      *ngIf="form.get('login')?.errors?.required"
      class="reset-pwd-form__input-box__error"
    >
      {{ "reset-pwd.errors.empty" | translate }}
    </p>
  </div>
</div>
