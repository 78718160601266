import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { AppState } from 'app/store/app.state';
import {
  setLoadingSpinner,
  setRequestError,
  setRequestSuccess,
} from 'app/store/Shared/shared.actions';
import { Router } from '@angular/router';
import {
  getUserInfo,
  getUserInfoSuccess,
  postUserinfoAction,
  postUserinfoActionSuccess,
  postUserinfoActionImage,
  setUserInfoErrormessage,
} from './userInfo.actions';
import { userinfoService } from './services/UserInfo.service';

@Injectable()
export class UserInfoEffects {
  constructor(
    private actions$: Actions,
    private userinfoService: userinfoService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  onGetUserInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getUserInfo),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.userinfoService.getProfileData(action.token).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.store.dispatch(setUserInfoErrormessage({ message: '' }));
            return getUserInfoSuccess({ UserInfoData: data.data });
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.store.dispatch(
              setRequestError({ message: errorRes.error.message })
            );
            return of(
              setUserInfoErrormessage({
                message: 'Номер не существует или уже используется',
              })
            );
          })
        );
      })
    );
  });

  onGetUserInfoSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(getUserInfoSuccess),
        tap((action) => {
          /*localStorage.setItem(
            'FirmderUserInfo',
            JSON.stringify(action.UserInfoData)
          );*/
        })
      );
    },
    { dispatch: false }
  );

  onPostUserInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(postUserinfoAction),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.userinfoService
          .postProfileData(action.UserInfoData, action.token)
          .pipe(
            map((data) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              this.store.dispatch(
                setRequestSuccess({ message: 'Success changing profile data' })
              );
              this.store.dispatch(
                postUserinfoActionSuccess(data)
              );
              return getUserInfo({
                token: action.token,
              });
            }),
            catchError((errorRes) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              this.store.dispatch(
                setRequestError({ message: errorRes.error.message })
              );
              return of(
                setUserInfoErrormessage({
                  message: errorRes.error.message,
                })
              );
            })
          );
      })
    );
  });
  onPostUserInfoImage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(postUserinfoActionImage),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.userinfoService
          .postProfileDataImage(action.UserInfoData, action.token)
          .pipe(
            map((data) => {
              this.store.dispatch(
                setRequestSuccess({ message: 'Success changing profile image' })
              );

              this.store.dispatch(setLoadingSpinner({ status: false }));
              return getUserInfo({
                token: action.token,
              });
            }),
            catchError((errorRes) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              this.store.dispatch(
                setRequestError({ message: errorRes.error.message })
              );
              return of(
                setUserInfoErrormessage({
                  message: errorRes.error.message,
                })
              );
            })
          );
      })
    );
  });
}
