<section *ngIf="loadingScr">
  <app-loading-screen></app-loading-screen>
</section>
<section class="MainPage">
  <div class="MainPage__top">
    <div class="MainPage__slider">
      <div class="MainPage__slider__slide slide1 fade">
        <p translate class="MainPage__slider__slide__text">main.slide-text-1</p>
      </div>

      <div class="MainPage__slider__slide slide2 fade">
        <p translate class="MainPage__slider__slide__text">main.slide-text-2</p>
      </div>

      <div class="MainPage__slider__slide slide3 fade">
        <p translate class="MainPage__slider__slide__text">main.slide-text-3</p>
      </div>

      <div style="text-align: center" class="MainPage__slider__dots">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>
    <div class="MainPage__nav">
      <buttonComponent
        *ngIf="screenWidth <= 900"
        [color]="'blue'"
        [btnName]="'main.sign-up-btn' | translate"
        [PutWidth]="'100%'"
        [maxWidth]="true"
        [callBackFunc]="NavSignUp"
      ></buttonComponent>
      <buttonComponent
        *ngIf="screenWidth > 900"
        [color]="'blue'"
        [btnName]="'main.sign-up-btn' | translate"
        [callBackFunc]="NavSignUp"
      ></buttonComponent>
      <buttonComponent
        *ngIf="screenWidth <= 900"
        [color]="'black'"
        [btnName]="'main.sign-in-btn' | translate"
        [PutWidth]="'100%'"
        [maxWidth]="true"
        [callBackFunc]="NavLogin"
        [disabled]="loginButtonDisabled"
      ></buttonComponent>
      <buttonComponent
        *ngIf="screenWidth > 900"
        [color]="'black'"
        [btnName]="'main.sign-in-btn' | translate"
        [callBackFunc]="NavLogin"
        [disabled]="loginButtonDisabled"
      ></buttonComponent>
    </div>
    <div class="MainPage__rights">
      <app-rights></app-rights>
    </div>
  </div>

  <div class="MainPage__images">
    <img
      [src]="
        screenWidth > 900
          ? '/assets/icons/mountain.svg'
          : '/assets/icons/mountain2.svg'
      "
      alt=""
      class="MainPage__images__pic mountain"
    />
    <img
      src="/assets/icons/bublick.svg"
      alt=""
      class="MainPage__images__pic bublick"
    />

    <img
      src="/assets/icons/graph.svg"
      alt=""
      class="MainPage__images__pic graph"
    />
  </div>
</section>

<!--
   <img
      src="assets/icons/mountains.svg"
      class="MainPage__images__pic mountain"
      alt=""
    />
    <img
      src="assets/icons/bublick.svg"
      class="MainPage__images__pic bublick"
      alt=""
    />
    <img
      src="assets/icons/diagram.svg"
      class="MainPage__images__pic diagram"
      alt=""
    />
-->
