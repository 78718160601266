import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { AppState } from 'app/store/app.state';
import {
  setLoadingSpinner,
  setRequestError,
  setRequestSuccess,
} from 'app/store/Shared/shared.actions';
import { Router } from '@angular/router';
import {
  RegistrationCodeSuccess,
  RegistrationPasswordSucess,
  RegistrationPhoneNumberSuccess,
  RegistrationSendCode,
  setRegistrationData,
  setRegistrationErrorMessage,
  registrationSuccess,
  setRegistrationTimerAction,
} from './registration.actions';
import { registrationService } from './services/registration.service';
import {
  loginSuccess,
  setPasswordReset,
} from 'app/providers/AppRouter/authState/auth.actions';
import { getRegistrationData } from './registration.seleсtor';
import { registrationData } from './models/registrationData.model';

@Injectable()
export class RegistrationEffects {
  constructor(
    private actions$: Actions,
    private registrationService: registrationService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  onRegistration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setRegistrationData),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.registrationService
          .checkPhoneNumber(action.phoneNumber)
          .pipe(
            map((data) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              this.store.dispatch(setRegistrationErrorMessage({ message: '' }));
              this.store.dispatch(
                setRequestSuccess({ message: 'phone number is valid' })
              );
              this.store.dispatch(
                setRegistrationTimerAction({ timeStart: true, time: 60 })
              );
              return RegistrationPhoneNumberSuccess();
            }),
            catchError((errorRes) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              return of(
                errorRes.error.message ==
                  'This phone number has already been verified!'
                  ? RegistrationCodeSuccess()
                  : (setRequestError({ message: 'phone confirm error' }),
                    setRegistrationErrorMessage({
                      message: errorRes.error.message,
                    }))
              );
              // return of(
              //   setRequestError({ message: 'phone confirm error' }),
              //     setRegistrationErrorMessage({
              //       message: errorRes.error.message,
              //   })
              // );
            })
          );
      })
    );
  });

  onNumberPassed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RegistrationPhoneNumberSuccess),
        tap(() => {
          return this.registrationService.codeTimer();
        })
      );
    },
    { dispatch: false }
  );

  codeSending$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RegistrationSendCode),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.registrationService
          .checkCode(action.phoneNumber, action.code)
          .pipe(
            map((data) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              this.store.dispatch(setRegistrationErrorMessage({ message: '' }));
              this.store.dispatch(setPasswordReset({ isReset: true }));
              this.store.dispatch(
                setRequestSuccess({ message: 'phone confirmed' })
              );

              return RegistrationCodeSuccess();
            }),
            catchError((errorRes) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              this.store.dispatch(
                setRequestError({ message: errorRes.error.message })
              );
              return of(
                setRegistrationErrorMessage({
                  message: errorRes.error.message,
                })
              );
            })
          );
      }),
      catchError((errorRes) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        this.store.dispatch(
          setRequestError({ message: errorRes.error.message })
        );
        return of(
          setRegistrationErrorMessage({
            message: errorRes.error.message,
          })
        );
      })
    );
  });

  onCodeSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RegistrationCodeSuccess),
        tap(() => {
          this.router.navigateByUrl('/new-pwd');
        })
      );
    },
    { dispatch: false }
  );
  onPasswordSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RegistrationPasswordSucess),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        let regData: registrationData | null;
        this.store.select(getRegistrationData).subscribe((reg) => {
          regData = reg;
        });
        return this.registrationService
          .Register(
            action.phoneNumber,
            action.password,
            action.firstName,
            action.lastName
          )
          .pipe(
            map((data) => {
              this.store.dispatch(
                setRegistrationTimerAction({ timeStart: true, time: 0 })
              );
              this.store.dispatch(setLoadingSpinner({ status: false }));
              this.store.dispatch(
                setRequestSuccess({ message: 'password succesfully added' })
              );
              const user = null;
              // return loginSuccess({ user });
              return registrationSuccess({ user });
            })
          );
      }),
      catchError((errorRes) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        return of(
          setRequestError({ message: 'error password' }),
          setRegistrationErrorMessage({
            message: errorRes.error.message,
          })
        );
      })
    );
  });
}
