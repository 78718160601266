interface userData {
  email: string;
  firstName: string;
  id: string;
  isVerified: boolean;
  lastName: string;
  phoneNumber: string;
  quantityOfOrders: number;
  quantityOfAdditionalServices: number;
  quantityOfCompanies: number;
}

export class User {
  constructor(private token: string, private user: userData) {}

  get userData() {
    return this.user;
  }
  get userToken() {
    return this.token;
  }
}
