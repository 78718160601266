import { Component, Input, Output, EventEmitter } from '@angular/core';

export type DropdownType1Eement = {
  title: string;
  option?: string;
};

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
})
export class CustomDropdownComponent {
  constructor() {}

  @Input() name: string | undefined = '';
  @Input() nameDefault: string | undefined = '';
  @Input() size: string = '';
  @Input() elements: DropdownType1Eement[] = [];
  @Input() color: string = '';
  @Output() setItemFromButton = new EventEmitter<string>();
  @Output() settingDropdownOpen = new EventEmitter<string>();
  @Output() settingSaveButton = new EventEmitter<boolean>();
  @Input() dropdown: string = '';
  @Input() dropdownOpen: boolean = false;
  @Input() disabled: boolean = false;

  setDropdownOpen(event?: string) {
    this.settingDropdownOpen.emit(event);
    this.settingSaveButton.emit(true);
  }

  buttonFunc(elem: string): void {
    this.setItemFromButton.emit(elem);
    this.setDropdownOpen('');
    console.log(elem);
  }
}
