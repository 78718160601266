import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-blue-amount',
  templateUrl: './ui/blue-amount.component.html',
  styleUrls: ['./ui/blue-amount.component.scss']
})
export class BlueAmountComponent {
  @Input() number: string = ''
}
