<button class="transaction">
  <div class="transaction__content">
    <p class="transaction__content__date">{{ date }}</p>
    <p class="transaction__content__company">{{ company }}</p>
    <p class="transaction__content__name">{{ name }}</p>
  </div>
  <div class="transaction__info">
    <p class="transaction__info__price">{{ price }}</p>
  </div>
</button>
