import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './providers/AppRouter/app-routing.module';
import { AppComponent } from './ui/app.component';
import { NotFoundPageComponent } from 'pages/NotFoundPage';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { SharedDirectivesModule } from 'shared';
import { appReducer } from './store/app.state';
import { AuthEffects } from './providers/AppRouter/authState/auth.effects';
import { AuthTokenInterceptor } from './services/AuthToken.interceptor';
import { RegistrationEffects } from './store/Registration/registration.effects';
import { ResetPwdEffects } from './store/ResetPwd/resetPwd.effects';
import { NewPasswordPageComponent } from 'pages/NewPasswordPage';
import { UserInfoEffects } from './store/UserInfo/userInfo.effects';
import { DatePipe } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedEffects } from './store/Shared/shared.effects';
import { importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  VERSION as MAT_VERSION,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '@environment/environment';

export function rootLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [AppComponent, NotFoundPageComponent],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    EffectsModule.forRoot([
      AuthEffects,
      RegistrationEffects,
      ResetPwdEffects,
      UserInfoEffects,
      SharedEffects,
    ]),
    SimpleNotificationsModule.forRoot(),
    StoreModule.forRoot(appReducer),
    SharedDirectivesModule,
    StoreDevtoolsModule.instrument({
      logOnly: true,
    }),
    NgxStripeModule.forRoot(environment.STRIPE_KEY),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: rootLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    DatePipe,
    provideAnimations(),
    importProvidersFrom(MatNativeDateModule),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
