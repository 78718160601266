import { registrationData } from './models/registrationData.model';

export interface RegistrationState {
  registrationData: registrationData | null;
  errMessage: string | null;
  step: number;
  timer: boolean;
  time: number;
  numberConfirmed: boolean;
}

export const initialState: RegistrationState = {
  registrationData: null,
  errMessage: null,
  step: 0,
  timer: false,
  time: 60,
  numberConfirmed: false,
};
