export const removeEmtpyValuesFromObject = (o: any) => {
  const objToReturn: any = {};

  Object.keys(o).forEach(function (x: any) {
    if (x === 'resetStore') {
      return;
    }

    if (
      (o[x] !== '' && o[x] !== null && o[x] !== undefined && o[x]) ||
      (Array.isArray(o[x]) && o[x].length > 0) ||
      (Number.isInteger(o[x]) && o[x] >= 0) ||
      o[x] === false
    ) {
      objToReturn[x] = o[x];
    }
  });

  return objToReturn;
};
