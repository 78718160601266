import { NgModule } from '@angular/core';
import { BlueAmountComponent } from './ui/blue-amount/blue-amount.component';
import { SidebarButtonComponent } from './ui/sidebar-button/sidebar-button.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from './ui/Button';
import { FormInputComponent } from './ui/FormInput';
import { langSwitcherComponent } from './ui/LangSwitcher';
import { NoteModalComponent } from './ui/note-modal';
import { RightsComponent } from './ui/rights';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { WidgetsDirectivesModule } from 'widgets';
import { ButtonComponentType2 } from './ui/button-type-2/ButtonType2';
import { DropdownType1Component } from './ui/dropdown-type-1/circle-img/dropdown-type-1';
import { CustomDropdownComponent } from './ui/custom-dropdown/custom-dropdown';
import { CircleImgComponent } from './ui/button-type-2/circle-img/circle-img.component';
import { LoadingSpinnerComponent } from './ui/loading-spinner/loading-spinner.component';
import { SvgComponent } from './ui/svgIcon/svg.component';
import { SubcscriptionComponent } from './ui/subscription/SubcscriptionComponent.component';
import { statusSign } from './ui/statusSign/statusSign.component';
import { TranslateModule } from '@ngx-translate/core';
import { TransactionComponent } from './transaction/transaction';
import {
  MatSnackBar,
  MatSnackBarModule,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { AsyncSelectComponent } from './ui/async-select/async-select.component';
import { CapitalSliderComponent } from './ui/capital-slider/capital-slider.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  declarations: [
    BlueAmountComponent,
    SidebarButtonComponent,
    CircleImgComponent,
    ButtonComponent,
    FormInputComponent,
    langSwitcherComponent,
    NoteModalComponent,
    RightsComponent,
    SvgComponent,
    SidebarButtonComponent,
    ButtonComponentType2,
    DropdownType1Component,
    CustomDropdownComponent,
    LoadingSpinnerComponent,
    SubcscriptionComponent,
    statusSign,
    TransactionComponent,
    AsyncSelectComponent,
    CapitalSliderComponent,
  ],
  exports: [
    BlueAmountComponent,
    SidebarButtonComponent,
    CircleImgComponent,
    ButtonComponent,
    FormInputComponent,
    SvgComponent,
    langSwitcherComponent,
    NoteModalComponent,
    RightsComponent,
    SidebarButtonComponent,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ButtonComponentType2,
    DropdownType1Component,
    CustomDropdownComponent,
    LoadingSpinnerComponent,
    SubcscriptionComponent,
    statusSign,
    TranslateModule,
    TransactionComponent,
    MatSnackBarModule,
    AsyncSelectComponent,
    CapitalSliderComponent,
  ],
})
export class SharedDirectivesModule {}
