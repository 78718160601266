import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';

export enum BtnClassTypes {
  BLACK = 'black',
  BLUE = 'blue',
  BLUE_FRAME = 'blue-frame',
}

@Component({
  selector: 'buttonComponent',
  templateUrl: './ui/Button.component.html',
  styleUrls: ['./ui/Button.component.scss'],
})
export class ButtonComponent {
  constructor(private router: Router) {}

  @Input() maxWidth: boolean;
  @Input() padding: string;
  @Input() paddingVert: string;
  @Input() color: string = '';
  @Input() btnName: string = '';
  @Input() public callBackFunc: any;
  @Input() PutWidth: string;
  @Input() disabled: boolean = false;

  @HostBinding('style.width') width = 'auto';

  ngOnInit(): void {
    this.width = this.maxWidth ? '100%' : 'auto';
  }

  changeClass() {
    if (this.color === BtnClassTypes.BLUE) {
      return 'Button-blue';
    }
    if (this.color === BtnClassTypes.BLACK) {
      return 'Button-black';
    }
    if (this.color === BtnClassTypes.BLUE_FRAME) {
      return 'Button-blue-frame';
    }
    return;
  }
}
