import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthResponceData } from 'app/models/AuthResponseData.model';
import { responceData } from 'app/models/responce.model';
import { User } from 'app/models/user.model';
import { getToken } from 'app/providers/AppRouter/authState/auth.selector';
import { Observable } from 'rxjs';
import { setRegistrationTimerAction } from '../registration.actions';
import { AppState } from 'app/store/app.state';
import { Store } from '@ngrx/store';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root',
})
export class registrationService {
  BASE_URL = `${environment.API_BASE_URL}`;
  constructor(private http: HttpClient, private store: Store<AppState>) {}

  checkPhoneNumber(phoneNumber: string): Observable<responceData> {
    return this.http.post<responceData>(`${this.BASE_URL}/phone-number-check`, {
      phoneNumber: phoneNumber,
      verificationType: 'sms',
    });
  }

  checkCode(phoneNumber: string, code: string): Observable<responceData> {
    return this.http.post<responceData>(
      `${this.BASE_URL}/phone-number-verify`,
      { phoneNumber: phoneNumber, verificationCode: code }
    );
  }
  Register(
    phoneNumber: string | number,
    password: string,
    name: string,
    lastName: string
  ): Observable<responceData> {
    return this.http.post<responceData>(`${this.BASE_URL}/register`, {
      phoneNumber: phoneNumber,
      lastName: lastName,
      firstName: name,
      password: password,
    });
  }
  codeTimer = (): void => {
    let time: number;
    const timePut = setTimeout(() => {
      time -= 1;
      if (time < 0) {
        return;
      }
      this.store.dispatch(
        setRegistrationTimerAction({ timeStart: true, time: time })
      );
      this.codeTimer();
    }, 1000);

    this.store
      .select((state) => state.registration.timer)
      .subscribe((timer) => {
        if (!timer) {
          clearTimeout(timePut);
        }
      });
    this.store
      .select((state) => state.registration.time)
      .subscribe((timer) => {
        time = timer;
        if (time < 1) {
          clearTimeout(timePut);
        }
      });
  };
}
