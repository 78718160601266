<section *ngIf="tabName === 'my-orders'" class="my-orders-catalog">
  <h3 class="my-orders-catalog__title">
    {{ "my-orders.orders-title" | translate }}
  </h3>
  <div class="my-orders-catalog__container">
    <div class="my-orders-catalog__container__items">
      <p *ngIf="!myOrders.length" class="my-orders-catalog__empty-text">
        {{ "my-orders.no-orders-yet" | translate }}
      </p>
      <div
        *ngFor="let order of myOrders"
        class="my-orders-catalog__item"
        (click)="navigateToOrder(order)"
      >
        <p class="my-orders-catalog__item__code">#{{ order.id }}</p>
        <p class="my-orders-catalog__item__state">
          {{ "my-orders.states." + order.state | translate }}
        </p>
        <div class="loading-pink">
          <div
            class="loading-pink__bar"
            [style]="{ width: order.state * 25 + '%' }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="tabName === 'templates'" class="my-orders-catalog">
  <app-modal-window
    [open]="deleteWindowOpen"
    [title]="'alerts.delete' | translate"
    [okText]="'alerts.ok-btn' | translate"
    [cancelText]="'alerts.cancel-btn' | translate"
    [okFunction]="deleteOrderMethod"
    [cancelFunction]="closeDeleteWindow"
  ></app-modal-window>
  <h3 class="my-orders-catalog__title">
    {{ "my-orders.templates-title" | translate }}
  </h3>
  <div class="my-orders-catalog__container">
    <div class="my-orders-catalog__container__items">
      <p *ngIf="!myTemplates.length" class="my-orders-catalog__empty-text">
        {{ "my-orders.no-drafts-yet" | translate }}
      </p>
      <div
        *ngFor="let template of myTemplates"
        class="my-orders-catalog__item"
        (click)="navigateToCompanyRegistration(template)"
      >
        <div class="my-orders-catalog__item__top">
          <button
            type="button"
            class="my-orders-catalog__item__top__button"
            (click)="$event.stopPropagation(); openDeleteWindow(template.id)"
          >
            <img src="/assets/icons/cross-icon.svg" alt="" />
          </button>
          <p class="my-orders-catalog__item__code">#{{ template.id }}</p>
        </div>
        <p class="my-orders-catalog__item__state">
          {{ "my-orders.states." + template.state | translate }}
        </p>
        <div class="loading-pink">
          <div
            class="loading-pink__bar"
            [style]="{ width: template.state * 25 + '%' }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</section>
