<div class="order-to-registration-container">
  <ng-container *ngIf="step === 0">
    <div class="item-container">
      <div class="row">
        <div class="label">
          {{ 'service-catalog.orders.registration.category' | translate }}
        </div>
        <img
          src="assets/icons/success-sign.svg"
          class="animate__animated animate__zoomIn"
          [ngClass]="
            !parentForm.get('category')?.value ? 'img-gray' : ''
          "
        />
      </div>

      <button class="navigate-button" (click)="step = 1">
        <p>
          {{ 
            !parentForm.get('category')?.value 
             ? ('service-catalog.orders.registration.choose_category' | translate)
             : categoryName
          }}
        </p>
        <img
          src="assets/icons/sm-arrow-right.svg"
          class="dropdown-type-1__arrow"
        />
      </button>
    </div>

    <div class="item-container">
      <div class="row">
        <div class="label">
          {{ 'service-catalog.orders.registration.subcategory' | translate }}
        </div>
      </div>

      <button 
        class="navigate-button" 
        (click)="step = 2"
        [disabled]="!parentForm.get('category')?.value"
      >
        <p>
          {{ 
            !parentForm.get('sub-category')?.value 
             ? ('service-catalog.orders.registration.choose_subcategory' | translate)
             : subCategoryName
          }}
        </p>
      </button>
    </div>

    <div class="item-container">
      <div class="row">
        <div class="label">
          {{ 'service-catalog.orders.registration.types_of_economic_activities' | translate }}
        </div>
        <img
          src="assets/icons/success-sign.svg"
          class="animate__animated animate__zoomIn"
          [ngClass]="
            !parentForm.get('type')?.value ? 'img-gray' : ''
          "
        />
      </div>

      <ng-container *ngFor="let type of selectedTypes">
        <div class="selected-type">
          <p>{{ type.name }}</p>
          <img src="assets/icons/cross-icon.svg" (click)="removeType(type)">
        </div>
      </ng-container>

      <button class="navigate-button" (click)="step = 3">
        <p>
          {{ 'service-catalog.orders.registration.choose_type' | translate }}
        </p>
        <img
          src="assets/icons/sm-arrow-right.svg"
          class="dropdown-type-1__arrow"
        />
      </button>
    </div>

    <div class="devider"></div>

    <div class="item-container">
      <div class="row">
        <div class="label">
          {{ 'service-catalog.orders.registration.documents' | translate }}
        </div>
        <img
          src="assets/icons/success-sign.svg"
          class="animate__animated animate__zoomIn"
          [ngClass]="
            validDocuments() ? '' : 'img-gray'
          "
        />
      </div>

      <button class="navigate-button" (click)="step = 4">
        <p>
          {{ 'service-catalog.orders.registration.provide_documents' | translate }}
        </p>
        <img
          src="assets/icons/sm-arrow-right.svg"
          class="dropdown-type-1__arrow"
        />
      </button>
    </div>

    <div class="disclaimer-container">
      <p>
        {{ 'add-company-page.continue-registration.DISCLAMER' | translate }}
      </p>
    </div>

    <div class="button-container">
      <button 
        (click)="onSubmit()"
        [disabled]="!parentForm.valid"
        [ngStyle]="{'background': !parentForm.valid ? 'gray' : ''}"
      >
        {{ 'add-company-page.preview.CONTINUE_REGISTRATION' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="step === 1">
    <app-async-select-component
      [type]="'category'"
      [apiUrl]="'categories'"
      [title]="'service-catalog.orders.registration.category'"
      (onSelect)="onCategorySelect($event)"
    >
    </app-async-select-component>
  </ng-container>

  <ng-container *ngIf="step === 2">
    <app-async-select-component
      [type]="'subcategory'"
      [apiUrl]="'sub-categories'"
      [additionalParams]="{'category': selectedCategory}"
      [title]="'service-catalog.orders.registration.subcategory'"
      (onSelect)="onSubCategorySelect($event)"
    >
    </app-async-select-component>
  </ng-container>


  <ng-container *ngIf="step === 3">
    <app-async-select-component
      [type]="'activities'"
      [apiUrl]="'activities'"
      [title]="'service-catalog.orders.registration.types_of_economic_activities'"
      (onSelect)="onTypeSelect($event)"
    >
    </app-async-select-component>
  </ng-container>

  <ng-container *ngIf="step === 4">
    <div class="back-button" (click)="step = 0">
      <img
        src="assets/icons/go-back-icon.svg"
        class="dropdown-type-1__arrow"
      />
    </div>
    <div class="document-item-container" *ngFor="let dir of directors; let index = index">
      <div class="name-container">
        <div class="type">{{ 'add-company-page.preview.director' | translate }}</div>
        <div class="name">{{ dir.fullname }}</div>
      </div>

      <div class="passport-container">
        <div class="text">
          <div class="title">
            {{ 'add-company-page.continue-registration.NATORIZED_COPY_OF_PASSPORT' | translate }}
          </div>
          <div class="description">
            {{ 
              dir.addressProofUrl && dir.addressProofState !== 2 
                ? ('add-company-page.continue-registration.SCAN_OR_UPLOAD' | translate)
                : ('add-company-page.continue-registration.DOCUMENT_UNDER_REVIEW' | translate)
            }}
          </div>
        </div>

        <input
          #passportUpload
          type="file"
          id="file"
          (change)="handleFileInput($event, 'director', 'passportUrl', index)"
        >

        <div class="icon">
          <img *ngIf="!dir.passportUrl" src="assets/icons/add-blue-icon.svg" style="cursor: pointer;" (click)="passportUpload.click()"/>
          <img *ngIf="dir.passportUrl && dir.passportState === 2" src="assets/icons/success-sign.svg" class="animate__animated animate__zoomIn"/>
          <img *ngIf="dir.passportUrl && dir.passportState !== 2" src="assets/icons/loading-icon.svg"/>
        </div>
      </div>

      <div class="passport-container">
        <div class="text">
          <div class="title">
            {{ 'add-company-page.continue-registration.ADDRESS_PROOF' | translate }}
          </div>
          <div class="description">
            {{ 
              dir.passportUrl && dir.passportState !== 2 
                ? ('add-company-page.continue-registration.SCAN_OR_UPLOAD' | translate)
                : ('add-company-page.continue-registration.DOCUMENT_UNDER_REVIEW' | translate)
            }}
          </div>
        </div>

        <input
          #addressUpload
          type="file"
          id="file"
          (change)="handleFileInput($event, 'director', 'addressProofUrl', index)"
        >

        <div class="icon">
          <img *ngIf="!dir.addressProofUrl" src="assets/icons/add-blue-icon.svg" style="cursor: pointer;" (click)="addressUpload.click()"/>
          <img *ngIf="dir.addressProofUrl && dir.addressProofState === 2" src="assets/icons/success-sign.svg" class="animate__animated animate__zoomIn"/>
          <img *ngIf="dir.addressProofUrl && dir.addressProofState !== 2" src="assets/icons/loading-icon.svg"/>
        </div>
      </div>
    </div>


    <ng-container *ngFor="let founder of founders; let index = index">
      <div *ngIf="!(index === 0 && directors[0].isOwner)" class="document-item-container" >
        <div class="name-container">
          <div class="type">{{ 'add-company-page.preview.founder' | translate }}</div>
          <div class="name">{{ founder.fullname }}</div>
        </div>

        <div class="passport-container">
          <div class="text">
            <div class="title">
              {{ 'add-company-page.continue-registration.NATORIZED_COPY_OF_PASSPORT' | translate }}
            </div>
            <div class="description">
              {{ 
                founder.addressProofUrl && founder.addressProofState !== 2 
                  ? ('add-company-page.continue-registration.SCAN_OR_UPLOAD' | translate)
                  : ('add-company-page.continue-registration.DOCUMENT_UNDER_REVIEW' | translate)
              }}
            </div>
          </div>

          <input
            #passportUpload
            type="file"
            id="file"
            (change)="handleFileInput($event, 'founder', 'passportUrl', index)"
          >

          <div class="icon">
            <img *ngIf="!founder.passportUrl" src="assets/icons/add-blue-icon.svg" style="cursor: pointer;" (click)="passportUpload.click()"/>
            <img *ngIf="founder.passportUrl && founder.passportState === 2" src="assets/icons/success-sign.svg" class="animate__animated animate__zoomIn"/>
            <img *ngIf="founder.passportUrl && founder.passportState !== 2" src="assets/icons/loading-icon.svg"/>
          </div>
        </div>

        <div class="passport-container">
          <div class="text">
            <div class="title">
              {{ 'add-company-page.continue-registration.ADDRESS_PROOF' | translate }}
            </div>
            <div class="description">
              {{ 
                founder.passportUrl && founder.passportState !== 2 
                  ? ('add-company-page.continue-registration.SCAN_OR_UPLOAD' | translate)
                  : ('add-company-page.continue-registration.DOCUMENT_UNDER_REVIEW' | translate)
              }}
            </div>
          </div>

          <input
            #addressUpload
            type="file"
            id="file"
            (change)="handleFileInput($event, 'founder', 'addressProofUrl', index)"
          >

          <div class="icon">
            <img *ngIf="!founder.addressProofUrl" src="assets/icons/add-blue-icon.svg" style="cursor: pointer;" (click)="addressUpload.click()"/>
            <img *ngIf="founder.addressProofUrl && founder.addressProofState === 2" src="assets/icons/success-sign.svg" class="animate__animated animate__zoomIn"/>
            <img *ngIf="founder.addressProofUrl && founder.addressProofState !== 2" src="assets/icons/loading-icon.svg" />
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <app-modal-window
    [open]="showModal"
    [title]="'alerts.registration.title' | translate"
    [text]="'alerts.registration.description' | translate"
    [okText]="'alerts.ok-btn' | translate"
    [okFunction]="closeModal"
  ></app-modal-window>
</div>