<div class="dropdown-type-1" [style]="{ width: size, height: size }">
  <button
    class="dropdown-type-1__bar"
    [ngClass]="name !== nameDefault ? 'dropdown-type-1__bar-white' : ''"
    [style]="{ background: color }"
    (click)="dropdownOpen ? setDropdownOpen('') : setDropdownOpen(dropdown)"
    [disabled]="disabled"
  >
    <p class="dropdown-type-1__bar__title">{{ name ? name : nameDefault }}</p>
    <img
      src="assets/icons/sm-arrow-right.svg"
      class="dropdown-type-1__arrow"
      alt=""
    />
  </button>

  <ng-container>
    <div
      class="dropdown-type-1__elements"
      [ngClass]="
        !dropdownOpen
          ? 'dropdown-type-1__elements-closed animate__animated animate__zoomOut'
          : 'animate__animated animate__zoomIn'
      "
    >
      <button
        *ngFor="let elem of elements"
        class="dropdown-type-1__elements__btn"
        (click)="elem.option ? buttonFunc(elem.option) : buttonFunc(elem.title)"
      >
        {{ elem.title }}
      </button>
    </div>
  </ng-container>
</div>
