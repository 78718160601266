import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transaction-component',
  templateUrl: './ui/transaction.component.html',
  styleUrls: ['./ui/transaction.component.scss'],
})
export class TransactionComponent {
  constructor(private router: Router) {}

  @Input() date: string;
  @Input() company: string;
  @Input() name: string = '';
  @Input() price: string = '';

  ngOnInit(): void {}
}
