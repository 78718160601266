import { removeEmtpyValuesFromObject } from './remove-empty-values-from-object';

const formatDate = (date: any) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }

  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
};

export const setQueryParams = (options: any, searchField = 'search') => {
  if (!options) {
    return;
  }
  
  if (options.filters && options.filters.created) {
    options.filters.created = formatDate(options.filters.created);
  }

  if (options.filters && options.filters.date_joined) {
    options.filters.date_joined = formatDate(options.filters.date_joined);
  }

  if (options.filters && options.filters.last_login) {
    options.filters.last_login = formatDate(options.filters.last_login);
  }

  if (options.filters && options.filters.timestamp) {
    options.filters.timestamp = formatDate(options.filters.timestamp);
  }

  const filterValues = removeEmtpyValuesFromObject(options.filters || {});

  const data = {
    ...options,
    [searchField]: options.search,
    ...filterValues,
    sorting:
      options.sorting && options.sorting.active
        ? options.sorting.active
        : options.sorting,
    sortingOrder:
      options.sorting && options.sorting.direction
        ? options.sorting.direction
        : options.sortingOrder,
  };

  if (options.doNotSort) {
    delete data.sorting;
    delete data.sortingOrder;
    delete data.doNotSort;
  }

  if (!data.sortingOrder) {
    delete data.sorting;
    delete data.sortingOrder;
  }

  if (!options.search) {
    delete data[searchField];
  }

  delete data.filters;

  if (searchField !== 'search') {
    delete data.search;
  }

  return {
    params: {
      ...data,
    },
  };
};
