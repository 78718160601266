import { state } from '@angular/animations';
import { getUserInfoSuccess, setUserinfoData } from './userInfo.actions';
import { UserInfoState, initialState } from './userInfo.state';
import { Action, createReducer, on } from '@ngrx/store';

const _UserInfoReducer = createReducer(
  initialState,
  on(getUserInfoSuccess, (state, action) => {
    return {
      ...state,
      UserInfoData: action.UserInfoData,
    };
  }),
  on(setUserinfoData, (state, action) => {
    return {
      ...state,
      UserInfoPostData: {
        data: action.UserInfoData,
        token: action.token,
      },
    };
  })
);

export function UserInfoReducer(
  state: UserInfoState | undefined,
  action: Action
) {
  return _UserInfoReducer(state, action);
}
