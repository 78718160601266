import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './ui/NotFoundPage.component.html',
  styleUrls: ['./ui/NotFoundPage.component.scss']
})
export class NotFoundPageComponent {

}
