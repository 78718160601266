import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { AppState } from 'app/store/app.state';
import {
  autoLogin,
  setLoadingSpinner,
  setLoginErrorCode,
  setLoginErrorMessage,
  setLogoutMessage,
  setRequestError,
  setRequestSuccess,
} from 'app/store/Shared/shared.actions';
import { Router } from '@angular/router';
import {
  ResetPwdLoginSuccess,
  ResetPwdReset,
  ResetPwdResetSuccess,
  resetPwdSendCode,
  resetPwdSendCodeSuccess,
  setResetPwdLogin,
  setResetPwdLoginErrorMessage,
} from './resetPwd.actions';
import { resetPwdServiceService } from './services/resetPwd.service';
import { setPasswordReset } from 'app/providers/AppRouter/authState/auth.actions';

@Injectable()
export class ResetPwdEffects {
  constructor(
    private actions$: Actions,
    private resetPwdServiceService: resetPwdServiceService,
    private store: Store<AppState>,
    private router: Router,
  ) {}

  onSumbitPhoneNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setResetPwdLogin),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.resetPwdServiceService.checkPhoneNumber(action.login).pipe(
          map((data) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            setRequestSuccess({ message: 'Phone number confirmed' });
            setResetPwdLoginErrorMessage({ message: '' });
            return ResetPwdLoginSuccess();
          }),
          catchError((errorRes) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.store.dispatch(
              setRequestError({ message: errorRes.error.message })
            );
            return of(
              setResetPwdLoginErrorMessage({
                message: errorRes.error.message,
              })
            );
          })
        );
      })
    );
  });

  onResetPwdPhoneSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResetPwdLoginSuccess),
        tap(() => {
          return this.resetPwdServiceService.codeTimer();
        })
      );
    },
    { dispatch: false }
  );

  onSendCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(resetPwdSendCode),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.resetPwdServiceService
          .sendCode(action.phoneNumber, action.code)
          .pipe(
            map((data) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              this.store.dispatch(
                setResetPwdLoginErrorMessage({ message: '' })
              );
              this.store.dispatch(
                setRequestSuccess({ message: 'Code is correct' })
              );
              this.store.dispatch(setPasswordReset({ isReset: true }));
              return resetPwdSendCodeSuccess({ token: data.data.token || '' });
            }),
            catchError((errorRes) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              this.store.dispatch(
                setRequestError({ message: errorRes.error.message })
              );
              return of(
                setResetPwdLoginErrorMessage({
                  message: errorRes.error.messagesage,
                })
              );
            })
          );
      })
    );
  });

  onCodePhoneSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(resetPwdSendCodeSuccess),
        tap(() => {
          this.router.navigateByUrl('/new-pwd');
        })
      );
    },
    { dispatch: false }
  );

  onSendingResetRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResetPwdReset),
      switchMap((action) => {
        this.store.dispatch(setLoadingSpinner({ status: true }));
        return this.resetPwdServiceService
          .sendResetPassword(action.password)
          .pipe(
            map((data) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              this.store.dispatch(
                setRequestSuccess({ message: 'reset password' })
              );

              const id = data.data.id;
              this.store.dispatch(
                setResetPwdLoginErrorMessage({ message: '' })
              );
              return ResetPwdResetSuccess({ id });
            }),
            catchError((errorRes) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              this.store.dispatch(
                setRequestError({ message: errorRes.error.message })
              );
              return of(
                setResetPwdLoginErrorMessage({
                  message: errorRes.error.messagesage,
                })
              );
            })
          );
      })
    );
  });

  onResetSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ResetPwdResetSuccess),
        tap(() => {
          this.router.navigate(['/login']);
        })
      );
    },
    { dispatch: false }
  );
}
